import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../api.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';  // Import it up here
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'file-conversions',
	templateUrl: './file-conversions.html',
	styleUrls: ['./file-conversions.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FileConversionPage implements OnInit {
	exportFormats: any[]
	filecontent: any;
	filesToUpload: Array<File> = [];	
	rawJson: any;
	inputFileName:any;
	csvString:any = '';
	stringValidated = false;
	fileExchange:any = 'CSV'
	constructor(private spinner: NgxSpinnerService, public confirmationDialogService:ConfirmationDialogService, public http: HttpClient, public apiService: ApiService) {}
	@ViewChild('fileInput') fileInput: ElementRef;
	fileBrowse: ElementRef;
	ngOnInit() {
	}

	clearList(){
		this.fileInput.nativeElement.value = ""
		this.inputFileName = ""
		this.rawJson = ""
	}

	fileChangedEvent(fileInput: any) {
		var self = this;
		this.csvString = []
		 this.inputFileName = fileInput.target.files[0].name;
		 if(fileInput.target.files[0].type == 'text/csv' || fileInput.target.files[0].type == 'application/vnd.ms-excel'){
			 this.spinner.show();
			// this.filesToUpload = <Array<File>>fileInput.target.files;
			var r = new FileReader();
			r.onload = function(e) {
				var eventTarget = <FileReader>e.target;
				var contents:any;
				contents = eventTarget.result;
				contents = contents.split("\n");
				contents.shift();
				var filtered = contents.filter(function(el) { return el; });
				self.rawJson =  filtered.toString();
				self.spinner.hide();
			};
			r.readAsText(fileInput.target.files[0]);
		 } else{
			this.confirmationDialogService.alert('Alert', 'Select CSV File.')
			.then((confirmed) =>{
			})	
			.catch(() => {});
			return;
		 }
		
	}

	selectFileFormat(format:any){
		this.fileExchange = format;
	}
	
	processRawData(rawJson){
		this.csvString = []
		if(rawJson == undefined){
			this.confirmationDialogService.alert('Alert', 'Enter (,) separated values to export')
			.then((confirmed) =>{})	
			.catch(() => {});
			return;
		}
		rawJson = rawJson.split(",");
		let validString = this.processRaw(rawJson)
		if(!validString){
			alert('CSV format is invalid.  Please review the example provided and enter a valid format');
			this.csvString = []
			return;
		} else {
			alert('CSV format is valid');
			// this.stringValidated = validString;		
			this.csvString = rawJson.join();
			return;
		}
	}
	isHexNumber(hex) {
		return /(^#[A-Fa-f0-9]{6})|(^#[A-Fa-f0-9]{3})/i.test(hex)
	}

	processRaw(rawJson){
		var self = this;
		var rawJsonArr = rawJson;
		var validString = true;
		var self = this;
		for(let i = 0; i < rawJsonArr.length; i++){
			let hex = rawJsonArr[i]
			hex.trim()
			var firstChar = hex.charAt(0);
			if(firstChar != '#'){
				hex = '#'+hex;
			}
			var isValid = self.isHexNumber(hex);
			if(!isValid){	
				validString = false;
				break
			}

		}
		return validString
	}

	exportBatchFile(){
		if(!this.fileExchange){
			alert('Select Output Format');
			return;
		}
		if(!this.csvString){
			alert('Enter (,) separated values to export.');
			return;
		}
		let rawJson = this.rawJson.split(",");
		if(this.processRaw(rawJson) == true){
			
			this.csvString = rawJson.join();
		}
		else{
			alert('CSV format is invalid.  Please review the example provided and enter a valid format')
			this.csvString = []
			return
		}
		this.spinner.show();
		this.apiService.exportString(this.csvString, this.fileExchange).subscribe(
			(res) => {		
				this.spinner.hide();
				var fileExt, filePrefix;
				if(this.fileExchange == 'basic' || this.fileExchange == 'extended'){
					fileExt = '.json';
					filePrefix = 'json_export_'
				} else if(this.fileExchange == 'ASE'){
					fileExt = '.ase';
					filePrefix = 'ase_export_'
				} else if(this.fileExchange == 'CSV' || this.fileExchange == 'CSVALL'){
					fileExt = '.csv';
					filePrefix = 'csv_export_'					
				} 

				var today = new Date();
				var day = today.getDate() + "";
				var month = (today.getMonth() + 1) + "";
				var year = today.getFullYear() + "";
				var hour = today.getHours() + "";
				var minutes = today.getMinutes() + "";
				var seconds = today.getSeconds() + "";
				
				day = this.checkZero(day);
				month = this.checkZero(month);
				year = this.checkZero(year);
				hour = this.checkZero(hour);
				minutes = this.checkZero(minutes);
				seconds = this.checkZero(seconds);
				var todayDateTime = month+'-'+day+'-'+year+' at '+hour+'.'+minutes+'.'+seconds;

				var outputFile = filePrefix+todayDateTime+fileExt;
				const element = document.createElement('a');
				element.href = URL.createObjectURL(res.image);
				element.download = outputFile;
				document.body.appendChild(element);
				element.click();
			},
		(error) =>{
			this.spinner.hide();
			alert(error.error.message);
		});
	}
	checkZero(data){
		if(data.length == 1){
			data = "0" + data;
		}
		return data;
	}
	
	generateDownloadcsvUri() {
		// Example data given in question text
		var data = [["hex"],["#1a1b1c"], ["#2a2b2c"], ["#3a3b3c"]];

		// Building the CSV from the Data two-dimensional array
		// Each column is separated by ";" and new line "\n" for next row
		var csvContent = '';
		data.forEach(function(infoArray, index) {
		let dataString = infoArray.join(',');
		csvContent += index < data.length-1 ? dataString + '\n' : dataString;
		});

		// The download function takes a CSV string, the filename and mimeType as parameters
		// Scroll/look down at the bottom of this snippet to see how download is called
		var download = function(content, fileName, mimeType) {
		var a = document.createElement('a');
		mimeType = mimeType || 'application/octet-stream';

		if (navigator.msSaveBlob) { // IE10
			navigator.msSaveBlob(new Blob([content], {
			type: mimeType
			}), fileName);
		} else if (URL && 'download' in a) { //html5 A[download]
			a.href = URL.createObjectURL(new Blob([content], {
			type: mimeType
			}));
			a.setAttribute('download', fileName);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		} else {
			location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
		}
		}

			download(csvContent, 'sample.csv', 'text/csv;encoding:utf-8');
			
		}
}