import {ColorPickerService} from "../components/color-picker/color-picker.service";
export class ColorPacket {
  public h:number;
  public s:number;
  public l:number;
  public r:number;
  public fullHsl:string;
  public rPercent:number;
  public g:number;
  public gPercent:number;
  public b:number;
  public bPercent:number;
  public fullRgb:string;
  public fullRgba:string;
  public fullRgbPercent:string;
  public hex:string;
  public hexShort:string;
  public safe:string;
  public simpleHex:string;
  public zcc:string;
  public contrastingFontColor:string;
  public contrastingShadowColor:string;

  /**
   * We pass in the function from teh color picker service that returns [r,g,b] from hsl, since we i dont want to inject the whole service here.
   * @param value
   * @returns {string}
   */
  getAdjustedBrightnessValue(value:number, colorPickerService:ColorPickerService):string {
    var r:string;

    var adjusted = this.l + value;
    if( adjusted < 0 )
      adjusted = 0;
    if( adjusted > 1 )
      adjusted = 1;

    adjusted = adjusted;
    var rgb = colorPickerService.hslToRgb(this.h,this.s,adjusted);
    r = "rgb(" + parseInt(rgb[0]) + "," + parseInt(rgb[1]) + "," + parseInt(rgb[2]) + ")";

    return r;
  }

  /**
   * We pass in the function from teh color picker service that returns [r,g,b] from hsl, since we i dont want to inject the whole service here.
   * @param value
   * @returns {string}
   */
  getAdjustedSaturationValue(value:number, colorPickerService:ColorPickerService):string {
    var r:string;

    var adjusted = this.s + value;
    if( adjusted < 0 )
      adjusted = 0;
    if( adjusted > 1 )
      adjusted = 1;

    adjusted = adjusted;
    var rgb = colorPickerService.hslToRgb(this.h,adjusted,this.l);
    r = "rgb(" + parseInt(rgb[0]) + "," + parseInt(rgb[1]) + "," + parseInt(rgb[2]) + ")";

    return r;
  }

  getAdjustedGreenValue(value:number):string {
    var r:string;

    var adjusted = this.g + value;
    if( adjusted < 0 )
      adjusted = 0;
    if( adjusted > 255 )
      adjusted = 255;

    r = "rgb(" + this.r + "," + adjusted + "," + this.b + ")";

    return r;
  }

  getAdjustedRedValue(value:number):string {
    var r:string;

    var adjusted = this.r + value;
    if( adjusted < 0 )
      adjusted = 0;
    if( adjusted > 255 )
      adjusted = 255;

    r = "rgb(" + adjusted + "," + this.g + "," + this.b + ")";

    return r;
  }

  getAdjustedHueValue(value:number, colorPickerService:ColorPickerService):string {
    //hue is 360 degrees, value will be a decimal percent from 0 to 1
    var adjusted = colorPickerService.changeHue( this.hex, 360 * value );

    return "#" + adjusted;
  }


}

export class ZCC {
  public static CONVERT(hex:string): string {
    if( !hex ) return undefined;
    let match = hex.toLowerCase().match(/^([0-9a-f])([0-9a-f])([0-9a-f])([0-9a-f])([0-9a-f])([0-9a-f])$/);
    if( match && match[2]==match[1] && match[4] == match[3] && match[6] == match[5] ) {
      let i = parseInt(`${match[2]}${match[4]}${match[6]}`, 16);
      let str = `${i+10001}`.substr(1);
      return `ZCC-${str}`;
    } else {
      return undefined;
    }
  };

  public static BACKCONVERT(zcc:string): string {
    if( !zcc ) return undefined;
    let match = zcc.toLowerCase().match(/^zcc-([0-9][0-9][0-9][0-9])$/);
    if( match ) {
      let i = parseInt(match[1], 10) - 1;   // ZCC starts at 1
      if (i >= 0 && i < 8138) {
        let hex = '0123456789abcdef';
        let r = hex.substr((i & 0xf00) >> 8, 1);
        let g = hex.substr((i & 0x0f0) >> 4, 1);
        let b = hex.substr((i & 0x00f) >> 0, 1);
        return `${r}${r}${g}${g}${b}${b}`;
      }
    }
    return undefined;
  };
}
