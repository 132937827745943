import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs/Observable';
import { Observable } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FileConfirmationDialogComponent } from './file-confirmation-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { AlertDialogComponent } from './alert-dialog.component';

@Injectable()
export class ConfirmationDialogService {

  constructor(private modalService: NgbModal) { }


  

  public confirm(
    title: string,
    message: string,
    btnOkText?: string,
    btnCancelText?: string,
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText || 'OK' ;
    modalRef.componentInstance.btnCancelText = btnCancelText || 'Cancel';

    return modalRef.result;
  }
  
  public alert(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm'|'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(AlertDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  
  public fileConfirm(
    title: string,
    message: string,
    btnMergeText: string = 'Merge',
    btnReplaceText: string = 'Replace',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm'|'lg' = 'lg'): Promise<any> {
    const modalRef = this.modalService.open(FileConfirmationDialogComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnMergeText = btnMergeText;
    modalRef.componentInstance.btnReplaceText = btnReplaceText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }


  

}
