import { Injectable, Component, OnInit, ViewEncapsulation, Injector, Input } from '@angular/core';
import { BaseComponent } from '../../base.component'
import { SwatchChangedEvent } from "../../events/swatch.changed.event";
import { SwatchModel } from "../../models/swatch.model";
import { PaletteModel } from "../../models/palette.model";
import { ColorPickerService } from '../../components/color-picker/color-picker.service';
import { Observable, Subscription, fromEvent } from 'rxjs';
declare var jQuery: any;


@Injectable({
	providedIn: 'root'
})
@Component({
	selector: 'image-exchange-colors-box',
	templateUrl: './imageExchange-colors-box.html',
	styleUrls: ['./imageExchange-colors-box.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ImageExchangeColorsBoxCommon extends BaseComponent implements OnInit {
	swatchesList: any[] = []
	screenWidth: any
	@Input() palette: PaletteModel;
	@Input() swatch: SwatchModel;
	resizeObservable$: Observable<Event>
	resizeSubscription$: Subscription
	scrollbar: any;


	constructor(public injector: Injector) {
		super(injector);
	}

	ngOnInit() {
		this.screenWidth = window.innerWidth
		this.createScrollScript()
		this.resizeObservable$ = fromEvent(window, 'resize')
		this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
			this.screenWidth = window.innerWidth
			this.reinitializeScrollBar();
		})
	}

	ngOnDestroy() {
		this.resizeSubscription$.unsubscribe()
	}

	contrastingFontColor(swatch): string {
		return ColorPickerService.isDarkColor(swatch.rgb) ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)';
	}


	missingTagBlack(swatch): any {
		if (swatch.empty || this.gm.palettesImageExchangeModel.selectedPalette.shouldShowMissingTags == false) {
			return false;
		} else {
			return swatch.missingRefTag;
		}
	}

	dropped(event: any, swatchIndex: number): void {
		this.gm.addToRedoStack();
		if (typeof event.dragData.dragIndex != 'undefined') {
			// move swatch from dragIndex to swatchIndex
			var element = this.palette.swatches[event.dragData.dragIndex];
			this.palette.swatches.splice(event.dragData.dragIndex, 1);
			this.palette.swatches.splice(swatchIndex, 0, element);
		} else {
			let indexSwatch = this.palette.swatches[swatchIndex];
			indexSwatch.copy(event.dragData.swatch);
			if (indexSwatch.refnumber == '' || indexSwatch.refname == '') {
				indexSwatch.missingRefTag = true;
			}
			else {
				indexSwatch.missingRefTag = false;
			}
			this.gm.palettesImageExchangeModel.selectedSwatch.selected = false;
			this.gm.palettesImageExchangeModel.selectedSwatch = indexSwatch;

			if (this.gm.palettesImageExchangeModel.selectedPalette.savedReferenceName.length > 0) {
				this.gm.palettesImageExchangeModel.selectedPalette.enableMerge = true;
			}
		}
		this.gm.palettesImageExchangeModel.selectedPalette.palletSaved = false;
		//this.gm.palettesImageExchangeModel.writeDefaultFile();
	}
	selectSwatch(event): void {
		if (event.selected == true) {
			event.selected = false;
		} else {
			this.gm.palettesImageExchangeModel.selectedPalette.deselectAllSwatches();

			var o = new SwatchChangedEvent({
				"oldSwatchId": this.gm.palettesImageExchangeModel.selectedSwatch.id,
				"oldPaletteId": this.gm.palettesImageExchangeModel.selectedPalette.id,
				"newSwatch": event,
				"newPalette": this.palette
			});
			event.selected = true;
			this.gm.palettesImageExchangeModel.selectedSwatch = event;
			// this.gm.colorUtility.generateColors(this.gm.palettesModel.selectedSwatch.rgb, this.gm.palettesModel.selectedSwatch);
			this.gm.palettesImageExchangeModel.selectedPalette = this.palette;
			this.gm.palettesImageExchangeModel.data.activePaletteId = this.palette.id;

			this.gm.palettesImageExchangeModel.swatchChanged.emit(o);
		}

	}


	deleteSwatch(swatch) {
		if (swatch.empty) {
			alert("The swatch is already empty.");
			return;
		} else {
			this.gm.addToRedoStack();
			for (var i = 0; i < this.palette.swatches.length; i++) {
				if (this.palette.swatches[i].id === swatch.id) {
					let deletedSwatch = Object.assign({}, this.palette.swatches[i]);
					let index = this.gm.palettesImageExchangeModel.selectedPalette.swatches.findIndex(x => x.id == this.palette.swatches[i].id);
					let deletedArr = { index: index, data: deletedSwatch };
					this.gm.deletedStack.push(deletedArr);
					this.palette.swatches[i].empty = true;
					this.palette.swatches[i].zcc = "ZCC-0000"
					this.palette.swatches[i].refname = ""
					this.palette.swatches[i].refnumber = ""
					this.palette.swatches[i].contrastingFontColor = "white";
					this.gm.palettesImageExchangeModel.selectedSwatch = new SwatchModel({});
					this.gm.palettesImageExchangeModel.updatePalette(this.palette);
					//this.gm.palettesImageExchangeModel.writeDefaultFile();
				}
			}
		}
	}
	createScrollScript() {
		var s = document.createElement('script');
		s.setAttribute('src', '../../../assets/scrollbar.js');
		s.onload = () => {
			this.scrollbar = jQuery('body').find("#common-box2").customScrollbar({ fixedThumbHeight: 50, fixedThumbWidth: 60 });
		};
		document.body.appendChild(s);
	}

	reinitializeScrollBar() {
		this.scrollbar.removeScrollbar("vertical");
		this.scrollbar.addScrollBar("vertical", "prepend");
	}
}