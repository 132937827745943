import {Injectable, Component, OnInit, ViewEncapsulation, Injector, ChangeDetectionStrategy, Input, HostListener, NgZone } from '@angular/core';
import { BaseComponentSecondary } from '../../base.component.secondary';
import {SwatchChangedEvent} from "../../events/swatch.changed.event";
import {SwatchModel} from "../../models/swatch.model";
import {PaletteModel} from "../../models/palette.model";
import { ColorPickerService } from '../../components/color-picker/color-picker.service';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { SwatchColorChangedEvent } from 'src/app/events/swatch.color.changed.event';
import * as cloneDeep from "lodash/cloneDeep";
declare var jQuery: any;
interface SwatchColor {
	hex: string;
	hashHex: string;
	zcc: string;
	empty: boolean;
	refname: string;
	refnumber: string;
  }
@Injectable({
	providedIn: 'root'
  })
@Component({
	selector: 'common-full-workspace',
	templateUrl: './full-workspace.html',
	styleUrls: ['./full-workspace.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class FullWorkspace extends BaseComponentSecondary implements OnInit {
	swatchesList: any[] = []
	@Input() palette: PaletteModel;
	@Input() swatch: SwatchModel;
	checkForDuplicate: Boolean = false;
	constructor(public injector: Injector, public confirmationDialogService: ConfirmationDialogService, public _zone: NgZone) {
		super(injector);
	 }

	ngOnInit() {
		// var widthUL = jQuery('ul.workspacecolors').outerWidth();
		// var boxH = jQuery(widthUL / 16);
		// jQuery('ul.workspacecolors').css('height',boxH[0]+'px');
	}
	@HostListener('window:resize', ['$event'])
	onResize(event){
		// var widthUL = jQuery('ul.workspacecolors').outerWidth();
		// var boxH = jQuery(widthUL / 16);
		// jQuery('ul.workspacecolors').css('height',boxH[0]+'px');
	}

	contrastingFontColor(swatch): string {
    return ColorPickerService.isDarkColor(swatch.rgb) ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)';
  }
  dropped(event: any, swatchIndex: number) : void {
	this.gm.addToRedoStack();
	if (typeof event.dragData.dragIndex != 'undefined') {
	  // move swatch from dragIndex to swatchIndex
	  var element = this.palette.swatches[event.dragData.dragIndex];
	  this.palette.swatches.splice(event.dragData.dragIndex, 1);
	  this.palette.swatches.splice(swatchIndex, 0, element);
	} 
	else {
	  let indexSwatch = this.palette.swatches[swatchIndex];
	  indexSwatch.copy(event.dragData.swatch);
	  if (indexSwatch.refnumber == '' || indexSwatch.refname == '') {
		indexSwatch.missingRefTag = true;
	  }
	  else{
		indexSwatch.missingRefTag = false;
	  }
	  this.gm.palettesFileModel.selectedSwatch.selected = false;
	  this.gm.palettesFileModel.selectedSwatch = indexSwatch;

	if (this.gm.palettesFileModel.selectedPalette.savedReferenceName.length > 0) {
	  this.gm.palettesFileModel.selectedPalette.enableMerge = true;
  }
	}
	this.gm.palettesFileModel.selectedPalette.palletSaved = false;
	this.gm.palettesFileModel.writeDefaultFile();
	
}

	missingTagBlack(swatch): any {
		if(swatch.empty || this.gm.palettesModel.selectedPalette.shouldShowMissingTags == false){
			return false;
		} else {
			return swatch.missingRefTag;
		}
	}

	selectSwatch(event):void {
		if(event.selected == true){
			event.selected = false;
		} else {
			this.gm.palettesModel.selectedPalette.deselectAllSwatches();

			var o = new SwatchChangedEvent({
				"oldSwatchId": this.gm.palettesModel.selectedSwatch.id,
				"oldPaletteId": this.gm.palettesModel.selectedPalette.id,
				"newSwatch": event,
				"newPalette": this.palette
			});
			event.selected = true;
			this.gm.palettesModel.selectedSwatch = event;
		// this.gm.colorUtility.generateColors(this.gm.palettesModel.selectedSwatch.rgb, this.gm.palettesModel.selectedSwatch);
			this.gm.palettesModel.selectedPalette = this.palette;
			this.gm.palettesModel.data.activePaletteId = this.palette.id;
			this.gm.palettesModel.swatchChanged.emit(o);
		}
        
	}
	

	deleteSwatch(swatch){
		if(swatch.empty){
			alert("The swatch is already empty.");
			return;
		} else {
			this.gm.addToRedoStack();
		  for ( var i = 0; i < this.palette.swatches.length; i ++ ) {
			if (this.palette.swatches[i].id === swatch.id) {
				let deletedSwatch = Object.assign({}, this.palette.swatches[i]);
				let index = this.gm.palettesModel.selectedPalette.swatches.findIndex(x=>x.id==this.palette.swatches[i].id);
				let deletedArr = {index:index, data:deletedSwatch};
				this.gm.deletedStack.push(deletedArr);
			  this.palette.swatches[i].empty = true;
			  this.palette.swatches[i].zcc = "ZCC-0000"
			  this.palette.swatches[i].refname = ""
			  this.palette.swatches[i].refnumber = ""
			  this.palette.swatches[i].contrastingFontColor = "white";
			  this.gm.palettesModel.selectedSwatch = new SwatchModel({});
			  this.gm.palettesModel.updatePalette(this.palette);
			  this.gm.palettesModel.writeDefaultFile();
			}
		  }
		}
	}

}