import {GlobalModel} from "./global.model";
import {FileModel} from "./file.model";
import {SwatchModel} from "./swatch.model";

import { UUID } from 'angular2-uuid';

declare var SystemPath: any;
declare var _: any;

/**
 * NOTE: we are using _.deepExtend, so if we add new properties to our models, for later versions, when the older version file loads,
 * properties will be merged with the new ones, and it should make backward compatibility a non issue. Just add new properties to the models
 */
export class ColorHistoryModel extends FileModel {

    data = {
        "version": "1.0.1",
        "swatches" : []
    };

    public swatches: SwatchModel[] = [];

    constructor(public gm:GlobalModel) {
      super();
      this.init();
    }

    init():void {
      // this.filePath = this.gm.csInterface.getSystemPath(SystemPath.USER_DATA);
      this.fileName =  "zcdp.colorhistory.json";
      this.initFileCheck();
      
      if(this.data){
        if( this.data.swatches && this.data.swatches.length ) {
          for (var i = 0; i < this.data.swatches.length; i++) {
            this.data.swatches[i] = new SwatchModel(this.data.swatches[i]);
          }
        }
      }
    }

    writeDefaultFile():void {
      if( this.data.swatches.length > 48 )
        this.data.swatches.length = 48;

      var json = JSON.stringify(this.data);
      this.writeFile(json);
    }
}
