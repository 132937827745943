import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import * as $ from 'jquery'
declare var jQuery: any;
export interface PromptModel {
  title:string;
  zccValue:string;
  zccColor:string;
  contrastingFontColor:string;
  referenceName:string;
  referenceNumber:string;
  defaultShape: string;
  defaultPosition: string;
  defaultFileType: string;
}

@Component({
  selector: 'prompt',
  template: './prompt.component.html'
})
export class PromptComponent extends DialogComponent<PromptModel, string> implements PromptModel {
  title: string;
  imageType: any = '';
  shape: any = '';
  location: any = '';
  zccValue:string;
  zccColor:string;
  contrastingFontColor:string;
  referenceName:string;
  referenceNumber:string;
  defaultShape: string;
  defaultPosition: string;
  defaultFileType: string;
  fileImagePath: string;
  fileType: string = 'image';
  constructor(dialogService: DialogService) {
    super(dialogService);
    this.imageType = 'JPG';
    this.shape = 'rectangle';
    this.location = 'TL';
    this.fileType = 'image';
  }
  ngOnInit(){
    let self = this
    self.defaultFileTypeSelect(this.defaultFileType);
    self.defaultShapeSelect(this.defaultShape)
    self.defaultPositionSelect(this.defaultPosition)

  }
  jsUcfirst(string)
  {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }
  defaultFileTypeSelect(fileId):void{
    this.defaultFileType = fileId;
    if(this.defaultFileType == 'fileTypeACT'){
      this.fileType = 'data';
      this.fileImagePath = "images/act_black.png"
    } else if( this.defaultFileType == 'fileTypeACO') {
      this.fileType = 'data';
      this.fileImagePath = "images/aco_black.png"
    } else if (this.defaultFileType == 'fileTypeASE'){
      this.fileType = 'data';
      this.fileImagePath = "images/ase_black.png"
    } else {
        this.fileType = 'image';
    }
    $('input:radio[id=' + fileId + ']').prop('checked', true);
    if(fileId == 'fileTypePNG'){
      $('.position-preview-box').attr('class','position-preview-box has-png');
    }
  }

  defaultShapeSelect(shapeId){
    this.defaultShape = shapeId
    let id = 'swatchShape' + this.jsUcfirst(shapeId);
    $('input:radio[id=' + id + ']').prop('checked', true);
    $('.swatch-data').attr('class','swatch-data ' + shapeId);
  }

  defaultPositionSelect(positionValue){
    this.defaultPosition = positionValue
    $('input:radio[id=' + positionValue + ']').prop('checked', true);
    $('.swatch-name').attr('class','swatch-name ' + positionValue);
  }

  apply() {
    this.imageType = $("#canvasFileType input[type='radio']:checked").val();
    this.shape = $("#canvasSwatchShape input[type='radio']:checked").val();
    this.location = $("#canvasReferenceLocation input[type='radio']:checked").val();

    this.result = this.imageType +':'+ this.shape +':'+ this.location;
    this.close();
  }
  positionSelect(positionValue){
    $('.swatch-name').attr('class','swatch-name ' + positionValue);
  }

  fileTypeSelect(fileId){
    $('.position-preview-box').attr('class','position-preview-box');
    this.fileType = 'image';
    if(fileId == 'fileTypePNG'){
      $('.position-preview-box').attr('class','position-preview-box has-png');
    }
    if(fileId == 'fileTypeACO'){
      this.fileType = 'data';
      this.fileImagePath = "images/aco_black.png"
      // $('.position-preview-box').attr('class','position-preview-box has-aco');
    }
    if(fileId == 'fileTypeACT'){
      this.fileType = 'data';
      this.fileImagePath = "images/act_black.png"
      // $('.position-preview-box').attr('class','position-preview-box has-act');
    }
    if(fileId == 'fileTypeASE'){
      this.fileType = 'data';
      this.fileImagePath = "images/ase_black.png"
      // $('.position-preview-box').attr('class','position-preview-box has-ase');
    }

  }
  shapeSelect(shapeId){
    $('.swatch-data').attr('class','swatch-data ' + shapeId);
  }
}
