import { Component } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
declare var jQuery: any;
export interface PromptModel {
  title:string;
  question:string;
}

@Component({
  selector: 'prompt',
  template: `<div class="modal-dialog">
                <div class="modal-content">
                   <div class="modal-header">
                     <button type="button" class="close" (click)="close()">&times;</button>
                     <h4 class="modal-title">{{title || 'Prompt'}}</h4>
                   </div>
                   <div class="modal-footer">
                     <button type="button" class="btn btn-primary" (click)="replace()">Replace</button>
                     <button type="button" class="btn btn-default" (click)="add()" >Add</button>
                     <button type="button" class="btn btn-default" (click)="cancel()" >Cancel</button>
                   </div>
                 </div>
                </div>`
})
export class AddPalettePromptComponent extends DialogComponent<PromptModel, string> implements PromptModel {
  title: string;
  question: string;

  constructor(dialogService: DialogService) {
    super(dialogService);
  }
  replace() {
    this.result = "0";
    this.close();
  }
  
  add() {
    this.result = "2";
    this.close();
  }
  
  cancel() {
    this.close();
  }

}
