import {Injectable} from '@angular/core';
import {Rgba, Hsla, Hsva} from './classes';
import {ZCC, ColorPacket} from "../../utils/zcc";
import { alertComponent } from '../alert.component';
// import { DialogService } from "ng2-bootstrap-modal";

@Injectable()
export class ColorPickerService {
  constructor() { }

    static hexToRgb(hex): {r: number, g: number, b: number} {
      hex = (hex.charAt(0) === "#") ? hex.substring(1, 7) : hex;

      if (hex.length !== 6) {
         // alert ('Invalid length of the input hex value!');
          return;
      }
      if (/[0-9a-f]{6}/i.test(hex) != true) {
          return;
      }

      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);

      return {r: r, g: g, b: b};
    }

    static isDarkColor(hex: string): boolean {
      let rgb = ColorPickerService.hexToRgb(hex);
      let rnd = (x, y) => Math.round(x) * y;
      let fc = Math.round((rnd(rgb.r, 299) + rnd(rgb.g, 587) + rnd(rgb.b, 114)) / 1000);
      if (fc > 125) {
        return false;
      } else {
        return true;
      }
    }



    hsla2hsva(hsla: Hsla): Hsva {
        let h = Math.min(hsla.h, 1), s = Math.min(hsla.s, 1), l = Math.min(hsla.l, 1), a = Math.min(hsla.a, 1);
        if (l === 0) {
            return new Hsva(h, 0, 0, a);
        } else {
            let v = l + s * (1 - Math.abs(2 * l - 1)) / 2;
            return new Hsva(h, 2 * (v - l) / v, v, a);
        }
    }


    hsva2hsla(hsva: Hsva): Hsla {
        let h = hsva.h, s = hsva.s, v = hsva.v, a = hsva.a;
        if (v === 0) {
            return new Hsla(h, 0, 0, a)
        } else if (s === 0 && v === 1) {
            return new Hsla(h, 1, 1, a)
        } else {
            let l = v * (2 - s) / 2;
            return new Hsla(h, v * s / (1 - Math.abs(2 * l - 1)), l, a)
        }
    }

    rgbToHsv(r: number, g: number, b: number): any {
        let r1 = Math.min(r / 255, 1);
        let g1 = Math.min(g / 255, 1)
        let b1 = Math.min(b / 255, 1)
        let max = Math.max(r1, g1, b1)
        let min = Math.min(r1, g1, b1)
        let h: number, s: number, v: number = max;

        let d = max - min;
        s = max === 0 ? 0 : d / max;

        if (max === min) {
            h = 0;
        } else {
            switch (max) {
                case r1:
                    h = (60 * ((g1 - b1) / d) + 360) % 360;
                    break;
                case g1:
                    h = (60 * ((b1 - r1) / d) + 120) % 360;
                    break;
                case b1:
                    h = (60 * ((r1 - g1) / d) + 240) % 360;
                    break;
            }
            //h /= 6;
        }

        return {h: h, s: s, v: v};
    }

    rgbaToHsva(rgba: Rgba): Hsva {
        let r = Math.min(rgba.r, 1), g = Math.min(rgba.g, 1), b = Math.min(rgba.b, 1), a = Math.min(rgba.a, 1);
        let max = Math.max(r, g, b), min = Math.min(r, g, b);
        let h: number, s: number, v: number = max;

        let d = max - min;
        s = max === 0 ? 0 : d / max;

        if (max === min) {
            h = 0;
        } else {
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }

        return new Hsva(h, s, v, a)
    }

    hsvaToRgba(hsva: Hsva): Rgba {
        let h = hsva.h, s = hsva.s, v = hsva.v, a = hsva.a;
        let r: number, g: number, b: number;

        let i = Math.floor(h * 6);
        let f = h * 6 - i;
        let p = v * (1 - s);
        let q = v * (1 - f * s);
        let t = v * (1 - (1 - f) * s);

        switch (i % 6) {
            case 0:
                r = v, g = t, b = p;
                break;
            case 1:
                r = q, g = v, b = p;
                break;
            case 2:
                r = p, g = v, b = t;
                break;
            case 3:
                r = p, g = q, b = v;
                break;
            case 4:
                r = t, g = p, b = v;
                break;
            case 5:
                r = v, g = p, b = q;
                break;
        }

        return new Rgba(r, g, b, a)
    }

    stringToHsva(colorString: string = '', hex8: boolean = false): Hsva {
        let stringParsers = [
            {
                re: /(rgb)a?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*%?,\s*(\d{1,3})\s*%?(?:,\s*(\d+(?:\.\d+)?)\s*)?\)/,
                parse: function(execResult: any) {
                    return new Rgba(parseInt(execResult[2]) / 255,
                        parseInt(execResult[3]) / 255,
                        parseInt(execResult[4]) / 255,
                        isNaN(parseFloat(execResult[5])) ? 1 : parseFloat(execResult[5]));
                }
            },
            {
                re: /(hsl)a?\(\s*(\d{1,3})\s*,\s*(\d{1,3})%\s*,\s*(\d{1,3})%\s*(?:,\s*(\d+(?:\.\d+)?)\s*)?\)/,
                parse: function(execResult: any) {
                    return new Hsla(parseInt(execResult[2]) / 360,
                        parseInt(execResult[3]) / 100,
                        parseInt(execResult[4]) / 100,
                        isNaN(parseFloat(execResult[5])) ? 1 : parseFloat(execResult[5]));
                }
            }
        ];
        if (hex8) {
            stringParsers.push({
                re: /#([a-fA-F0-9]{2})([a-fA-F0-9]{2})([a-fA-F0-9]{2})([a-fA-F0-9]{2})$/,
                parse: function(execResult: any) {
                    return new Rgba(parseInt(execResult[1], 16) / 255,
                        parseInt(execResult[2], 16) / 255,
                        parseInt(execResult[3], 16) / 255,
                        parseInt(execResult[4], 16) / 255);
                }
            });
        } else {
            stringParsers.push({
                re: /#([a-fA-F0-9]{2})([a-fA-F0-9]{2})([a-fA-F0-9]{2})$/,
                parse: function(execResult: any) {
                    return new Rgba(parseInt(execResult[1], 16) / 255,
                        parseInt(execResult[2], 16) / 255,
                        parseInt(execResult[3], 16) / 255,
                        1);
                }
            },
                {
                    re: /#([a-fA-F0-9])([a-fA-F0-9])([a-fA-F0-9])$/,
                    parse: function(execResult: any) {
                        return new Rgba(parseInt(execResult[1] + execResult[1], 16) / 255,
                            parseInt(execResult[2] + execResult[2], 16) / 255,
                            parseInt(execResult[3] + execResult[3], 16) / 255,
                            1);
                    }
                });
        }


        colorString = colorString.toLowerCase();
        let hsva: Hsva = null;
        for (let key in stringParsers) {
            if (stringParsers.hasOwnProperty(key)) {
                let parser = stringParsers[key];
                let match = parser.re.exec(colorString), color: any = match && parser.parse(match);
                if (color) {
                    if (color instanceof Rgba) {
                        hsva = this.rgbaToHsva(color);
                    } else if (color instanceof Hsla) {
                        hsva = this.hsla2hsva(color);
                    }
                    return hsva;
                }
            }
        }
        return hsva;
    }

    outputFormat(hsva: Hsva, outputFormat: string, allowHex8: boolean): string {
        if (hsva.a < 1) {
            switch (outputFormat) {
                case 'hsla':
                    let hsla = this.hsva2hsla(hsva);
                    let hslaText = new Hsla(Math.round((hsla.h) * 360), Math.round(hsla.s * 100), Math.round(hsla.l * 100), Math.round(hsla.a * 100) / 100);
                    return 'hsla(' + hslaText.h + ',' + hslaText.s + '%,' + hslaText.l + '%,' + hslaText.a + ')';
                default:
                    if (allowHex8 && outputFormat === 'hex')
                        return this.hexText(this.denormalizeRGBA(this.hsvaToRgba(hsva)), allowHex8);
                    let rgba = this.denormalizeRGBA(this.hsvaToRgba(hsva));
                    return 'rgba(' + rgba.r + ',' + rgba.g + ',' + rgba.b + ',' + Math.round(rgba.a * 100) / 100 + ')';
            }
        } else {
            switch (outputFormat) {
                case 'hsla':
                    let hsla = this.hsva2hsla(hsva);
                    let hslaText = new Hsla(Math.round((hsla.h) * 360), Math.round(hsla.s * 100), Math.round(hsla.l * 100), Math.round(hsla.a * 100) / 100);
                    return 'hsl(' + hslaText.h + ',' + hslaText.s + '%,' + hslaText.l + '%)';
                case 'rgba':
                    let rgba = this.denormalizeRGBA(this.hsvaToRgba(hsva));
                    return 'rgb(' + rgba.r + ',' + rgba.g + ',' + rgba.b + ')';
                default:
                    return this.hexText(this.denormalizeRGBA(this.hsvaToRgba(hsva)), allowHex8);
            }
        }
    }

    hexText(rgba: Rgba, allowHex8: boolean): string {
        let hexText = '#' + ((1 << 24) | (rgba.r << 16) | (rgba.g << 8) | rgba.b).toString(16).substr(1);
        if (hexText[1] === hexText[2] && hexText[3] === hexText[4] && hexText[5] === hexText[6] && rgba.a === 1 && !allowHex8) {
            hexText = '#' + hexText[1] + hexText[3] + hexText[5];
        }
        if (allowHex8) {
            hexText += ((1 << 8) | Math.round(rgba.a * 255)).toString(16).substr(1);
        }
        return hexText;
    }

    denormalizeRGBA(rgba: Rgba): Rgba {
        return new Rgba(Math.round(rgba.r * 255), Math.round(rgba.g * 255), Math.round(rgba.b * 255), rgba.a);
    }

    //setB(val: { v: number, rg: number }) {
    setB(val: any, originalHsva:Hsva): string {
        let rgba = this.hsvaToRgba(originalHsva);
        rgba.b = val.v / val.rg;
        var temp = this.rgbaToHsva(rgba);
        return this.outputFormat(temp, 'rgba', true);
    }

    displayZCCColor( hexString: string, zccString:string): ColorPacket {
        var simpleHex = hexString.split('#').join('')
        var zcc = zccString
        let hex = simpleHex
        var o:ColorPacket = new ColorPacket();
        var r:any = 0;
        var g:any = 0;
        var b:any = 0;
        var a:any = 1;

        var expr1 = /([0-9]+%?)[^0-9A-F]+([0-9]+%?)[^0-9A-F]+([0-9]+%?)(?:[^\d.]+([\d.]+))?/i;
        var expr2 = /([0-9A-F][0-9A-F])([0-9A-F][0-9A-F])([0-9A-F]?[0-9A-F]?)/i;
        var expr3 = /([0-9A-F])([0-9A-F]?)([0-9A-F]?)/i;
        if (expr1.exec(hex)) {
            r = RegExp.$1;
            g = RegExp.$2;
            b = RegExp.$3;
            if (RegExp.$4 && RegExp.$4.length > 0) a = 1 * parseFloat(RegExp.$4);
            if (r.indexOf("%") >= 0) r = Math.round(parseInt(r) * 2.55);
            if (g.indexOf("%") >= 0) g = Math.round(parseInt(g) * 2.55);
            if (b.indexOf("%") >= 0) b = Math.round(parseInt(b) * 2.55);
        }
        else if (expr2.exec(simpleHex)) {
            r = parseInt((RegExp.$1 + "00").substr(0, 2), 16);
            g = parseInt((RegExp.$2 + "00").substr(0, 2), 16);
            b = parseInt((RegExp.$3 + "00").substr(0, 2), 16);
        }
        else if (expr3.exec(simpleHex)) {
            r = parseInt("0" + RegExp.$1 + RegExp.$1, 16);
            g = parseInt("0" + RegExp.$2 + RegExp.$2, 16);
            b = parseInt("0" + RegExp.$3 + RegExp.$3, 16);
        }

        r = Math.min(Math.max(0, r), 255);
        g = Math.min(Math.max(0, g), 255);
        b = Math.min(Math.max(0, b), 255);
        while (a > 1) a /= 100;

        simpleHex = "#" + (r < 16 ? "0" : "") + r.toString(16) +
            (g < 16 ? "0" : "") + g.toString(16) + (b < 16 ? "0" : "") + b.toString(16);
        simpleHex = simpleHex.toUpperCase();

        o.hex = '#' + hex.toUpperCase()
        o.r = r;
        o.g = g;
        o.b = b;

        simpleHex = "rgb(" + Math.round(r) + "," + Math.round(g) + "," + Math.round(b) + ")";

        o.fullRgb = simpleHex.toString();

        simpleHex = "rgba(" + Math.round(r) + "," + Math.round(g) + "," + Math.round(b) + "," + a + ")";

        o.fullRgba = simpleHex.toString();

        simpleHex = "rgb(" + Math.round(r / 2.55) + "%," + Math.round(g / 2.55) + "%," +
            Math.round(b / 2.55) + "%)";

        o.rPercent = Math.round(r / 2.55);
        o.gPercent = Math.round(g / 2.55);
        o.bPercent = Math.round(b / 2.55);
        o.fullRgbPercent = simpleHex.toString();

        simpleHex = "#" + (r < 1 ? "0" : Math.round(r / 17).toString(16)) +
            (g < 1 ? "0" : Math.round(g / 17).toString(16)) +
            (b < 1 ? "0" : Math.round(b / 17).toString(16));
            simpleHex = simpleHex.toUpperCase();

        o.hexShort = simpleHex.toString();

        simpleHex = "#" + (r < 1 ? "0" : (Math.round(r / 51) * 3).toString(16)) +
            (g < 1 ? "0" : (Math.round(g / 51) * 3).toString(16)) +
            (b < 1 ? "0" : (Math.round(b / 51) * 3).toString(16));
            simpleHex = simpleHex.toUpperCase();

        o.safe = simpleHex.toString();

        o.simpleHex = simpleHex
        o.zcc = zcc
        var hsl = this.rgbToHsl(o.r, o.g, o.b);
        o.h = hsl[0];
        o.s = hsl[1];
        o.l = hsl[2];

        var fc = Math.round(((Math.round(o.r) * 299) + (Math.round(o.g) * 587) + (Math.round(o.b) * 114)) /1000);
        if(fc > 125) {
            o.contrastingFontColor = 'black';
            o.contrastingShadowColor = 'rgba(0,0,0,0.5)'; //Black with opacity
        }else{
            o.contrastingFontColor = 'white';
            o.contrastingShadowColor = 'rgba(255,255,255,0.5)'; //White with opacity
        }

        return o;
    }

    rgbToHex(R,G,B): string {
        return this.toHex(R)+this.toHex(G)+this.toHex(B);
    }

    rgbStringToHex(color): string {
        var nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color),
            r = parseInt(nums[2], 10).toString(16),
            g = parseInt(nums[3], 10).toString(16),
            b = parseInt(nums[4], 10).toString(16);

        var s = (r.length == 1 ? "0"+ r : r) +
            (g.length == 1 ? "0"+ g : g) +
            (b.length == 1 ? "0"+ b : b);

        return "#" + s;
    }

    toHex(n):any {
        n = parseInt(n,10);
        if (isNaN(n)) return "00";
        n = Math.max(0,Math.min(n,255));
        return "0123456789ABCDEF".charAt((n-n%16)/16)
            + "0123456789ABCDEF".charAt(n%16);
    }

    /**
     * Converts an RGB color value to HSL. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes r, g, and b are contained in the set [0, 255] and
     * returns h, s, and l in the set [0, 1].
     *
     * @param   Number  r       The red color value
     * @param   Number  g       The green color value
     * @param   Number  b       The blue color value
     * @return  Array           The HSL representation
     */
    rgbToHsl(r, g, b):any{
        r /= 255, g /= 255, b /= 255;
        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h, s, l = (max + min) / 2;

        if(max == min){
            h = s = 0; // achromatic
        }else{
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch(max){
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }

        return [h, s, l];
    }

    /**
     * Converts an HSL color value to RGB. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes h, s, and l are contained in the set [0, 1] and
     * returns r, g, and b in the set [0, 255].
     *
     * @param   Number  h       The hue
     * @param   Number  s       The saturation
     * @param   Number  l       The lightness
     * @return  Array           The RGB representation
     */
    hslToRgb(h, s, l):any{
        var r, g, b;

        if(s == 0){
            r = g = b = l; // achromatic
        }else{
            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;
            r = this.hue2rgb(p, q, h + 1/3);
            g = this.hue2rgb(p, q, h);
            b = this.hue2rgb(p, q, h - 1/3);
        }

        return [r * 255, g * 255, b * 255];
    }

    hue2rgb(p, q, t):any{
        if(t < 0) t += 1;
        if(t > 1) t -= 1;
        if(t < 1/6) return p + (q - p) * 6 * t;
        if(t < 1/2) return q;
        if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
    }

    applySaturationToHexColor(hex, saturationPercent):string {
        if (!/^#([0-9a-f]{6})$/i.test(hex)) {
            throw('Unexpected color format');
        }

        if (saturationPercent < 0 || saturationPercent > 100) {
            throw('Unexpected color format');
        }

        var saturationFloat   = saturationPercent / 100,
            rgbIntensityFloat = [
                parseInt(hex.substr(1,2), 16) / 255,
                parseInt(hex.substr(3,2), 16) / 255,
                parseInt(hex.substr(5,2), 16) / 255
            ];

        var rgbIntensityFloatSorted = rgbIntensityFloat.slice(0).sort(function(a, b){ return a - b; }),
            maxIntensityFloat       = rgbIntensityFloatSorted[2],
            mediumIntensityFloat    = rgbIntensityFloatSorted[1],
            minIntensityFloat       = rgbIntensityFloatSorted[0];

        if (maxIntensityFloat == minIntensityFloat) {
            // All colors have same intensity, which means
            // the original color is gray, so we can't change saturation.
            return hex;
        }

        // New color max intensity wont change. Lets find medium and weak intensities.
        var newMediumIntensityFloat,
            newMinIntensityFloat = maxIntensityFloat * (1 - saturationFloat);

        if (mediumIntensityFloat == minIntensityFloat) {
            // Weak colors have equal intensity.
            newMediumIntensityFloat = newMinIntensityFloat;
        }
        else {
            // Calculate medium intensity with respect to original intensity proportion.
            var intensityProportion = (maxIntensityFloat - mediumIntensityFloat) / (mediumIntensityFloat - minIntensityFloat);
            newMediumIntensityFloat = (intensityProportion * newMinIntensityFloat + maxIntensityFloat) / (intensityProportion + 1);
        }

        var newRgbIntensityFloat       = [],
            newRgbIntensityFloatSorted = [newMinIntensityFloat, newMediumIntensityFloat, maxIntensityFloat];

        // We've found new intensities, but we have then sorted from min to max.
        // Now we have to restore original order.
        rgbIntensityFloat.forEach(function(originalRgb) {
            var rgbSortedIndex = rgbIntensityFloatSorted.indexOf(originalRgb);
            newRgbIntensityFloat.push(newRgbIntensityFloatSorted[rgbSortedIndex]);
        });

        var floatToHex = function(val) { return ('0' + Math.round(val * 255).toString(16)).substr(-2); },
            rgb2hex    = function(rgb) { return '#' + floatToHex(rgb[0]) + floatToHex(rgb[1]) + floatToHex(rgb[2]); };

        var newHex = rgb2hex(newRgbIntensityFloat);

        return newHex;
    }

    rgb2cmyk (r,g,b):any {
        var computedC = 0;
        var computedM = 0;
        var computedY = 0;
        var computedK = 0;

        //remove spaces from input RGB values, convert to int
        r = parseInt( (''+r).replace(/\s/g,''),10 );
        g = parseInt( (''+g).replace(/\s/g,''),10 );
        b = parseInt( (''+b).replace(/\s/g,''),10 );

        if ( r==null || g==null || b==null ||
            isNaN(r) || isNaN(g)|| isNaN(b) )
        {
           // alert ('Please enter numeric RGB values!');
            return;
        }
        if (r<0 || g<0 || b<0 || r>255 || g>255 || b>255) {
           // alert ('RGB values must be in the range 0 to 255.');
            return;
        }

        // BLACK
        if (r==0 && g==0 && b==0) {
            return {
              c: 0,
              m: 0,
              y: 0,
              k: 100
            }
        }

        computedC = 1 - (r/255);
        computedM = 1 - (g/255);
        computedY = 1 - (b/255);

        var minCMY = Math.min( computedC, Math.min( computedM,computedY ) );

        computedC = (computedC - minCMY) / (1 - minCMY) ;
        computedM = (computedM - minCMY) / (1 - minCMY) ;
        computedY = (computedY - minCMY) / (1 - minCMY) ;

        computedK = minCMY;

        return {
          c: Math.round(100 * ( Math.round(computedC * 1000) / 1000 )),
          m: Math.round(100 * ( Math.round(computedM * 1000) / 1000 )),
          y: Math.round(100 * ( Math.round(computedY * 1000) / 1000 )),
          k: Math.round(100 * ( Math.round(computedK * 1000) / 1000 ))
        };
    }

    hexToCMYK (hex): any {
        var computedC = 0;
        var computedM = 0;
        var computedY = 0;
        var computedK = 0;

        let r: number, g: number, b: number;
        let rgb = ColorPickerService.hexToRgb(hex);
        r = rgb.r; g = rgb.g; b = rgb.b;

        // BLACK
        if (r==0 && g==0 && b==0) {
            computedK = 1;
            return [0,0,0,1];
        }

        computedC = 1 - (r/255);
        computedM = 1 - (g/255);
        computedY = 1 - (b/255);

        var minCMY = Math.min(computedC,Math.min(computedM,computedY));

        computedC = (computedC - minCMY) / (1 - minCMY) ;
        computedM = (computedM - minCMY) / (1 - minCMY) ;
        computedY = (computedY - minCMY) / (1 - minCMY) ;
        computedK = minCMY;

        return {
          c: Math.round(100 * ( Math.round(computedC * 1000) / 1000 )),
          m: Math.round(100 * ( Math.round(computedM * 1000) / 1000 )),
          y: Math.round(100 * ( Math.round(computedY * 1000) / 1000 )),
          k: Math.round(100 * ( Math.round(computedK * 1000) / 1000 ))
        };
    }

    cmyk2rgb(c, m, y, k):any {

      c = c / 100;
      m = m / 100;
      y = y / 100;
      k = k / 100;

      var r = 255 * ( 1-c ) * ( 1-k );
      var g = 255 * ( 1-m ) * ( 1-k );
      var b = 255 * ( 1-y ) * ( 1-k );

      r = Math.round(r * 1) / 1;
      g = Math.round(g * 1) / 1;
      b = Math.round(b * 1) / 1;

      return {
        r: r,
        g: g,
        b: b
      }
    }

    cmyk2hex(c, m, y, k):string {
      var o = this.cmyk2rgb(c,m,y,k);

      return this.rgbToHex(o.r, o.g, o.b);
    }

    // Changes the RGB/HEX temporarily to a HSL-Value, modifies that value
    // and changes it back to RGB/HEX.

    changeHue(rgb, degree): any {
        var hsl = this.rgbHexToHSL(rgb);

        hsl.h += degree;
        if (hsl.h > 360) {
            hsl.h -= 360;
        }
        else if (hsl.h < 0) {
            hsl.h += 360;
        }
        return this.hslToRGB(hsl);
    }

    // exepcts a string and returns an object
    rgbHexToHSL(rgb): any {
        // strip the leading # if it's there
        rgb = rgb.replace(/^\s*#|\s*$/g, '');

        // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
        if (rgb.length == 3) {
            rgb = rgb.replace(/(.)/g, '$1$1');
        }

        var r = parseInt(rgb.substr(0, 2), 16) / 255,
            g = parseInt(rgb.substr(2, 2), 16) / 255,
            b = parseInt(rgb.substr(4, 2), 16) / 255,
            cMax = Math.max(r, g, b),
            cMin = Math.min(r, g, b),
            delta = cMax - cMin,
            l = (cMax + cMin) / 2,
            h = 0,
            s = 0;

        if (delta == 0) {
            h = 0;
        }
        else if (cMax == r) {
            h = 60 * (((g - b) / delta) % 6);
        }
        else if (cMax == g) {
            h = 60 * (((b - r) / delta) + 2);
        }
        else {
            h = 60 * (((r - g) / delta) + 4);
        }

        if (delta == 0) {
            s = 0;
        }
        else {
            s = (delta / (1 - Math.abs(2 * l - 1)))
        }

        return {
            h: h,
            s: s,
            l: l
        }
    }

    // expects an object and returns a string
    hslToRGB(hsl): any {
        var h = hsl.h,
            s = hsl.s,
            l = hsl.l,
            c = (1 - Math.abs(2 * l - 1)) * s,
            x = c * ( 1 - Math.abs((h / 60 ) % 2 - 1)),
            m = l - c / 2,
            r, g, b;

        if (h < 60) {
            r = c;
            g = x;
            b = 0;
        }
        else if (h < 120) {
            r = x;
            g = c;
            b = 0;
        }
        else if (h < 180) {
            r = 0;
            g = c;
            b = x;
        }
        else if (h < 240) {
            r = 0;
            g = x;
            b = c;
        }
        else if (h < 300) {
            r = x;
            g = 0;
            b = c;
        }
        else {
            r = c;
            g = 0;
            b = x;
        }

        r = this.normalize_rgb_value(r, m);
        g = this.normalize_rgb_value(g, m);
        b = this.normalize_rgb_value(b, m);

        return this.rgbToHex(r, g, b);
    }

    normalize_rgb_value(color, m): void {
        color = Math.floor((color + m) * 255);
        if (color < 0) {
            color = 0;
        }
        return color;
    }

    //https://github.com/antimatter15/rgb-lab/blob/master/color.js
    /**
     * Expects an array [l, a, b]
     * @param lab
     * @returns {[number,number,number]}
     */
    lab2rgb(lab): any {
        var y = (lab[0] + 16) / 116,
            x = lab[1] / 500 + y,
            z = y - lab[2] / 200,
            r, g, b;

        x = 0.95047 * ((x * x * x > 0.008856) ? x * x * x : (x - 16 / 116) / 7.787);
        y = 1.00000 * ((y * y * y > 0.008856) ? y * y * y : (y - 16 / 116) / 7.787);
        z = 1.08883 * ((z * z * z > 0.008856) ? z * z * z : (z - 16 / 116) / 7.787);

        r = x * 3.2406 + y * -1.5372 + z * -0.4986;
        g = x * -0.9689 + y * 1.8758 + z * 0.0415;
        b = x * 0.0557 + y * -0.2040 + z * 1.0570;

        r = (r > 0.0031308) ? (1.055 * Math.pow(r, 1 / 2.4) - 0.055) : 12.92 * r;
        g = (g > 0.0031308) ? (1.055 * Math.pow(g, 1 / 2.4) - 0.055) : 12.92 * g;
        b = (b > 0.0031308) ? (1.055 * Math.pow(b, 1 / 2.4) - 0.055) : 12.92 * b;

        return [Math.max(0, Math.min(1, r)) * 255,
            Math.max(0, Math.min(1, g)) * 255,
            Math.max(0, Math.min(1, b)) * 255]
    }

    //https://github.com/antimatter15/rgb-lab/blob/master/color.js
    /**
     * Expects and array [r,g,b]
     * @param rgb
     * @returns {[number,number,number]}
     */
    static rgb2lab(rgb): Array<number> {
        var r = rgb[0] / 255,
            g = rgb[1] / 255,
            b = rgb[2] / 255,
            x, y, z;

        r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
        g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
        b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;

        x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
        y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
        z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;

        x = (x > 0.008856) ? Math.pow(x, 1 / 3) : (7.787 * x) + 16 / 116;
        y = (y > 0.008856) ? Math.pow(y, 1 / 3) : (7.787 * y) + 16 / 116;
        z = (z > 0.008856) ? Math.pow(z, 1 / 3) : (7.787 * z) + 16 / 116;

        return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
    }

  /* hexToComplimentary : Converts hex value to HSL, shifts
   * hue by 180 degrees and then converts hex, giving complimentary color
   * as a hex value
   * @param  [String] hex : hex value
   * @return [String] : complimentary color as hex value
   */
  hexToComplimentary(hex): any {

    // Convert hex to rgb
    // Credit to Denis http://stackoverflow.com/a/36253499/4939630
    var rgb = 'rgb(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g')).map(function (l) {
        return parseInt(hex.length % 2 ? l + l : l, 16);
      }).join(',') + ')';
    // Get array of RGB values
    let rgbArray = rgb.replace(/[^\d,]/g, '').split(',');

    var r = parseInt(rgbArray[0]), g = parseInt(rgbArray[1]), b = parseInt(rgbArray[2]);

    // Convert RGB to HSL
    // Adapted from answer by 0x000f http://stackoverflow.com/a/34946092/4939630
    r /= 255.0;
    g /= 255.0;
    b /= 255.0;
    var max = Math.max(r, g, b);
    var min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2.0;

    if (max == min) {
      h = s = 0;  //achromatic
    } else {
      var d = max - min;
      s = (l > 0.5 ? d / (2.0 - max - min) : d / (max + min));

      if (max == r && g >= b) {
        h = 1.0472 * (g - b) / d;
      } else if (max == r && g < b) {
        h = 1.0472 * (g - b) / d + 6.2832;
      } else if (max == g) {
        h = 1.0472 * (b - r) / d + 2.0944;
      } else if (max == b) {
        h = 1.0472 * (r - g) / d + 4.1888;
      }
    }

    h = h / 6.2832 * 360.0 + 0;

    // Shift hue to opposite side of wheel and convert to [0-1] value
    h += 180;
    if (h > 360) {
      h -= 360;
    }
    h /= 360;

    // Convert h s and l values into r g and b values
    // Adapted from answer by Mohsen http://stackoverflow.com/a/9493060/4939630
    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      var p = 2 * l - q;

      r = this.hue2rgb(p, q, h + 1 / 3);
      g = this.hue2rgb(p, q, h);
      b = this.hue2rgb(p, q, h - 1 / 3);
    }

    r = Math.round(r * 255);
    g = Math.round(g * 255);
    b = Math.round(b * 255);

    // Convert r b and g values to hex
    let compHex = b | (g << 8) | (r << 16);
    // debugger;
/*
    return "#" + (0x1000000 | rgb).toString(16).substring(1);
    */
  }

  // Rounding to standard rgb hex for zcc. e.g. 'C8' -> 'CC'  ,  'C1' -> 'BB'
  static roundHex( hex: string ) {
    return this.channelValueToRoundedHex(parseInt(hex,16));
  }
  // channel value: 0-255 for r, g, or b
  static channelValueToRoundedHex( channelValue: number ): string {
    let h = this.roundChannelValue(channelValue).toString(16)
    if(h === '0') {
      h = '00'
    }
    return h
  }
  static roundChannelValue( channelValue: number ): number {
    return (Math.round(1.0 * channelValue / 17) * 17)
  }
}
