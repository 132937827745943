import {PaletteModel} from "../models/palette.model";
import {SwatchModel} from "../models/swatch.model";


// declare var _: any;
import * as _ from 'underscore';


export class SwatchChangedEvent {

    public oldSwatchId: string = "";
    public oldPaletteId: string = "";
    public newSwatch: SwatchModel;
    public newPalette: PaletteModel;


    constructor(o:any=null) {
        if( o ) {
            _.extend(this, o);
        }
    }

}
