import {Injector, Injectable} from '@angular/core';
import {ColorPickerService} from "../components/color-picker/color-picker.service";
import {GlobalModel} from "../models/global.model";
import {ZCC, ColorPacket} from "./zcc";
import {Hsva, Hsla, Rgba} from "../components/color-picker/classes";
import {SwatchModel} from "../models/swatch.model";

declare var _: any;
interface ColorInfo {
  colorPacket: ColorPacket;
  brightness: {
    bLevel1: string;
    bLevel2: string;
    bLevel3: string;
    bLevel4: string;
    bLevel5: string;
    bLevel6: string;
  };
  saturation: {
    sLevel1: string;
    sLevel2: string;
    sLevel3: string;
    sLevel4: string;
    sLevel5: string;
    sLevel6: string;
  };
  hues: {
    hLevel1: string;
    hLevel2: string;
    hLevel3: string;
    hLevel4: string;
    hLevel5: string;
    hLevel6: string;
  };
  reds: {
    redLevel1: string;
    redLevel2: string;
    redLevel3: string;
  };
  greens: {
    greenLevel1: string;
    greenLevel2: string;
    greenLevel3: string;
  }
}

export class Cmyk {
    constructor(public c: number, public m: number, public y: number, public k: number) { }
}

export class ColorUtility {
    private cpAlphaChannel: string = "hex8";

    constructor(public gm:GlobalModel, public service:ColorPickerService) {
    }

    /**
     * Generates all colors that map to a swatch and the UI.
     * @param hex
     */

    generateZCCColors(hex:string, zcc:string, swatch:SwatchModel):any {
      let color = hex;

      var o = this.gm.colorUtility.service.displayZCCColor(hex, zcc);

      let r:ColorInfo = {
        colorPacket: o,
        brightness: { bLevel1: '', bLevel2: '', bLevel3: '', bLevel4: '', bLevel5: '', bLevel6: '' },
        saturation: { sLevel1: '', sLevel2: '', sLevel3: '', sLevel4: '', sLevel5: '', sLevel6: '' },
        hues: { hLevel1: '', hLevel2: '', hLevel3: '', hLevel4: '', hLevel5: '', hLevel6: '' },
        reds: { redLevel1: '', redLevel2: '', redLevel3: '' },
        greens: { greenLevel1: '', greenLevel2: '', greenLevel3: '' },
        // brightness: this.generateBrightness(o),
        // saturation: this.generateSaturation(o),
        // greens: this.generateGreens(o),
        // reds: this.generateReds(o),
        // hues: this.generateHues(o)
      }

      swatch.contrastingFontColor = o.contrastingFontColor;
      swatch.contrastingShadowColor = o.contrastingShadowColor;
      swatch.brightness1 = r.brightness.bLevel1;
      swatch.brightness2 = r.brightness.bLevel2;
      swatch.brightness3 = r.brightness.bLevel3;
      swatch.brightness4 = r.brightness.bLevel4;
      swatch.brightness5 = r.brightness.bLevel5;
      swatch.brightness6 = r.brightness.bLevel6;
      swatch.saturation1 = r.saturation.sLevel1;
      swatch.saturation2 = r.saturation.sLevel2;
      swatch.saturation3 = r.saturation.sLevel3;
      swatch.saturation4 = r.saturation.sLevel4;
      swatch.saturation5 = r.saturation.sLevel5;
      swatch.saturation6 = r.saturation.sLevel6;
      swatch.hue1 = r.hues.hLevel1;
      swatch.hue2 = r.hues.hLevel2;
      swatch.hue3 = r.hues.hLevel3;
      swatch.hue4 = r.hues.hLevel4;
      swatch.hue5 = r.hues.hLevel5;
      swatch.hue6 = r.hues.hLevel6;
      swatch.red1 = r.reds.redLevel1;
      swatch.red2 = r.reds.redLevel2;
      swatch.red3 = r.reds.redLevel3;
      swatch.green1 = r.greens.greenLevel1;
      swatch.green2 = r.greens.greenLevel2;
      swatch.green3 = r.greens.greenLevel3;
      swatch.zcc = o.zcc || 'ZCC-0001';
      swatch.rgb = o.hex;
      
      var rgb = ColorPickerService.hexToRgb( o.hex );
      var cmyk = this.service.rgb2cmyk( rgb.r, rgb.g, rgb.b );
      var hsl = this.service.rgbHexToHSL( o.hex );
      var hsl = this.service.rgbHexToHSL( o.hex );
      var lab = ColorPickerService.rgb2lab( [rgb.r, rgb.g, rgb.b] );

      swatch.labfull = Math.round(lab[0]) + ',' + Math.round(lab[1]) + ',' + Math.round(lab[2]);
      swatch.hslfull = Math.round(hsl.h) + ',' + Math.round(hsl.s * 100) + ',' + Math.round(hsl.l * 100);
      swatch.rgbfull = rgb.r + ',' + rgb.g + ',' + rgb.b;
      swatch.cmykfull = cmyk.c + ',' + cmyk.m + ',' + cmyk.y + ',' + cmyk.k;
      swatch.hue = Math.round(hsl.h).toString() 
      swatch.saturation = Math.round(hsl.s*100).toString()
      swatch.lightness = Math.round(hsl.l*100).toString()

      return r;
  }
    generateGreens(c:ColorPacket):any {
      return {
        greenLevel1: c.getAdjustedGreenValue(this.gm.preferencesModel.data.settings.editSwatch.green.level1),
        greenLevel2: c.getAdjustedGreenValue(this.gm.preferencesModel.data.settings.editSwatch.green.level2),
        greenLevel3: c.getAdjustedGreenValue(this.gm.preferencesModel.data.settings.editSwatch.green.level3)
      }
    }

    generateReds(c:ColorPacket):any {
      return {
        redLevel1: c.getAdjustedRedValue(this.gm.preferencesModel.data.settings.editSwatch.red.level1),
        redLevel2: c.getAdjustedRedValue(this.gm.preferencesModel.data.settings.editSwatch.red.level2),
        redLevel3: c.getAdjustedRedValue(this.gm.preferencesModel.data.settings.editSwatch.red.level3)
      }
    }

    generateBrightness(c:ColorPacket):any {
      return {
        bLevel1: c.getAdjustedBrightnessValue(this.gm.preferencesModel.data.settings.editSwatch.brightness.level1, this.service),
        bLevel2: c.getAdjustedBrightnessValue(this.gm.preferencesModel.data.settings.editSwatch.brightness.level2, this.service),
        bLevel3: c.getAdjustedBrightnessValue(this.gm.preferencesModel.data.settings.editSwatch.brightness.level3, this.service),
        bLevel4: c.getAdjustedBrightnessValue(this.gm.preferencesModel.data.settings.editSwatch.brightness.level4, this.service),
        bLevel5: c.getAdjustedBrightnessValue(this.gm.preferencesModel.data.settings.editSwatch.brightness.level5, this.service),
        bLevel6: c.getAdjustedBrightnessValue(this.gm.preferencesModel.data.settings.editSwatch.brightness.level6, this.service)
      }
    }

    generateSaturation(c:ColorPacket):any {
      return {
        sLevel1: c.getAdjustedSaturationValue(this.gm.preferencesModel.data.settings.editSwatch.saturation.level1, this.service),
        sLevel2: c.getAdjustedSaturationValue(this.gm.preferencesModel.data.settings.editSwatch.saturation.level2, this.service),
        sLevel3: c.getAdjustedSaturationValue(this.gm.preferencesModel.data.settings.editSwatch.saturation.level3, this.service),
        sLevel4: c.getAdjustedSaturationValue(this.gm.preferencesModel.data.settings.editSwatch.saturation.level4, this.service),
        sLevel5: c.getAdjustedSaturationValue(this.gm.preferencesModel.data.settings.editSwatch.saturation.level5, this.service),
        sLevel6: c.getAdjustedSaturationValue(this.gm.preferencesModel.data.settings.editSwatch.saturation.level6, this.service)
      }
    }

    generateHues(c:ColorPacket):any {
      return {
        hLevel1: c.getAdjustedHueValue(this.gm.preferencesModel.data.settings.editSwatch.hue.level1, this.service),
        hLevel2: c.getAdjustedHueValue(this.gm.preferencesModel.data.settings.editSwatch.hue.level2, this.service),
        hLevel3: c.getAdjustedHueValue(this.gm.preferencesModel.data.settings.editSwatch.hue.level3, this.service),
        hLevel4: c.getAdjustedHueValue(this.gm.preferencesModel.data.settings.editSwatch.hue.level4, this.service),
        hLevel5: c.getAdjustedHueValue(this.gm.preferencesModel.data.settings.editSwatch.hue.level5, this.service),
        hLevel6: c.getAdjustedHueValue(this.gm.preferencesModel.data.settings.editSwatch.hue.level6, this.service)
      }
    }
}
