import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'color-picker',
	templateUrl: './color-picker.html',
	styleUrls: ['./color-picker.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ColorPickerPage implements OnInit {
	subLinks: any[]
	currentTab: String;
	constructor() {}
	ngOnInit() {
		
		this.currentTab = 'enter-a-color-code'
		this.subLinks = [
			{
				'text':'COLOR PICKER',
				'tabLink': 'enter-a-color-code',
				'tooltip': 'The bottom half of the Compare Swatch has editable fields for RGB, HEX and CMYK. Enter the coordinates and select the ENTER button to automatically normalize the input values into a ZCC® universal standard. Select ADD TO WORKSPACE to move the swatch to your work area.'
			},
			{
				'text':'COLOR HARMONY',
				'tabLink': 'color-harmony',
				'tooltip': 'color-harmony'
			},
			{
				'text':'COLOR THEORY',
				'tabLink': 'color-theory',
				'tooltip': 'color theory'
			}
		]
	}


}