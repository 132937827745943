import { Component, OnInit, ViewEncapsulation, Injector, Input } from '@angular/core';
import { BaseComponent } from '../../base.component'
import {SwatchChangedEvent} from "../../events/swatch.changed.event";
import {SwatchModel} from "../../models/swatch.model";
import {PaletteModel} from "../../models/palette.model";
import { ColorPickerService } from '../../components/color-picker/color-picker.service';

@Component({
	selector: 'common-file-colors-box',
	templateUrl: './colors-file-box.html',
	styleUrls: ['./colors-file-box.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ColorsFileBoxCommon extends BaseComponent implements OnInit {
	swatchesList: any[] = []
	@Input() palette: PaletteModel;
	@Input() swatch: SwatchModel;
	
	constructor(public injector: Injector) {
		super(injector);
	 }

	ngOnInit() {
	}

	contrastingFontColor(swatch): string {
    return ColorPickerService.isDarkColor(swatch.rgb) ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)';
	}
	
	contrastingCheckIconColor(swatch): string {
    return ColorPickerService.isDarkColor(swatch.rgb) ? 'check' : 'black-check';
	}
	
	contrastingCrossIconColor(swatch): string {
    return ColorPickerService.isDarkColor(swatch.rgb) ? 'close' : 'black-close';
	}
	
	


	missingTagBlack(swatch): any {
		if(swatch.empty || this.gm.palettesFileModel.selectedPalette.shouldShowMissingTags == false){
			return false;
		} else {
			return swatch.missingRefTag;
		}
	}

	selectSwatch(event):void {
        this.gm.palettesFileModel.selectedPalette.deselectAllSwatches();

        var o = new SwatchChangedEvent({
            "oldSwatchId": this.gm.palettesFileModel.selectedSwatch.id,
            "oldPaletteId": this.gm.palettesFileModel.selectedPalette.id,
            "newSwatch": event,
            "newPalette": this.palette
        });
        event.selected = true;
        this.gm.palettesFileModel.selectedSwatch = event;
       // this.gm.colorUtility.generateColors(this.gm.palettesFileModel.selectedSwatch.rgb, this.gm.palettesFileModel.selectedSwatch);
        this.gm.palettesFileModel.selectedPalette = this.palette;
        this.gm.palettesFileModel.data.activePaletteId = this.palette.id;

		this.gm.palettesFileModel.swatchChanged.emit(o);
	}
	

	deleteSwatch(swatch){
		if(swatch.empty){
			alert("The swatch is already empty.");
			return;
		} else {
		  for ( var i = 0; i < this.palette.swatches.length; i ++ ) {
			if (this.palette.swatches[i].id === swatch.id) {
				let deletedSwatch = Object.assign({}, this.palette.swatches[i]);
				let index = this.gm.palettesFileModel.selectedPalette.swatches.findIndex(x=>x.id==this.palette.swatches[i].id);
				let deletedArr = {index:index, data:deletedSwatch};
				this.gm.deletedStack.push(deletedArr);
			  this.palette.swatches[i].empty = true;
			  this.palette.swatches[i].zcc = "ZCC-0000"
				this.palette.swatches[i].contrastingFontColor = "white";
			
				this.palette.swatches[i].swatchCoupleZcc = "ZCC-0000";
				this.palette.swatches[i].swatchCouplergb = "884444";
				this.palette.swatches[i].swatchCouplergbfull = "0, 0, 0";
				this.palette.swatches[i].deltaE = null
				this.palette.swatches[i].comparisonResult = 'none';
				this.palette.swatches[i].lock = false;

			  this.gm.palettesFileModel.selectedSwatch = new SwatchModel({});
			  this.gm.palettesFileModel.updatePalette(this.palette);
			  this.gm.palettesFileModel.writeDefaultFile();
			}
		  }
		}
	}

	  deleteSelectedPalette(){
		if (this.gm.palettesFileModel.selectedSwatch.empty == false) {
		  let deletedSwatch = Object.assign({}, this.gm.palettesFileModel.selectedSwatch);
		  let index = this.gm.palettesFileModel.selectedPalette.swatches.findIndex(x=>x.id==this.gm.palettesFileModel.selectedSwatch.id);
		  let deletedArr = {index:index, data:deletedSwatch};
		  this.gm.deletedStack.push(deletedArr);
		  this.gm.palettesFileModel.selectedSwatch.empty = true
		  this.gm.palettesFileModel.selectedSwatch.refname = ""
		  this.gm.palettesFileModel.selectedSwatch.refnumber = ""
			this.gm.palettesFileModel.selectedSwatch.contrastingFontColor = "white";
			
			this.gm.palettesFileModel.selectedSwatch.swatchCoupleZcc = "ZCC-0000";
				this.gm.palettesFileModel.selectedSwatch.swatchCouplergb = "884444";
				this.gm.palettesFileModel.selectedSwatch.swatchCouplergbfull = "0, 0, 0";
				this.gm.palettesFileModel.selectedSwatch.deltaE = null
				this.gm.palettesFileModel.selectedSwatch.comparisonResult = 'none';
				this.gm.palettesFileModel.selectedSwatch.lock = false;


		  this.gm.palettesFileModel.updatePalette(this.gm.palettesFileModel.selectedSwatch);
		  this.gm.palettesFileModel.writeDefaultFile();
		}
	  }

	  removeAllPallets() {
			this.gm.deletedAllStack = [];
			for (let i = 0; i < this.gm.palettesFileModel.selectedPalette.swatches.length; i++) {
				let swatch = this.gm.palettesFileModel.selectedPalette.swatches[i];
				if (swatch.empty == false) {
					let deletedSwatch = Object.assign({}, swatch);	
					let deletedArr = {index:i, data:deletedSwatch};
					this.gm.deletedAllStack.push(deletedArr);
				}
			}
			this.gm.palettesFileModel.removePalette( this.palette );
			this.gm.palettesFileModel.writeDefaultFile();
			this.gm.deletedStack = [];
		}
		undoDelete(){
			if (this.gm.deletedAllStack.length > 0) {
				//fill the stacks on clear all
				while (this.gm.deletedAllStack.length > 0) {
					let lastDeleted = this.gm.deletedAllStack.pop();
					this.gm.palettesFileModel.selectedPalette.swatches[lastDeleted.index] = lastDeleted.data;
					this.gm.palettesFileModel.updatePalette(this.gm.palettesFileModel.selectedPalette.swatches[lastDeleted.index]);
					this.gm.palettesFileModel.selectedPalette.swatches[lastDeleted.index];
					var o = new SwatchChangedEvent({
						"oldSwatchId": this.gm.palettesFileModel.selectedSwatch.id,
						"oldPaletteId": this.gm.palettesFileModel.selectedPalette.id,
						"newSwatch": lastDeleted.data,
						"newPalette": this.palette
				});
				this.gm.palettesFileModel.selectedPalette = this.palette;
				this.gm.palettesFileModel.data.activePaletteId = this.palette.id;
				}
				this.gm.palettesFileModel.writeDefaultFile();
				this.gm.palettesFileModel.selectedPalette.deselectAllSwatches();
				this.gm.palettesFileModel.swatchChanged.emit(o);
				this.gm.deletedAllStack = [];
				return
			}
			let lastDeleted = this.gm.deletedStack.pop();
			if(lastDeleted){
				this.gm.palettesFileModel.selectedPalette.swatches[lastDeleted.index] = lastDeleted.data;
				this.gm.palettesFileModel.updatePalette(this.gm.palettesFileModel.selectedSwatch);
				this.gm.palettesFileModel.writeDefaultFile();
				this.gm.palettesFileModel.selectedPalette.deselectAllSwatches();
				this.gm.palettesFileModel.selectedPalette.swatches[lastDeleted.index].selected = true;
				var o = new SwatchChangedEvent({
					"oldSwatchId": this.gm.palettesFileModel.selectedSwatch.id,
					"oldPaletteId": this.gm.palettesFileModel.selectedPalette.id,
					"newSwatch": lastDeleted.data,
					"newPalette": this.palette
			});
			this.gm.palettesFileModel.selectedSwatch = lastDeleted.data ;
			this.gm.palettesFileModel.selectedPalette = this.palette;
			this.gm.palettesFileModel.data.activePaletteId = this.palette.id;
	
			this.gm.palettesFileModel.swatchChanged.emit(o);
			}
		}

}