import { Component, Output, EventEmitter, Input, Injector } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponentSecondary } from '../base.component.secondary';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent extends BaseComponentSecondary {
  @Input() id: number;
  myForm: FormGroup;
  public title: String = 'IMAGE QA REPORT'
  public importedImageName: String = ''
  public importedReportName: String = ''
  public imageMangerReport: Boolean = true
  constructor(
    public injector: Injector,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public confirmationDialogService: ConfirmationDialogService
  ) {
    super(injector);
    this.createForm();
  }
  private createForm() {
    this.myForm = this.formBuilder.group({
      swatchFileName: "",//this.gm.paletteFileName.length > 0 ? 'report_' + this.gm.paletteFileName.substring(0, this.gm.paletteFileName.lastIndexOf('.')) : "",
      techpack: this.gm.palettesFileModel.selectedPalette.techpack,
      customer: "",
      skuNumber: "",
      productDescription:"",
      approvedBy: "",
      approvedDate: ""
    });
  }
  public submitForm() {
    if (this.myForm.value.swatchFileName.length == 0 || this.myForm.value.swatchFileName == undefined || this.myForm.value.swatchFileName == null) {
      this.confirmationDialogService.confirm('ALERT!', 'Please enter file name', ' ', 'OK')
        .then((confirmed) => {
          if (confirmed) {
          }
        })
        .catch(() => {

        });
      return
    }

    this.activeModal.close(this.myForm.value);
  }
}