import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { Error404Page } from './pages/error-404/error-404'
import { DigitalImageManager } from './pages/digital-image-manager/digital-image-manager.page'
import { Sample } from './pages/sample/sample.page'
import { FileExchange } from './pages/fileexchange/file-exchange.page'
import { PaletteBuilderComponent } from './pages/palette-builder/palette-builder.component'
import { SwatchPicker } from './pages/swatch-picker/swatch-picker.page'
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { LoginGuard } from './login.guard';
import { ImageExchange } from './pages/imageexchange/image-exchange.page'

const routes: Routes = [
	{
		path: '',
		redirectTo: '/login',
		pathMatch: 'full'
	},
	{
		path: 'login',
		component: LoginComponent,
		canActivate: [LoginGuard]
	},
	{
		path: 'library',
		component: SwatchPicker,
		data: {
			title: 'ZENCOLOR® COMPLETE | PICKER'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'swatch-exchange',
		component: FileExchange,
		data: {
			title: 'ZENCOLOR® COMPLETE | SWATCH EXCHANGE'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'imageexchange',
		component: ImageExchange,
		data: {
			title: 'ZENCOLOR® COMPLETE | IMAGE EXCHANGE'
		},
		canActivate: [AuthGuard]
	},

	{
		path: 'create',
		component: PaletteBuilderComponent,
		data: {
			title: 'ZENCOLOR® COMPLETE | CREATE'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'image-manager',
		component: DigitalImageManager,
		data: {
			title: 'ZENCOLOR® COMPLETE | IMAGE MANAGER'
		},
		canActivate: [AuthGuard]
	},
	{
		path: 'capture',
		component: Sample,
		data: {
			title: 'ZENCOLOR® COMPLETE | CAPTURE'
		},
		canActivate: [AuthGuard]
	},
	{
		path: '**',
		component: Error404Page,
		data: {
			title: 'Error - 404 | Page Not Found'
		}
	}
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }