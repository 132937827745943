import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'file-app-confirmation-dialog',
  templateUrl: './file-confirmation-dialog.component.html',
  styleUrls: ['./file-confirmation-dialog.component.scss'],
})
export class FileConfirmationDialogComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() btnMergeText: string;
  @Input() btnReplaceText: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  public decline() {
    this.activeModal.close(0);
  }

  
  public acceptMerge() {
    this.activeModal.close(1);
  }

  public acceptReplace() {
    this.activeModal.close(2);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
