/**
 * Component class to select
 * a single swatch in workarea
 */
import {ElementRef, Component, OnInit, Injector, Input} from '@angular/core';
import {BaseComponent} from "../base.component";
import {SwatchModel} from "../models/swatch.model";
import {SwatchChangedEvent} from "../events/swatch.changed.event";
import {PaletteModel} from "../models/palette.model";
import {ColorUtility} from "../utils/color.utility";
import {ColorPickerService} from "./color-picker/color-picker.service";

declare var _: any;
declare var jQuery: any;
@Component({
    selector: 'selectable-swatch-file',
    styles: ['./selectable-swatch-file.scss'],
    template: './selectable-swatch-file.component.html'
})
export class SelectableSwatchFileComponent extends BaseComponent {
    @Input() swatch: SwatchModel;
    @Input() palette: PaletteModel;

    colorUtility:ColorUtility;
    colorPickerService:ColorPickerService;
    constructor(public injector: Injector, el: ElementRef) {
        super(injector);
    }

    private get missingTagWhite(): any {
      if(this.swatch.empty || this.gm.palettesFileModel.selectedPalette.shouldShowMissingTags == false){
        return false;
      } else if(this.swatch.contrastingFontColor == 'white') {
        return this.swatch.missingRefTag
      } else {
        return false;
      }
    }

    private get missingTagBlack(): any {
      if(this.swatch.empty || this.gm.palettesFileModel.selectedPalette.shouldShowMissingTags == false){
        return false;
      } else if(this.swatch.contrastingFontColor != 'white') {
        return this.swatch.missingRefTag
      } else {
        return false;
      }
    }


    selectSwatch():void {
        //jQuery('#currentZccText').val('');
        jQuery('#currentZccTextCheckbox').attr('checked', false);
        this.gm.palettesFileModel.selectedPalette.deselectAllSwatches();

        var o = new SwatchChangedEvent({
            "oldSwatchId": this.gm.palettesFileModel.selectedSwatch.id,
            "oldPaletteId": this.gm.palettesFileModel.selectedPalette.id,
            "newSwatch": this.swatch,
            "newPalette": this.palette
        });
        this.swatch.selected = true;
        this.gm.palettesFileModel.selectedSwatch = this.swatch;
        //this.gm.colorUtility.generateColors(this.gm.palettesFileModel.selectedSwatch.rgb, this.gm.palettesFileModel.selectedSwatch);
        this.gm.palettesFileModel.selectedPalette = this.palette;
        this.gm.palettesFileModel.data.activePaletteId = this.palette.id;

        this.gm.palettesFileModel.swatchChanged.emit(o);
    }

}
