import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ApiService } from '../../api.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'batch-data-conversions',
	templateUrl: './batch-data-conversions.html',
	styleUrls: ['./batch-data-conversions.scss'],
	encapsulation: ViewEncapsulation.None
})
export class BatchDataConversionsPage implements OnInit {
	exportFormats: any[]
	hexString: any;
	fileContent: any;
	fileExchange: any = 'basic';
	file:any;
	rawJson: any;
	exportJson: any = null;
	fileName: any;
	downloadJsonHref: any;
	constructor(private spinner: NgxSpinnerService, public apiService: ApiService, public sanitizer: DomSanitizer) {}

	@ViewChild('fileInput') fileInput: ElementRef;
	fileBrowse: ElementRef;

	ngOnInit() {
		this.generateDownloadJsonUri();
	}

	clearList(){
		this.fileInput.nativeElement.value = ""
		this.fileName = ""
		this.rawJson = ""
	}

	fileChanged(e) {
		this.exportJson = null
		if(e.target.files[0].type != 'application/json'){
			alert('JSON format is invalid.  Please review the example provided and enter a valid format');
			this.fileBrowse.nativeElement.value = "";
			return;
		}
		this.spinner.show();
		this.fileName = e.target.files[0].name;
		let fileReader = new FileReader();
		fileReader.onload = (e) => {
		  this.fileContent = fileReader.result;
		  this.rawJson = this.fileContent;
		  this.spinner.hide();
		}
		fileReader.readAsText(e.target.files[0]);
		
	}

	processFile(){
		if(this.isJsonString(this.rawJson)){
			alert('JSON format is valid.')
			this.exportJson = this.rawJson
		}
		else{
			alert('JSON format is invalid.  Please review the example provided and enter a valid format')
			this.exportJson = null
			
		}
	}
	public resJsonResponse  = {
		"data": [
		  {
			"hex": "#1a1b1c"
		  },
		  {
			"hex": "#2a2b2c"
		  },
		  {
			"hex": "#3a3b3c"
		  }
		]
	}

	generateDownloadJsonUri() {
        var theJSON = JSON.stringify(this.resJsonResponse);
        var uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(theJSON));
        this.downloadJsonHref = uri;
    }

	 isJsonString(str) {
		try {
		   let json = JSON.parse(str);
		   if(('data' in json)){
				let dataArray = json['data']
				if(Array.isArray(dataArray) && dataArray!==null){
					let isHex = true
					for(let i = 0; i < dataArray.length; i++){
						let hexObj = dataArray[i]
						if(!('hex' in hexObj)){
							isHex = false
							break
						}
					}
					return isHex
				}
				else{
					return false
				}
		   }
		   else{
			   return false
		   }
		   
		} catch (e) {
			return false;
		}
		return true;
	}

	selectFileFormat(format:any){
		this.fileExchange = format;
	}

	exportBatch(){
		if(this.rawJson == undefined){
			alert('Enter data in json format');
			return;
		}
		if(this.fileExchange == ''){
			alert('Select output file format');
			return;
		}
		if(this.isJsonString(this.rawJson)){
			this.exportJson = this.rawJson
		}
		else{
			alert('JSON format is invalid.  Please review the example provided and enter a valid format')
			this.exportJson = null	
			return
		}
		this.spinner.show();
		this.apiService.exportBatch(this.exportJson, this.fileExchange).subscribe(
			(res) => {
				this.spinner.hide();
				var fileExt, filePrefix;
				if(this.fileExchange == 'basic' || this.fileExchange=='extended'){
					fileExt = '.json';
					filePrefix = 'json_export_'
				} else if(this.fileExchange == 'ASE'){
					fileExt = '.ase';
					filePrefix = 'ase_export_'
				} else if(this.fileExchange == 'CSV' || this.fileExchange == 'CSVALL'){
					fileExt = '.csv';
					filePrefix = 'csv_export_'
				} 

				var today = new Date();
				var day = today.getDate() + "";
				var month = (today.getMonth() + 1) + "";
				var year = today.getFullYear() + "";
				var hour = today.getHours() + "";
				var minutes = today.getMinutes() + "";
				var seconds = today.getSeconds() + "";
				
				day = this.checkZero(day);
				month = this.checkZero(month);
				year = this.checkZero(year);
				hour = this.checkZero(hour);
				minutes = this.checkZero(minutes);
				seconds = this.checkZero(seconds);
				var todayDateTime = month+'-'+day+'-'+year+' at '+hour+'.'+minutes+'.'+seconds;

				var outputFile = filePrefix+todayDateTime+fileExt;
				const element = document.createElement('a');
				element.href = URL.createObjectURL(res.image);
				element.download = outputFile;
				document.body.appendChild(element);
				element.click();
			},
		  (error) =>{
			  this.spinner.hide();
			  alert(error.error.message)
		  });
	}

	checkZero(data){
		if(data.length == 1){
			data = "0" + data;
		}
		return data;
	}
	setDefault(){
		this.fileExchange = 'CSV';
	}
}