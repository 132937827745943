// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURl: 'https://zclmwebapistaging.zencolor.com/',
  shopURL: 'https://staging.zencolor.com/zclm-api/',
  accountURL: 'https://staging.zencolor.com/zclm-account/',
  privacyURL: 'https://staging.zencolor.com/privacy-policy/',
  termsURL: 'https://staging.zencolor.com/general-terms-of-use/',
  legalURL: 'https://staging.zencolor.com/legal-guidelines/',
  contactURL: 'https://staging.zencolor.com/contact-us/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
