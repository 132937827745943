import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dataconversion',
  templateUrl: './dataconversion.component.html',
  styleUrls: ['./dataconversion.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DataconversionComponent implements OnInit {
  public subLinks;
  currentTab: String;
  constructor() { 

   
  }

  ngOnInit() {
    this.currentTab = 'batch-data-conversions'
    this.subLinks = [
			{
				'text':'JSON DATA CONVERSIONS',
				'tabLink': 'batch-data-conversions',
				'tooltip': 'JSON (JavaScript Object Notation) is a human readable format which organizes data that may be imported into a database. Convert a JSON file containing HEX codes into the normalized zenColorCode® (ZCC) and the corresponding HEX standard.'
			},
			{
				'text':'CSV DATA CONVERSIONS',
				'tabLink': 'file-conversions',
				'tooltip': 'CSV (Comma Separated Value) is a data format in which each piece of data is separated by a comma. This is a popular format for transferring data from one application to another, because most database systems can import and export comma-delimited data. Convert a CSV file containing HEX codes into the normalized zenColorCode® (ZCC) and the corresponding HEX standard.'
			}
		]
  }

}
