import { Injectable } from '@angular/core';

// Copied from CSInterface.js, because the functionality was not exposed
function translateKey(resourceBundle: any, resKey: string): string {
  // Get all the resources that start with the key.
  let result = resourceBundle[resKey];

  if (typeof result === 'string') {
    return result;
  }

  return 'Unknown Key: ' + resKey;
}

@Injectable()
export class LocalizationService {
  // private resourceBundle: any = JSON.parse(window.__adobe_cep__.initResourceBundle());
  // localize(key: string): string {
  //   return translateKey(this.resourceBundle, key);
  // }
}
