import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation, Injector,Input } from '@angular/core';
import { BaseComponent } from '../../base.component';
import { ApiService } from '../../api.service';
import { Hsva, Hsla, Rgba } from '../../components/color-picker/classes';
import {ColorPickerService} from '../../components/color-picker/color-picker.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
declare var jQuery: any;
@Component({
  selector: 'app-colortheory',
  templateUrl: './colortheory.component.html',
  styleUrls: ['./colortheory.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ColortheoryComponent extends BaseComponent implements OnInit {

  public name: string;
	zccRGB = {zcc:'R0402C',zccHexColor:'#EE3322', r:'238',g:'51',b:'34'};
	zccLAB = {L:'', A:'', B:''};
	zccCMYK = {c:'', m:'', y:'', k:''};
	zccHsl = {h:'', s:'', l:''};
	zccHsv = {h:'',s:'',v:''};
	public zccdeltaE: any = 0.0
	public color: any;
	public coolColor: any;	
	public dench: any;
	public _hue: number = 0.01388888889;
	public _saturation: number = 0.857;
	public _value: number = 0.933;
	
	public rawValues = {rgbFull: '', rgbNoHash: ''}; 
	public cssColor: string;
	public colorHex:string;;
	public colorR:string;
	public colorG:string;
	public colorB:string;
	public enterPressedAfterHex: boolean = false
	public isZCCTextChanged: boolean = false//boolean value to track if zcc text has been changed by the user input then on enter if its true call the commiteditzcc method
	public selectedColorVariation;
  constructor(private spinner: NgxSpinnerService,public confirmationDialogService: ConfirmationDialogService, public service: ColorPickerService, public ref: ChangeDetectorRef, public injector: Injector, public apiService: ApiService) {
	  
		super(injector)
		this.coolColor = {
			hex: 255,
			hexString: "#0000FF",
			hsl:{
				hue: 240,
				lightness: 50,
				saturation: 100
			},
			rgb:{
				blue: 255,
				green: 0,
				red: 0
			},
			cmyk:{c:'', m:'', y:'',k:''}
		};
	 }
	 
	contrastingFontColor(rgb): string {
		if(rgb){
			return ColorPickerService.isDarkColor(rgb) ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)';	
		}
	}

	handleSaturationUpdate(saturation: number) {
		this.saturation = saturation;
		this.updatePureRGB();
	}

	handleValueUpdate(value: number) {
		this.value = value;
		this.updatePureRGB();
	}

	handleHueUpdated(hue: number) {
		if (Math.abs(hue - this._hue) < 0.001) {
			return;
		}
		this.hue = hue;
		this.updatePureRGB();
	}
	  
	@Input()
	set hue(hue: number) {
		this.isZCCTextChanged = false
	if (hue >= 0 && hue <= 1) {
		this._hue = hue;
	} else {
		this._hue = 0;
	}
		this.updatePureRGB();
	}
	get hue(): number { return this._hue; }

	@Input()
	set saturation(saturation: number) {
		this.isZCCTextChanged = false
		if (saturation >= 0 && saturation <= 1) {
			this._saturation = saturation;
		  } else {
			this._saturation = 0;
		  }
		this.updatePureRGB();
	}
	get saturation(): number { return this._saturation; }

	@Input()
	set value(value: number) {
		this.isZCCTextChanged = false
		if (value >= 0 && value <= 1) {
			this._value = value;
		  } else {
			this._value = 0;
		  }
		this.updatePureRGB();
	}
	get value(): number { return this._value; }
	
	public get rawRed(): any {
		return this.coolColor.rgb.red;
	}
	
	public get rawGreen(): any {
		return this.coolColor.rgb.green;
	}

	public get rawBlue(): any {
		return this.coolColor.rgb.blue;
	}

	public get rawCyan(): any {
		return this.coolColor.cmyk.c;
	}
	
	public get rawMagenta(): any {
		return this.coolColor.cmyk.m;
	}
	public get rawYellow(): any {
		return this.coolColor.cmyk.y;
	}
	
	public get rawBlack(): any {
		return this.coolColor.cmyk.k;
	}

	public set rawCyan(c) {
		this.coolColor.cmyk.c = c;
	}
	
	public set rawMagenta(m) {
		this.coolColor.cmyk.m = m;
	}
	public set rawYellow(y) {
		this.coolColor.cmyk.y = y;
	}
	
	public set rawBlack(k) {
		this.coolColor.cmyk.k = k;
	}

	isHexNumber($event) {
		this.isZCCTextChanged = false
		var valueHex = $event.key
		if(valueHex.match(/[0-9a-fA-F]/)){
		return true
		}
		return false
	}
  public paletteOptions;
  	ngOnInit() {
    this.rgb2cmyk(this.coolColor.rgb.red, this.coolColor.rgb.green, this.coolColor.rgb.blue);
    this.paletteOptions = [
			{
				'formatID': 'complementary',
				'formatText': 'Complementary'
			},
			{
				'formatID': 'terad',
				'formatText': 'Terad'
			},
			{
				'formatID': 'triad',
				'formatText': 'Triad'
			},
			{
				'formatID': 'analogous',
				'formatText': 'Analogous'
      },
			{
				'formatID': 'splitcomplementary',
				'formatText': 'Split Complementary'
      },
      
			{
				'formatID': 'square',
				'formatText': 'Square'
      },
      
      
		]
		//this.enterColorCode();
		jQuery('#currentZCCInput')[0].setSelectionRange(4, 9)
	}


	selectedPalleteOption(value:any){
		this.selectedColorVariation = value;
	}
	onKeydown(event:any){
		// if ((event.which != 37 && (event.which != 39)) && ((event.srcElement.selectionStart < 4) || ((event.srcElement.selectionStart == 4) && (event.which == 8)))) {
		// 	return false;
		// }
		this.isZCCTextChanged = true

	}

	getZCCHEX(zcc){
		//let number = zcc.split("-").pop();
		if(zcc.length < 6){
			alert('You have entered an invalid ZCC#.');
			return;
		}
		//if(number >= 1 && number <= 8138 ){
			this.spinner.show();
			this.apiService.zccToHex(zcc).subscribe((data:any)=>{
				this.spinner.hide();
				this.zccRGB.zcc = zcc
				this.zccRGB.zccHexColor = data.hex;
				this.zccdeltaE = 0.0
				this.zccRGB.r = data.rgb.r;
				this.zccRGB.g = data.rgb.g;
				this.zccRGB.b = data.rgb.b;
				this.zccLAB.L = data.lab.l != null ? data.lab.l : '';
				this.zccLAB.A = data.lab.a != null ? data.lab.a : '';
				this.zccLAB.B = data.lab.b != null ? data.lab.b : '';
				this.zccCMYK = data.cmyk;
				this.zccHsl.h = Math.round(data.hsl.h).toString()
				this.zccHsl.s = Math.round(data.hsl.s*100).toString()
				this.zccHsl.l = Math.round(data.hsl.l*100).toString()
	
			//	this.zccHsl = data.hsl;
	
				let hsb = this.service.rgbToHsv(data.rgb.r, data.rgb.g, data.rgb.b);
				this.zccHsv.h = Math.round(hsb.h).toString()
				this.zccHsv.s = Math.round(hsb.s*100).toString()
				this.zccHsv.v = Math.round(hsb.v*100).toString()

				this.coolColor.hexString = data.hex;
				this.coolColor.hex = this.coolColor.hexString.substr(1);
				this.coolColor.rgb.red = data.rgb.r;
				this.coolColor.rgb.green = data.rgb.g;
				this.coolColor.rgb.blue = data.rgb.b;
				this.coolColor.cmyk = data.cmyk;
				let rgb = {r: data.rgb.r/255.0, g: data.rgb.g/255.0, b: data.rgb.b/255.0, a: 1.0};
		// let rgba = new Rgba(this.coolColor.rgb.red, g, b, 1.0);
		let hsva = this.service.rgbaToHsva(rgb);
		// let hsva = this.rgb2hsv(r, g, b)
		this._hue = hsva.h
		this._saturation = hsva.s
		this._value = hsva.v
				
			},(error) =>{
			  this.spinner.hide();
			  if(error.status == 404){
				alert('You have entered an invalid ZCC#.');
			  }
			  else{
			  	alert(error.error.message)
			  }
			}) 
		// } else {
		// 	alert('Invalid ZCC code. Please enter in the format ZCC-XXXX where XXXX is a number between 0001 and 8138.');
		// 	return;
		// }
	}
	commitEditZCC(event){
		let zcc = event.target.value;
		this.getZCCHEX(zcc)
		
	}
  	updatePureRGB() {
		let hue = this._hue;
		let sat = this._saturation;
		let val = this._value;
		if (hue >= 0 && hue <= 1.0 && sat >= 0 && sat <= 1.0 && val >= 0 && val <= 1.0) {
			let hsva = new Hsva(this._hue, this._saturation, this._value, 1.0);
			let hsvaToRgba = this.gm.colorUtility.service.hsvaToRgba.bind(this.gm.colorUtility.service);
			let rgba = hsvaToRgba(hsva);
			let normalRgba = this.gm.colorUtility.service.denormalizeRGBA(rgba);
			let fullbits = (r) => Math.floor(256.5 + 255 * r);
			let zccBits = [rgba.r, rgba.g, rgba.b].map(fullbits);
			let toHex = (r) => `${r.toString(16).substr(1,2)}`;
			let value = zccBits.map(toHex).join('');
			// let swatch = new SwatchModel();
			// this.gm.colorUtility.generateColors(value, swatch);
			this.rawValues.rgbFull = [normalRgba.r, normalRgba.g, normalRgba.b].join(',');
			this.rawValues.rgbNoHash = value.toUpperCase();
			this.coolColor.hexString = '#' + value.toUpperCase();
			this.coolColor.hex = value.toUpperCase();
			let arrRGB:string[]=this.rawValues.rgbFull.split(',');
			this.coolColor.rgb.red = arrRGB[0];
			this.coolColor.rgb.green = arrRGB[1];
			this.coolColor.rgb.blue = arrRGB[2];
			this.coolColor.cmyk = this.rgb2cmyk(this.coolColor.rgb.red, this.coolColor.rgb.green, this.coolColor.rgb.blue)
		}
	}

	public colorChange(color: any): void {
		this.coolColor = color;
		this.coolColor.cmyk = {c:'', m:'', y:'',k:''};
		this.rgb2cmyk(this.coolColor.rgb.red, this.coolColor.rgb.green, this.coolColor.rgb.blue);
	}

	rgb2cmyk (r,g,b):any {
		var computedC = 0;
		var computedM = 0;
		var computedY = 0;
		var computedK = 0;

		//remove spaces from input RGB values, convert to int
		r = parseInt( (''+r).replace(/\s/g,''),10 );
		g = parseInt( (''+g).replace(/\s/g,''),10 );
		b = parseInt( (''+b).replace(/\s/g,''),10 );

		if ( r==null || g==null || b==null ||
				isNaN(r) || isNaN(g)|| isNaN(b) )
		{
				// alert ('Please enter numeric RGB values!');
				return;
		}
		if (r<0 || g<0 || b<0 || r>255 || g>255 || b>255) {
				// alert ('RGB values must be in the range 0 to 255.');
				return;
		}

		// BLACK
		if (r==0 && g==0 && b==0) {
				return {
					c: 0,
					m: 0,
					y: 0,
					k: 100
				}
		}

		computedC = 1 - (r/255);
		computedM = 1 - (g/255);
		computedY = 1 - (b/255);

		var minCMY = Math.min( computedC, Math.min( computedM,computedY ) );

		computedC = (computedC - minCMY) / (1 - minCMY) ;
		computedM = (computedM - minCMY) / (1 - minCMY) ;
		computedY = (computedY - minCMY) / (1 - minCMY) ;

		computedK = minCMY;
		this.coolColor.cmyk.c = Math.round(100 * ( Math.round(computedC * 1000) / 1000 ));
		this.coolColor.cmyk.m = Math.round(100 * ( Math.round(computedM * 1000) / 1000 ));
		this.coolColor.cmyk.y = Math.round(100 * ( Math.round(computedY * 1000) / 1000 ));
		this.coolColor.cmyk.k = Math.round(100 * ( Math.round(computedK * 1000) / 1000 ));

		return {
			c: Math.round(100 * ( Math.round(computedC * 1000) / 1000 )),
			m: Math.round(100 * ( Math.round(computedM * 1000) / 1000 )),
			y: Math.round(100 * ( Math.round(computedY * 1000) / 1000 )),
			k: Math.round(100 * ( Math.round(computedK * 1000) / 1000 ))
		};
	}
	enterColorCode(){
		if (this.isZCCTextChanged == true) {
			this.getZCCHEX(this.zccRGB.zcc)
			return
		}
		let hex = this.coolColor.hexString.substr(1);
		this.spinner.show();
		this.apiService.hexToZccWithCF(hex, 1, 1, 1).subscribe((data:any)=>{
			this.spinner.hide();
			this.zccRGB.zcc = data.zcc
			this.zccRGB.zccHexColor = "#"+data.hex;
			this.zccdeltaE = data.deltaE.toFixed(1)
			this.zccRGB.r = data.rgb.r;
			this.zccRGB.g = data.rgb.g;
			this.zccRGB.b = data.rgb.b;
			this.zccLAB.L = data.lab.l != null ? data.lab.l : '';
			this.zccLAB.A = data.lab.a != null ? data.lab.a : '';
			this.zccLAB.B = data.lab.b != null ? data.lab.b : '';
			this.zccCMYK = data.cmyk;

			this.zccHsl.h = Math.round(data.hsl.h).toString()
			this.zccHsl.s = Math.round(data.hsl.s*100).toString()
			this.zccHsl.l = Math.round(data.hsl.l*100).toString()

		//	this.zccHsl = data.hsl;

			let hsb = this.service.rgbToHsv(data.rgb.r, data.rgb.g, data.rgb.b);
			this.zccHsv.h = Math.round(hsb.h).toString()
			this.zccHsv.s = Math.round(hsb.s*100).toString()
			this.zccHsv.v = Math.round(hsb.v*100).toString()

		},(error) =>{
		  this.spinner.hide();
		  alert(error.error.message)
		}) 
	}
	getColorFromRGB(r,g,b){
		if (r.length == 0) {
			r = '0'
			this.coolColor.rgb.red = '0'
		}
		if (g.length == 0) {
			g = '0'
			this.coolColor.rgb.green = '0'
		}
		if (b.length == 0) {
			b = '0'
			this.coolColor.rgb.blue = '0'
		}
		let hex = this.rgbToHex(r, g, b);
		let cmyk = this.rgb2cmyk(r, g, b);
		this.coolColor.cmyk = cmyk; 
		this.coolColor.hexString = '#'+hex;
		this.coolColor.hex = hex;
		let rgb = {r: r/255.0, g: g/255.0, b: b/255.0, a: 1.0};
		// let rgba = new Rgba(this.coolColor.rgb.red, g, b, 1.0);
		let hsva = this.service.rgbaToHsva(rgb);
		// let hsva = this.rgb2hsv(r, g, b)
		this._hue = hsva.h
		this._saturation = hsva.s
		this._value = hsva.v
	}

	getColorFromCMYK(c,m,y,k){
		if (c.length == 0) {
			c = '0'
			this.coolColor.cmyk.c = c
		}
		if (m.length == 0) {
			m = '0'
			this.coolColor.cmyk.m = m
		}
		if (y.length == 0) {
			y = '0'
			this.coolColor.cmyk.y = y
		}
		if (k.length == 0) {
			k = '0'
			this.coolColor.cmyk.k = k
		}
		let rgb = this.cmyk2rgb(c,m,y,k)
		this.coolColor.rgb.red = rgb.r;
		this.coolColor.rgb.blue = rgb.b;
		this.coolColor.rgb.green = rgb.g;
		let hex = this.cmyk2hex(this.coolColor.cmyk.c, this.coolColor.cmyk.m, this.coolColor.cmyk.y, this.coolColor.cmyk.k)
		this.coolColor.hexString = '#'+hex;
		this.coolColor.hex = hex;
		let rgba = {r: rgb.r/255.0, g: rgb.g/255.0, b: rgb.b/255.0, a: 1.0};
		let hsva = this.service.rgbaToHsva(rgba);
		this._hue = hsva.h
		this._saturation = hsva.s
		this._value = hsva.v
	}
	cmyk2rgb(c, m, y, k):any {
		c = c / 100;
		m = m / 100;
		y = y / 100;
		k = k / 100;

		var r = 255 * ( 1-c ) * ( 1-k );
		var g = 255 * ( 1-m ) * ( 1-k );
		var b = 255 * ( 1-y ) * ( 1-k );

		r = Math.round(r * 1) / 1;
		g = Math.round(g * 1) / 1;
		b = Math.round(b * 1) / 1;

		return {
		r: r,
		g: g,
		b: b
		}
	}
	
	cmyk2hex(c, m, y, k):string {
		var o = this.cmyk2rgb(c,m,y,k);

		return this.rgbToHex(o.r, o.g, o.b);
	}

	getColorFromHexOnEnterPressed(hex){
		this.getColorFromHex(hex)
	}
	getColorFromHex(hex){
		//bool value to remove this method called twice
		if (this.isValidHex(hex) == false) {
			this.coolColor.hex = this.coolColor.hexString.substring(1, 7)
			return
		}
		let rgb = this.hexTorgb(hex);
		this.coolColor.rgb.red = rgb.r;
		this.coolColor.rgb.blue = rgb.b;
		this.coolColor.rgb.green = rgb.g;
		this.coolColor.hexString = '#'+hex;
		this.coolColor.hex = hex;
		let cmyk = this.rgb2cmyk(rgb.r, rgb.g, rgb.b);
		this.coolColor.cmyk = cmyk; 
		let rgba = {r: rgb.r/255.0, g: rgb.g/255.0, b: rgb.b/255.0, a: 1.0};
		let hsva = this.service.rgbaToHsva(rgba);
		this._hue = hsva.h
		this._saturation = hsva.s
		this._value = hsva.v
	}

	isValidHex(hex){
		hex = (hex.charAt(0) === "#") ? hex.substring(1, 7) : hex
        
        if (hex.length !== 6) {
              alert ('Invalid length of the input hex value!')
              return false
          }
        if (/[0-9a-f]{6}/i.test(hex) !== true) {
             // alert ('Invalid digits in the input hex value!')
              return false
					}
					return true
	}

	hexTorgb(hex: string){
        hex = (hex.charAt(0) === "#") ? hex.substring(1, 7) : hex
        
        if (hex.length !== 6) {

              return
          }
        if (/[0-9a-f]{6}/i.test(hex) !== true) {
             // alert ('Invalid digits in the input hex value!')
              return
          }
        
        let r = parseInt(hex.substring(0, 2), 16)
        let g = parseInt(hex.substring(2, 4), 16)
        let b = parseInt(hex.substring(4, 6), 16)
        
        return {r: r, g: g, b: b}
      }

	rgbToHsl(r, g, b):any{
        r /= 255, g /= 255, b /= 255;
        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h, s, l = (max + min) / 2;

        if(max == min){
            h = s = 0; // achromatic
        }else{
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch(max){
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }

        return [h, s, l];
    }
	isValidNumber($event) {
		this.isZCCTextChanged = false
		var charCode = ($event.which) ? $event.which : $event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		let val = $event.srcElement.value;
		let valR = Number(val + $event.key)
		if (valR > 255) {
		return false
		}
		return true;
	}
	isCMYK(event){
		this.isZCCTextChanged = false
			var charCode = (event.which) ? event.which : event.keyCode;
			if (charCode > 31 && (charCode < 48 || charCode > 57)) {
				return false;
			}
			let val = event.srcElement.value
			let valR = Number(val + event.key)
			if (valR > 100) {
			  return false
			}
			return true;
	}
	rgbToHex(R,G,B): string {
        return this.toHex(R)+this.toHex(G)+this.toHex(B);
	}
	toHex(n):any {
        n = parseInt(n,10);
        if (isNaN(n)) return "00";
        n = Math.max(0,Math.min(n,255));
        return "0123456789ABCDEF".charAt((n-n%16)/16)
            + "0123456789ABCDEF".charAt(n%16);
	}
	

	addPaletts (dataObj){
		var self = this;
		let paletteColors: Array<any> = []
		for(var j = 0 ; j < dataObj.length ; j++){
			let zccObj = dataObj[j]
			var hex = "#" + zccObj.hex
			let paletteColor = { hex: zccObj.hex, hashHex: hex, zcc: zccObj.zcc}
			paletteColors.push(paletteColor)
		}
		//if dynamivSwatchLength is 1 only add color to the selected swatch
		self.removeAllPallets();
		let hashHex;
		for(let i=0; i<paletteColors.length; i++){
			if(paletteColors[i] && paletteColors[i].hashHex){
			hashHex = paletteColors[i].hashHex;
			} else {
			hashHex = '#CCCCCC';
			}
		for(let j=0; j<this.gm.palettesModel.selectedPalette.swatches.length;j++){
			let swatch = this.gm.palettesModel.selectedPalette.swatches[j];
			if(swatch.empty === true){
			//self.gm.colorUtility.generateColors(hashHex, swatch);
			self.gm.colorUtility.generateZCCColors(hashHex,paletteColors[i].zcc, swatch);
			swatch.empty = false;
			break;
			}
		}
		}
		this.scrollWorkspaceToTop()
		this.gm.palettesModel.writeDefaultFile();
  }
  removeAllPallets():void {
	this.gm.emptyPalettesModelSavedDetails();

	this.gm.palettesModel.removePalette( this.gm.palettesModel.selectedPalette);
  }
  
  addToWorkspace(){

	//create the pallete
	let self = this
	if (this.selectedColorVariation != undefined) {
			
		   switch(this.selectedColorVariation){

				case 'complementary':
					this.spinner.show();
		this.apiService.generateHueVariation("0,180", this.coolColor.hexString).subscribe((data:any)=>{
			this.spinner.hide();
			self.gm.deletedAllStack = []
			let dataObj = data["data"]
			self.addPaletts(dataObj);
		},(error) =>{
			this.spinner.hide();
			alert(error.error.message)
		}) 

				break;
				case 'splitcomplementary':
					this.spinner.show();
					this.apiService.generateHueVariation("150,0,-150", this.coolColor.hexString).subscribe((data:any)=>{
						this.spinner.hide();
						self.gm.deletedAllStack = []
						let dataObj = data["data"]
						self.addPaletts(dataObj);
					},(error) =>{
						this.spinner.hide();
						alert(error.error.message)
					}) 
		
				break;
				case 'analogous':

					this.spinner.show();
					this.apiService.generateHueVariation("30,15,0,-15,30", this.coolColor.hexString).subscribe((data:any)=>{
						this.spinner.hide();
						self.gm.deletedAllStack = []
						let dataObj = data["data"]
						self.addPaletts(dataObj);
					},(error) =>{
						this.spinner.hide();
						alert(error.error.message)
					}) 
		
				break;
				case 'triad':
					this.spinner.show();
					this.apiService.generateHueVariation("120,0,-120", this.coolColor.hexString).subscribe((data:any)=>{
						this.spinner.hide();
						self.gm.deletedAllStack = []
						let dataObj = data["data"]
						self.addPaletts(dataObj);
					},(error) =>{
						this.spinner.hide();
						alert(error.error.message)
					}) 
				
				break;
				case 'terad':
					this.spinner.show();
					
					this.apiService.generateHueVariation("180, 60, 0, -120", this.coolColor.hexString).subscribe((data:any)=>{
						this.spinner.hide();
						self.gm.deletedAllStack = []
						let dataObj = data["data"]
						self.addPaletts(dataObj);
					},(error) =>{
						this.spinner.hide();
						alert(error.error.message)
					}) 
		
				break;

				case 'square':
					this.spinner.show();
					this.apiService.generateHueVariation("180, 90, 0, -90", this.coolColor.hexString).subscribe((data:any)=>{
						this.spinner.hide();
						self.gm.deletedAllStack = []
						let dataObj = data["data"]
						self.addPaletts(dataObj);
					},(error) =>{
						this.spinner.hide();
						alert(error.error.message)
					}) 
		
				break;
		   }
	
	}
	else{

		let alertMessage =  "Please select a COLOR THEORY"
		this.confirmationDialogService.alert('ALERT!', alertMessage)
			.then((confirmed) =>{
				if(confirmed){
				}
				
			})	
			.catch( 
			
			);
	}
	
}

scrollWorkspaceToTop(){
	jQuery('.colors').scrollTop(0)
}

}
