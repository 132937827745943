/**
 * Component class to select
 * a single swatch in workarea
 */
import {ElementRef, Component, OnInit, Injector, Input} from '@angular/core';
import {BaseComponent} from "../base.component";
import {SwatchModel} from "../models/swatch.model";
import {SwatchChangedEvent} from "../events/swatch.changed.event";
import {PaletteModel} from "../models/palette.model";
import {ColorUtility} from "../utils/color.utility";
import {ColorPickerService} from "./color-picker/color-picker.service";

@Component({
    selector: 'selectable-swatch',
    styles: ['./selectable-swatch.scss'],
    template: './selectable-swatch.component.html'
})
export class SelectableSwatchComponent extends BaseComponent {
    @Input() swatch: SwatchModel;
    @Input() palette: PaletteModel;

    colorUtility:ColorUtility;
    colorPickerService:ColorPickerService;
    constructor(public injector: Injector, el: ElementRef) {
        super(injector);
    }

    ngOnInit(){
            
    }
    selectSwatch():void {
        this.gm.palettesModel.selectedPalette.deselectAllSwatches();

        var o = new SwatchChangedEvent({
            "oldSwatchId": this.gm.palettesModel.selectedSwatch.id,
            "oldPaletteId": this.gm.palettesModel.selectedPalette.id,
            "newSwatch": this.swatch,
            "newPalette": this.palette
        });
        this.swatch.selected = true;
        this.gm.palettesModel.selectedSwatch = this.swatch;
       // this.gm.colorUtility.generateColors(this.gm.palettesModel.selectedSwatch.rgb, this.gm.palettesModel.selectedSwatch);
        this.gm.palettesModel.selectedPalette = this.palette;
        this.gm.palettesModel.data.activePaletteId = this.palette.id;

        this.gm.palettesModel.swatchChanged.emit(o);
    }

}
