import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {GlobalModel} from "./global.model";

declare var SystemPath: any;
declare var _: any;
declare var cep: any;

/**
 * NOTE: we are using _.deepExtend, so if we add new properties to our models, for later versions, when the older version file loads,
 * properties will be merged with the new ones, and it should make backward compatibility a non issue. Just add new properties to the models
 */
@Injectable()
export abstract class FileModel {

    public filePath;
    public fileName;
    public data = {};
    public initialDataStr: string = '';

    initFileCheck():void {

      try{
            let data = localStorage.getItem(this.fileName);
            data = JSON.parse(data);
            let typeofData = typeof data;
            if(data && typeofData == "object"){
                this.data = data;
            }
        } catch(error){
          console.log(this.fileName + ":Caught error: " + error);
        }
    }

    writeFile(json) :void {
        try{
            localStorage.setItem(this.fileName, json);
        } catch(error) {
            console.log(this.fileName + ":Caught error writing new file: " + error);
        }
    }

    writeDefaultFile():void {
        var json = JSON.stringify(this.data)
        this.writeFile(json);
    }

}
