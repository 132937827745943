import { Injectable } from '@angular/core';
import { User } from './user';
import { ApiService } from './api.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private spinner: NgxSpinnerService, private router: Router, public apiService: ApiService) { }

  public login(userInfo: User) {
    this.spinner.show();
    this.apiService.login(userInfo.email, userInfo.password).subscribe((data: any) => {
      this.spinner.hide();
      let ssData = { "Value": "0", "CustomerId": "4", "CompanyName": "zenColor", "ContactName": "Rachelle", "BusinessEmail": "rp@zencolor.com", "LicenseKey": "YJME-1Sv8-NkWk-DEjb-tbjb-yaJD-aM6V", "Token": "", "TransId": "61803135682", "PackageType": "BASE PACKAGE", "SortOrder": "1, 2, 3, 4", "PackageId": "1, 2, 3, 4", "PackageName": "COLOR PICKER, SWATCH EXCHANGE, CAPTURE, COLOR MANAGER" }
      ssData.Token = data;
      sessionStorage.setItem('USER_DETAILS', JSON.stringify(ssData));
      let sessionData: any;
      sessionData = sessionStorage.getItem('USER_DETAILS');
      if (sessionData) {
        sessionData = JSON.parse(sessionData);
        this.apiService.token = sessionData.Token;
        this.apiService.packageId = sessionData.PackageId;
      }
      // this.router.navigateByUrl('/color-picker');
      this.router.navigateByUrl('/library');
    },
      (error) => {
        this.spinner.hide();
        alert(error.error.message)
      }
    )
    // localStorage.setItem('ACCESS_TOKEN', "access_token");
  }

  public isLoggedIn() {
    return sessionStorage.getItem('USER_DETAILS') !== null;

  }

  public logout() {
    sessionStorage.removeItem('USER_DETAILS');
  }
}