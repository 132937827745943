import { ChangeDetectorRef,ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, NgZone, Injector, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../api.service';
import { BaseComponent } from '../../base.component'
import {SwatchChangedEvent} from "../../events/swatch.changed.event";
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { ColorPickerService } from '../../components/color-picker/color-picker.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormModalComponent } from '../../form-modal/form-modal.component';
import '../../components/ColorThief';
import { DomSanitizer } from '@angular/platform-browser';
import * as deltae from 'delta-e';
import * as _ from 'underscore';
import { NgxSpinnerService } from 'ngx-spinner';

declare var jQuery: any;
declare var ColorThief: any;

interface PreviewArea {
	src: any;
	width: number;
	height: number;
}
interface SwatchColor {
	hex: string;
	hashHex: string;
	zcc: string;
}
interface ColorCounter {
	value: string;
	count: number;
}
interface ColorData {
val: string;
hex: string;
red: number;
green: number;
blue: number;
hue: number;
saturation: number;
lightness: number;
pos: any;
zccIndex: number;
zcc: string;
}


@Component({
  selector: 'home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})

export class HomePage extends BaseComponent implements OnInit {
	hasImage: boolean
	exportFormats: any[];
	filesToUpload: Array<File> = [];
	compareFilesToUpload: Array<File> = [];
	compareFormData: any;
	public previewArea: PreviewArea = {src: '', width: 100, height: 100};
	public shrinkMultiplier:number = 1	
	public paletteColors: SwatchColor[] = []
	public zoomImageDefaultWidth:any;
	public zoomImageDefaultHeight:any;
	public imageData: any
	public relevanceImageData: any
	public isImageLoaded: boolean = false
	public initLoad: boolean = false
	public zoomImg: string = '0';
	public zoomValue: string = '1x'
	public dynamicSwatchLength: number = 1;
	public sortMode: string  = 'commonDesc'
	public processedPixels: ColorData[] = []
	public selectionTool: string = "window-box";
	public currDoc: string = ''
	public fileName: string = ''
	public samplingValue: number =1;
	public selectedSampling: any = 'dominant';
	public defaultChecked: boolean = true;
	public swatchFileName: any;
	public compareFile:any = 'COMPARE_FILE'

	public referenceFileName:any = 'REFERENCE_FILE_Name'
	public compareFileName:any = 'COMPARE_FILE_Name'  
	public dynamicDeltaE : any = 2.0
	public cf : any = 1
	public l : any = 2
	public c : any = 1
	public basicTolerance: any = 2
	public deltaET = 'ΔE'
	public deltaETEXT = ''
	private _swatchChangedSubscription: any;
	public deltaeOption = 'basic';
	public tolerancePack : any = {cf:1, l:2, c:1}
	public isMenuShowing = false
	public zooms = [
		{value: '0', display: '1x', img:'1x'},
		{value: '100', display: '2x', img:'2x'},
		{value: '200', display: '4x', img:'4x'},
		{value: '300', display: '8x', img:'8x'},
		{value: '400', display: '16x', img:'16x'}
	]
	public sortFuncs = {
		"commonDesc": (a:any,b:any) => { return b.pos.length - a.pos.length } ,
		"saturationDesc": (a:any,b:any) => { return b.saturation - a.saturation },
		"lightnessDesc": (a:any,b:any) => { return b.lightness - a.lightness }
	}
	public zoomModel: any = 6;

	@ViewChild('swatchFile') swatchFIleTag: ElementRef;
	@ViewChild('fileInput') fileInput: ElementRef;
	constructor(private spinner: NgxSpinnerService, public ref: ChangeDetectorRef, private _sanitizer: DomSanitizer, private modalService: NgbModal, public confirmationDialogService: ConfirmationDialogService, public injector: Injector, public _zone: NgZone, public apiService: ApiService) {
		super(injector)
	}
	ngOnInit() {
		let palettesModel = this.gm.palettesFileModel;
    	this._swatchChangedSubscription = palettesModel.swatchChanged
			.subscribe(id => this.swatchChanged(id));
			
    	this.hasImage = false
		this.exportFormats = [
			{
				'formatID': 'marquee',
				'formatText': 'Marquee',
				'selected':true
			},
			{
				'formatID': 'sampler',
				'formatText': 'Eyedropper',
				'selected':false
			}
		]

		let imageAsDataURL = localStorage.getItem(this.compareFile)
		if (imageAsDataURL != null || imageAsDataURL != undefined) {
			//load image
			this.fileName = localStorage.getItem(this.compareFileName)
			let self = this
			self.previewArea.src = imageAsDataURL
					self.paletteColors.length = 0   // empty the array
				
					
					var img = new Image;
				
					img.onload = function() {
						var response:any; 
						//response = self.prepareImage(364,274, img);
						var maxWidth = jQuery('#imageContainer').width();
				var maxHeight = jQuery('#imageContainer').height();
				response = self.prepareImage(maxWidth, maxHeight, img);
						response = JSON.parse(response)
						self.currDoc = response;						
						self.zoomImageDefaultWidth = response.fullWidth;
						self.zoomImageDefaultHeight = response.fullHeight;
						setTimeout(function(){
							self.cb( null, response.shrink, 0, 0)
							}, 100)
					};
				
					img.src = imageAsDataURL;
		}
		let refFileName = localStorage.getItem(this.referenceFileName)
		if (refFileName != null || refFileName != undefined) {
			this.swatchFileName = refFileName
			this.gm.paletteFileName = this.swatchFileName
			this.setDeltaEText()
		}

		this.toolSelect('marquee')
	}
	ngOnDestroy() {
		if (!this._swatchChangedSubscription.closed) {
		this._swatchChangedSubscription.unsubscribe();
		}
	  }
	selecteDetlaE(value){
		this.deltaeOption = value;
		if (value == 'advanced') {
			this.dynamicDeltaE = this.cf
			this.tolerancePack.cf = this.cf
			this.tolerancePack.l = this.l
			this.tolerancePack.c = this.c
		}
		else{
			this.setBasicTolerancePack()
		}
	}

	swatchChanged(o: SwatchChangedEvent): void {
    this.setDeltaEText()
	}

	clearStandard(){
		let self = this
			if (self.isComparisonAlreadyDone()) {
				self.confirmationDialogService.confirm('ALERT!', 'YOUR COLOR COMPARISON IS NOT COMPLETE.<br/>IF YOU SELECT <b>PROCEED</b>, YOUR RESULTS WILL BE CLEARED AND A NEW COMPARISON IMAGE OR ZCC&reg; REFERENCE STANDARD CAN BE LOADED.<br/>SELECT <b>CANCEL</b> TO STAY WITH THE CURRENT COMPARISON IMAGE OR ZCC&reg; REFERENCE STANDARD.', 'PROCEED', 'CANCEL')
						.then((confirmed) =>{
							if(confirmed){
								self.swatchFileName = ''
								self.gm.palettesFileModel.removePalette(this.gm.palettesFileModel.selectedPalette)
								localStorage.removeItem(this.referenceFileName)
								localStorage.removeItem('zcdpfile.palettes.json')
								self.finallyClearImage()
							}
						})	
						.catch(() =>  {
							
						});
			}
			else{
				self.swatchFileName = ''
				self.gm.palettesFileModel.removePalette(this.gm.palettesFileModel.selectedPalette)
				localStorage.removeItem(this.referenceFileName)
				localStorage.removeItem('zcdpfile.palettes.json')
				//self.finallyClearImage()
			}
	}
	
	clearImage(){
		
		let self = this
			if (self.isComparisonAlreadyDone()) {
				self.confirmationDialogService.confirm('ALERT!', 'YOUR COLOR COMPARISON IS NOT COMPLETE.<br/>IF YOU SELECT <b>PROCEED</b>, YOUR RESULTS WILL BE CLEARED AND A NEW COMPARISON IMAGE OR ZCC&reg; REFERENCE STANDARD CAN BE LOADED.<br/>SELECT <b>CANCEL</b> TO STAY WITH THE CURRENT COMPARISON IMAGE OR ZCC&reg; REFERENCE STANDARD.', 'PROCEED', 'CANCEL')
						.then((confirmed) =>{
							if(confirmed){
								self.swatchFileName = ''
								self.gm.palettesFileModel.removePalette(this.gm.palettesFileModel.selectedPalette)
								localStorage.removeItem(this.referenceFileName)
								localStorage.removeItem('zcdpfile.palettes.json')
								self.finallyClearImage()
							}
						})	
						.catch(() =>  {
							
						});
			}
			else{
				self.finallyClearImage()
			}
	}

	finallyClearImage(){
		this.unselectSampler()
		let self = this
		self.previewArea = {src: '', width: 100, height: 100};
		localStorage.removeItem(this.compareFile)
		localStorage.removeItem(this.compareFileName)
		self.imageData = null
		jQuery('#img').removeAttr("style");
		jQuery('#marqueeImg').removeAttr("style");
		jQuery('#marqueeSelection').removeAttr("style");
		self.currDoc = '';
		self.fileName = '';
		self.fileInput.nativeElement.value = ""
	}
	openFormModal() {
		let self = this
		if((this.gm.paletteFileName == null || this.gm.paletteFileName == undefined || this.gm.paletteFileName.length == 0) && (this.imageData == null || this.imageData == undefined)){
			//show no file alert
			
			self.confirmationDialogService.confirm('ALERT!', 'Load a reference swatch file and compare colors before creating your report.', ' ', 'OK')
			.then((confirmed) =>{
				if(confirmed){
				}
			})	
			.catch(() =>  {
				
			});
			return
		}

		if(this.gm.paletteFileName == null || this.gm.paletteFileName == undefined || this.gm.paletteFileName.length == 0){
			//show no file alert
			
			self.confirmationDialogService.confirm('ALERT!', 'Load a reference swatch file and compare colors before creating your report.', ' ', 'OK')
			.then((confirmed) =>{
				if(confirmed){
				}
			})	
			.catch(() =>  {
				
			});
			return
		}

		if(this.imageData == null || this.imageData == undefined){
			//show no file alert
			
			self.confirmationDialogService.confirm('ALERT!', 'Please load an image file and compare color on at least one swatch to create a report.', ' ', 'OK')
			.then((confirmed) =>{
				if(confirmed){
				}
			})	
			.catch(() =>  {
				
			});
			return
		}


		if (this.isComparisonAlreadyDone() == false) {
			//show no comparison alert
			self.confirmationDialogService.confirm('ALERT!', 'Please compare color on at least one swatch to create a report.', ' ', 'OK')
			.then((confirmed) =>{
				if(confirmed){
				}
			})	
			.catch(() =>  {
				
			});
			return

		}

		if(this.isComparisonRemainingForSwatches()){
			//show remaining comparison alert
			self.confirmationDialogService.confirm('ALERT!', 'Color comparison for all reference swatches is not complete.', 'Continue with report', 'Continue swatch comparison')
			.then((confirmed) =>{
				if(confirmed){
					self.showFormModalForFileDetails()
				}
			})	
			.catch(() =>  {
				
			});
			return
		}
		self.showFormModalForFileDetails()
		
	}
	contrastingFontColor(): string {
    return ColorPickerService.isDarkColor(this.gm.palettesFileModel.selectedSwatch.rgb) ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)';
	}
	
	contrastingCoupleFontColor(): string {
    return ColorPickerService.isDarkColor(this.gm.palettesFileModel.selectedSwatch.swatchCouplergb) ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)';
	}

	showFormModalForFileDetails(){
		const modalRef = this.modalService.open(FormModalComponent);
		
		modalRef.result.then((result) => {
			let swatchFileName = result.swatchFileName
			let techpack = result.techpack
			let notes = result.notes;
			this.spinner.show();
			this.apiService.uploadCompareFileAsBase64(this.previewArea.src).subscribe((data:any)=>{
				this.spinner.hide();
				let filePath = data.filename
				let swatches = this.gm.palettesFileModel.selectedPalette.swatches
				let userDetails = JSON.parse(sessionStorage.getItem('USER_DETAILS'));
				let userName = userDetails.ContactName
				let company = userDetails.CompanyName
				var event = new Date();
				var options = {year: 'numeric', month: 'short', day: 'numeric' };
				let date = event.toLocaleDateString("en-US", options)
				let time = new Date();
				var outputdate = 'Date: ' +date+'    '+time.getHours()+':'+time.getMinutes()+':'+time.getSeconds()+' '+new Date().toTimeString().match(new RegExp("[A-Z](?!.*[\(])","g")).join('')
				this.spinner.show();
				this.apiService.createManagerReport(filePath, swatchFileName, techpack, notes, userName, company, outputdate, swatches).subscribe(
					(res) => {		
						this.spinner.hide();
						var outputFile = swatchFileName;
						const element = document.createElement('a');
						element.href = URL.createObjectURL(res.image);
						element.download = outputFile;
						document.body.appendChild(element);
						element.click();
					},
				(error) =>{
					this.spinner.hide();
					alert(error.error.message);
				});

			}, (error) => {
				this.spinner.hide();
				alert(error.error.message);
			})
		}).catch((error) => {
			console.log(error);
		});
	}

	isComparisonAlreadyDone(){
		let isCompared = false
		let swatches = this.gm.palettesFileModel.selectedPalette.swatches
		for (let index = 0; index < swatches.length; index++) {
			let swatch = swatches[index];
			if (swatch.comparisonResult != 'none') {
					isCompared = true
					break
			}
			
		}
		return isCompared
	}

	isComparisonRemainingForSwatches(){
		let isComparedRemaining = false
		let swatches = this.gm.palettesFileModel.selectedPalette.swatches
		for (let index = 0; index < swatches.length; index++) {
			let swatch = swatches[index];
			if (swatch.comparisonResult == 'none' && swatch.empty == false) {
				isComparedRemaining = true
					break
			}
			
		}
		return isComparedRemaining
	}

	openSwatchFile(filetype){
		this.openFile(filetype, 'swatchFile')
	}

	openComparisonFile(filetype){
		this.openFile(filetype, 'comparisonFile')
	}

	openFile(filetype, whichFile){
		let self = this
			if (self.isComparisonAlreadyDone()) {
				self.confirmationDialogService.confirm('ALERT!', 'YOUR COLOR COMPARISON IS NOT COMPLETE.<br/>IF YOU SELECT <b>PROCEED</b>, YOUR RESULTS WILL BE CLEARED AND A NEW COMPARISON IMAGE OR ZCC&reg; REFERENCE STANDARD CAN BE LOADED.<br/>SELECT <b>CANCEL</b> TO STAY WITH THE CURRENT COMPARISON IMAGE OR ZCC&reg; REFERENCE STANDARD.', 'PROCEED', 'CANCEL')
						.then((confirmed) =>{
							if(confirmed){
								this.swatchFileName = ''
								this.gm.palettesFileModel.removePalette(this.gm.palettesFileModel.selectedPalette)
								localStorage.removeItem(this.referenceFileName)
								localStorage.removeItem('zcdpfile.palettes.json')
								if (whichFile == 'comparisonFile') {
									//remove from cache also
									localStorage.removeItem(this.compareFile)
									localStorage.removeItem(this.compareFileName)	
								}
								jQuery('#'+filetype).click();
							}
						})	
						.catch(() =>  {
							
						});
			}
			else{
				jQuery('#'+filetype).click();
			}
		
	}
	
	fileChangeEvent(fileInput){
		if (fileInput.target.files.length == 0) {
			return
		}
		this.filesToUpload = <Array<File>>fileInput.target.files;
		const formData: any = new FormData();
		const files: Array<File> = this.filesToUpload;

    	for(let i =0; i < files.length; i++){
        	formData.append("uploads[]", files[i], files[i]['name']);
		}
		this.spinner.show();
		this.apiService.uploadFile(formData).subscribe((data:any)=>{
			this.spinner.hide();
			data = JSON.parse(data);
			this.gm.paletteFileName = undefined
			this.gm.palettesFileModel.removePalette(this.gm.palettesFileModel.selectedPalette)
			let swatches = data.swatches
			for (let i = 0; i < swatches.length; i++) {
				let swatch = swatches[i];
				this.gm.palettesFileModel.selectedPalette.swatches[i].empty = swatch.empty;
				this.gm.palettesFileModel.selectedPalette.swatches[i].rgb = swatch.hex;
				this.gm.palettesFileModel.selectedPalette.swatches[i].rgbfull = swatch.red+','+swatch.green+','+swatch.blue;
				this.gm.palettesFileModel.selectedPalette.swatches[i].zcc = swatch.zcc;
				this.gm.palettesFileModel.selectedPalette.swatches[i].zcc = swatch.zcc;
				this.gm.palettesFileModel.selectedPalette.swatches[i].refname = swatch.referenceName;
				this.gm.palettesFileModel.selectedPalette.swatches[i].refnumber = swatch.referenceNumber;
				if (i == 0) {
					this.gm.palettesFileModel.selectedPalette.swatches[i].selected = true
				}
				else{
					this.gm.palettesFileModel.selectedPalette.swatches[i].selected = false
				}
			}
			this.gm.palettesFileModel.writeDefaultFile();
			this.swatchFileName = fileInput.target.files[0].name;
			this.gm.paletteFileName = this.swatchFileName;
			try {
				localStorage.setItem(this.referenceFileName, this.swatchFileName)	
			} catch (e) {
				console.log("Local Storage is full, Please empty data");
			}
			
			this.gm.palettesFileModel.selectedPalette.notes = data.notes
			this.gm.palettesFileModel.selectedPalette.techpack = data.techpack
			this.swatchFIleTag.nativeElement.value = "";
		}, (error) => {
			this.spinner.hide();
			this.swatchFIleTag.nativeElement.value = "";
			alert(error.error.message);
		})
		

	}

	unlockComparison(){
		this.confirmationDialogService.confirm('UNLOCK', 'YOU ARE UNLOCKING THE SELECTED SWATCH')
			.then((confirmed) =>{
				if(confirmed){
					this.gm.palettesFileModel.selectedSwatch.lock = false
				}
			})	
			.catch(() =>  {

			});
		
	}

	processNow(){
		if (this.imageData == null || this.imageData == undefined) {
			//show alert message and return
			alert('No image found');
			return
		}

				let self = this
				if (self.gm.palettesFileModel.selectedSwatch.lock == true || self.gm.palettesFileModel.selectedSwatch.empty) {
						return
				}
				
				let val = jQuery('#img')
				var mh = 0
				var mw = 0
				 var input

		if (this.selectionTool == "marquee") {
			var h = self.imageData.height;
	      	var w = self.imageData.width;
			mh = jQuery('#marqueeSelection').height();
	     	mw = jQuery('#marqueeSelection').width();
	    	if(mh * mw > 0) {
				var orig = jQuery('#img')[0];
	        	var initialX = orig.offsetLeft;
	        	//change in mx because of scrollIndicator size has been changed so subtract that offset
	        	var mx = parseInt( jQuery('#marqueeSelection').css('left').match(/[0-9]+/)) - initialX;

	        	var my = parseInt( jQuery('#marqueeSelection').css('top').match(/[0-9]+/));
	        	input = self.getImagePortion(mx, my, mh, mw, h, w); // image section
				let selectedSwatchHex = self.gm.palettesFileModel.selectedSwatch.rgb
				let ssHex = selectedSwatchHex.split('#').join('')
				let hexString = ""
				//color thief comparison
				var colorThief    = new ColorThief();
				var palettes = colorThief.getPalettes(h, w, input, 1);
				let dominantPalettes = palettes[0]
				let r=dominantPalettes[0], g=dominantPalettes[1], b=dominantPalettes[2];
				let newhexString = self.rgbToHex(r, g, b)
				newhexString = newhexString.split('#').join('')
		//	if sshex is equal to the dominant color then set hexstring to dominant string
				if (ssHex.toUpperCase() == newhexString.toUpperCase()) {
					hexString = newhexString
					
				}
				else{
				let colorCounter : ColorCounter[] = []
				let data = input
				for (let index = 0; index < data.length; ) {
					var hex:string = self.rgb2hex([data[index], data[index + 1], data[index + 2]]);
					var finalHex = hex
					let isItemExist = false
					for(let i = 0; i < colorCounter.length; i++){
						let item = colorCounter[i]
						if (item.value == finalHex) {
							item.count += 1 
							isItemExist = true
							break
						}
					}
					if (isItemExist == false) {
						let item :ColorCounter = {value: finalHex, count: 1}
						colorCounter.push(item)
					}
					index += 4
				}
				colorCounter.sort(function(a,b){
					return b.count - a.count
				 })	
				hexString = ""
				//prepare hexString to feed the API
				for (let i = 0; i < colorCounter.length; i++) {
					let colorObject = colorCounter[i];
					let simpleHex = colorObject.value
					let hexValue = simpleHex.split('#').join('')
					hexString += hexValue + ','
				}
				hexString = hexString.substr(0, hexString.length - 1)
			}
				self.spinner.show();

				self.apiService.batchhextoCompareZcc(hexString, ssHex, self.tolerancePack.cf, self.tolerancePack.l, self.tolerancePack.c).subscribe((data:any)=>{
					self.spinner.hide();
		       		let dataObj = data["data"]
					let minZCCObj = null
					minZCCObj = dataObj[0]
					var hashHex = "#" + minZCCObj.oldHex
					var crgb = ColorPickerService.hexToRgb( hashHex);
                    self.gm.palettesFileModel.selectedSwatch.deltaE = minZCCObj.deltaE.toFixed(1)
					self.setDeltaEText()
					self.gm.palettesFileModel.selectedSwatch.swatchCouplergb = hashHex
					self.gm.palettesFileModel.selectedSwatch.swatchCoupleZcc = minZCCObj.zcc
					self.gm.palettesFileModel.selectedSwatch.swatchCouplergbfull = crgb.r.toString() + ',' + crgb.g.toString() + ',' + crgb.b.toString();
					if (minZCCObj.deltaE.toFixed(1) <= self.dynamicDeltaE) {

						let msg = '∆E = ' + minZCCObj.deltaE.toFixed(1) + ' BETWEEN THE REFERENCE AND COMPARISON COLORS AND IN YOUR SELECTED TOLERANCE SETTING OF ' + self.dynamicDeltaE + '.'
                        self.gm.palettesFileModel.selectedSwatch.comparisonResult = 'success'
                        self.confirmationDialogService.confirm('MATCH FOUND', msg, 'LOCK RESULT', 'CONTINUE SAMPLING')
                        .then((confirmed) =>{
                          if(confirmed){
                            self.gm.palettesFileModel.selectedSwatch.lock = true
                          }
                        })  
                        .catch(() =>  {
                        });
					} else {
						self.gm.palettesFileModel.selectedSwatch.comparisonResult = 'failure'
						self.gm.palettesFileModel.selectedSwatch.lock = false
					}
					self.gm.palettesFileModel.writeDefaultFile();
				 },(error) =>{
					self.spinner.hide();
					alert(error.error.message)
				})
			}
		}
	}

	setDeltaEText(){
		let self = this
		if (self.gm.palettesFileModel.selectedSwatch.deltaE != null) {
			self.deltaETEXT = self.deltaET + ' : ' + self.gm.palettesFileModel.selectedSwatch.deltaE;
		}
		else{
			self.deltaETEXT = ''
		}
	}

	getImagePortion(mx:any, my:any, mh:any, mw:any, h:any, w:AnalyserNode) {
		let self = this
		  var section = [];
		  var arrayLength = self.imageData.data.length;
		  for(var rowIndex = my; rowIndex<(my+mh); rowIndex++)    // [r, g, b, a, ...]
		  {
			  for(var colIndex = mx; colIndex<(mx+mw); colIndex++)
			  {
				  section.push(self.imageData.data[(rowIndex*Number(w) + colIndex)*4]);
				  section.push(self.imageData.data[(rowIndex*Number(w) + colIndex)*4 +1]);
				  section.push(self.imageData.data[(rowIndex*Number(w) + colIndex)*4 +2]);
				  section.push(self.imageData.data[(rowIndex*Number(w) + colIndex)*4 +3]);
			  }
		  }
		  return section;
		}

		onPaste(event){
			this.zoomImage('0', '1x');
			var self = this;
			const items = (event.clipboardData || event.originalEvent.clipboardData).items;
			// let items = event.clipboardData.items;
			let blob = null;
			for (const item of items) {
				if (item.type.indexOf('image') === 0) {
					blob = item.getAsFile();
					self.fileName = blob.name
				}
			}
	
			// load image if there is a pasted image
			if (blob !== null) {
				if (this.imageData != undefined) {
					this.gm.paletteFileName = undefined
					this.gm.palettesFileModel.removePalette(this.gm.palettesFileModel.selectedPalette)
				}
				const reader = new FileReader();
				reader.onload = (evt: any) => {
					self.previewArea.src = reader.result
					self.paletteColors.length = 0   // empty the array
					self.saveCompareImageDataToLocalStorage()
					
					var img = new Image;
				
					img.onload = function() {
						var response:any; 
					//	response = self.prepareImage(364,274, img);
					    var maxWidth = jQuery('#imageContainer').width();
			    	    var maxHeight = jQuery('#imageContainer').height();
				        response = self.prepareImage(maxWidth, maxHeight, img);
						response = JSON.parse(response)
						self.currDoc = response;						
						self.zoomImageDefaultWidth = response.fullWidth;
						self.zoomImageDefaultHeight = response.fullHeight;
						setTimeout(function(){
							self.cb( null, response.shrink, 0, 0)
							}, 100)
					};
				
					img.src = reader.result.toString()	;
				};
				reader.readAsDataURL(blob);
			} else {
				alert('No image found');
			}
		}

	fileChangedEvent(fileInput){
		if (fileInput.target.files.length == 0) {
			return
		}
		this.zoomImage('0', '1x');
		let file,type: any;
		
		file = fileInput.target.files[0];
		type = file.type;
		if((type != "image/png") && (type != "image/jpeg")){
			return;
		}
		// if (this.imageData != undefined) {
		// 	// this.gm.paletteFileName = undefined
		// 	// this.gm.palettesFileModel.removePalette(this.gm.palettesFileModel.selectedPalette)
		// }
		// // this.compareFilesToUpload = <Array<File>>fileInput.target.files;
		// const formData: any = new FormData();
		// const files: Array<File> = this.compareFilesToUpload;

		

    // 	for(let i =0; i < files.length; i++){
    //     	formData.append("uploads[]", files[i], files[i]['name']);
		// }
		// this.compareFormData = formData;

		this.fileName = file.name;
		var self = this;
		var reader = new FileReader;
		
		reader.onload = function() { // file is loaded
			self.previewArea.src = reader.result
			self.paletteColors.length = 0   // empty the array
		self.saveCompareImageDataToLocalStorage()
			
			var img = new Image;
		
			img.onload = function() {
				var response:any; 
				//response = self.prepareImage(364,274, img);
				var maxWidth = jQuery('#imageContainer').width();
				var maxHeight = jQuery('#imageContainer').height();
				response = self.prepareImage(maxWidth, maxHeight, img);
				response = JSON.parse(response)
				self.currDoc = response;
				
				self.zoomImageDefaultWidth = response.fullWidth;
				self.zoomImageDefaultHeight = response.fullHeight;
				setTimeout(function(){
					self.cb( null, response.shrink, 0, 0)
				  }, 100)
			};
		
			img.src = reader.result.toString()	; // is the data URL because called with readAsDataURL
		};
		
		reader.readAsDataURL(fileInput.target.files[0]);
	}

	saveCompareImageDataToLocalStorage(){
		let self = this
		try {
			localStorage.setItem(self.compareFile, self.previewArea.src)
			localStorage.setItem(self.compareFileName, self.fileName)
		} catch (e) {
			console.log("Local Storage is full, Please empty data");
		}
		
}
	public width: any;
	public height: any;
	cb ( imgPath:String, shrink:number, fullWidth:any, fullHeight:any ): void {
		let self = this;
		self._zone.run(() => {
		  var startTime = Date.now();
	
		  	var orig = jQuery('#img')[0],
		  	refCanvas = jQuery('.reference')[0]
	
		  	var _h = orig.naturalHeight,
			  _w = orig.naturalWidth,
			  x = 0, y = 0, w = _w, h = _h,
			  maxWidth = jQuery('#imageContainer').width(),
	
			  k = w > maxWidth ? maxWidth/w : 1;    // width is the determining factor
			  w = Math.round(w * shrink)
			  h = Math.round(h * shrink)
			  refCanvas.width = w ;
			  refCanvas.height = h;
			self.width = w;
			self.height = h;
		  	var mainHeight = jQuery('main#palleteView').innerHeight() + 105;
		  	jQuery('.main-palette-container').css('height', 'calc(100% - '+mainHeight +'px)');
		  	var context = refCanvas.getContext('2d');
		  	context.drawImage(orig, 0, 0, w, h);
	
				var input = context.getImageData(x, y, w, h);
				self.imageData = input;
		  // if(self.isImageLoaded == false && self.initLoad == false){
			// self.colorsToPalette( self.processPixels( input.data, h, w ), h, w );
		  // }
		  	self.initLoad = false
		  	self.zoomImage(self.zoomImg, self.zoomValue)
			self.isImageLoaded = false
		});
	}

	rgbToHex(R:any,G:any,B:any) {
		var hex = this.toHex(R)+this.toHex(G)+this.toHex(B)
		hex = '#' + hex;
		return hex;
	  }
	  toHex(n:any) {
		n = parseInt(n,10);
		if (isNaN(n)) return "00";
		n = Math.max(0,Math.min(n,255));
		return "0123456789ABCDEF".charAt((n-n%16)/16)
			 + "0123456789ABCDEF".charAt(n%16);
	   }

	   getMarqueeData(input:Array<number>){
		let palettes: ColorCounter[] = []
			let data = input
			for (let index = 0; index < data.length; ) {
				var hex:string = this.rgb2hex([data[index], data[index + 1], data[index + 2]]);
				var finalHex = hex
				let isItemExist = false
				for(let i = 0; i < palettes.length; i++){
					let item = palettes[i]
					if (item.value == finalHex) {
						item.count += 1 
						isItemExist = true
						break
					}
				}
				if (isItemExist == false) {
					let item :ColorCounter = {value: finalHex, count: 1}
					palettes.push(item)
				}
				index += 4
			}	

			palettes.sort(function(a,b){
			   return b.count - a.count
			})
			return palettes
	}

	processPixels ( pixelArray:Array<number>, h: any, w: any ): ColorData[] {
		// let self = this
		// var table: ColorData[] = [] , i, i0, i1, i2, id, r,g,b,x,y;

		// 	let palettes = self.getMarqueeData(pixelArray)
		// 	if (palettes == null){
		// 		palettes = []
		// 	}
		//   for (var pal = 0; pal < palettes.length; pal++) {
		// 	var hex = palettes[pal].value;
		// 	let rgb = ColorPickerService.hexToRgb(hex)
		// 	r=rgb.r, g=rgb.g, b=rgb.b
		// 	var hslArray = this.gm.colorUtility.service.rgbToHsl(r,g,b);
		// 	id = r+','+g+','+b
		// 	if( !table[id] ){
		// 	  table[id] = {val:id, hex:hex, red:r, green:g, blue:b, hue:hslArray[0], saturation:hslArray[1], lightness:hslArray[2], pos:[{x:x,y:y}], zccIndex: 0, zcc: 'zcc-0000' };
		// 	}
		//   }

		let self = this
		var table: ColorData[] = [] , i, i0, i1, i2, id, r,g,b,x,y;
			var colorThief    = new ColorThief();
			var palettes = colorThief.getPalettes(h, w, pixelArray, self.dynamicSwatchLength > 6 ? self.dynamicSwatchLength + 1 : self.dynamicSwatchLength);
			if (palettes == null){
				palettes = []
			}
		  for (var pal = 0; pal < palettes.length; pal++) {
			var element = palettes[pal];
			r=element[0], g=element[1], b=element[2];
			var hex = self.rgbToHex(r, g, b).toUpperCase();
			var hslArray = this.gm.colorUtility.service.rgbToHsl(r,g,b);
			id = r+','+g+','+b
			if( !table[id] ){
			  table[id] = {val:id, hex:hex, red:r, green:g, blue:b, hue:hslArray[0], saturation:hslArray[1], lightness:hslArray[2], pos:[{x:x,y:y}], zccIndex: 0, zcc: 'zcc-0000' };
			}
		  }
		// update pixel info
		self.processedPixels.length = 0
		var res: ColorData[] = []
		for(i in table)
		  res.push( table[i] )          // ??? why copy?
		self.processedPixels = res;
		self.processedPixels.sort(self.sortFuncs['commonDesc'])   // baseline sort of most common
		return res;
	
	  }
		
	colorsToPalette(colorData: ColorData[], h, w):void {
			let self = this
			self._zone.run(() => {
				let hexString = ""
				for(var i = 0; i<colorData.length; i++)
				{
				var clr =  colorData[i] ? colorData[i] : {hex: '#808080'}; //  use 128 gray for 'empty' spots
					var rgbHex = clr.hex || '#808080';   //  use 128 gray for 'empty' spots
					rgbHex = rgbHex.split('#').join('')
					hexString += rgbHex + ','
				}	
				var table : ColorData[] = []
				var res: ColorData[] = []
				hexString = hexString.substr(0, hexString.length - 1)
				let selectedSwatchHex = self.gm.palettesFileModel.selectedSwatch.rgb
				let ssHex = selectedSwatchHex.split('#').join('')
				this.spinner.show();
				this.apiService.batchhextoCompareZcc(hexString, ssHex, self.tolerancePack.cf, self.tolerancePack.l, self.tolerancePack.c).subscribe((data:any)=>{
					this.spinner.hide();
					let dataObj = data["data"]
					if (dataObj.length > 0) {
						let zccObj = dataObj[0]
						var hashHex = "#" + zccObj.oldHex
						self.gm.palettesFileModel.selectedSwatch.swatchCouplergb = hashHex
						self.gm.palettesFileModel.selectedSwatch.swatchCoupleZcc = zccObj.zcc
						var rgb = ColorPickerService.hexToRgb( hashHex);
						self.gm.palettesFileModel.selectedSwatch.swatchCouplergbfull = rgb.r.toString() + ',' + rgb.g.toString() + ',' + rgb.b.toString();
						if (zccObj.zcc == self.gm.palettesFileModel.selectedSwatch.zcc) {
							let msg = '∆E = ' + zccObj.deltaE.toFixed(1) + ' BETWEEN THE REFERENCE AND COMPARISON COLORS AND IN YOUR SELECTED TOLERANCE SETTING OF ' + self.dynamicDeltaE + '.'
							self.gm.palettesFileModel.selectedSwatch.comparisonResult = 'success'
							self.confirmationDialogService.confirm('MATCH FOUND', msg, 'LOCK RESULT', 'CONTINUE SAMPLING')
							.then((confirmed) =>{
								if(confirmed){
									self.gm.palettesFileModel.selectedSwatch.lock = true
								}
							})	
							.catch(() =>  {
								
							});
						}
						else{
							self.gm.palettesFileModel.selectedSwatch.comparisonResult = 'failure'
							self.gm.palettesFileModel.selectedSwatch.lock = false
						}
					}
				},(error) =>{
					this.spinner.hide();
					alert(error.error.message)
				}) 

			})
		   // end zone run
	}
	getColorsForPalette( colorData:any ): ColorData[] {
		let self = this
	
			// returned colors should have this much zcc distance between them
		let paletteColors: ColorData[] = []   // assumed to be six colors for now
		var returnColorCount = this.dynamicSwatchLength; // colorData.length < 6 ? colorData.length : 6    // can't return more than we that with which we started
		let sampleSet = colorData.slice(0, colorData.length)
		var spread = 10;
		switch(self.sortMode) {
			case 'commonDesc':
			// we already sorted by common in processedPixels
			break;
			case 'saturationDesc':
			case 'lightnessDesc':
			sampleSet.sort(self.sortFuncs[self.sortMode]);
			break;
			case 'random':
			case 'random2':
			// we're taking our sample from the top 50% most common if we have enough.
			var n = sampleSet.length > 6 * spread ? Math.round(sampleSet.length/2) : sampleSet.length
			sampleSet.length = n
			var indices = [];
			sampleSet.forEach( elem => elem.order =  Math.random() )
			sampleSet.sort( (a,b) => b.order - a.order )     // new random order
			break;
		}
	
		//  populate the result set of colors trying to avoid similar colors by checking the spread
		let sampleIndex = 0;
	
		for(var i = 0; i<returnColorCount; i++){
			// check if we beat the spread
			// If we run out of colors, ignore the spread.
			// If we fail to beat the spread, try the next item in the sample set array.
			if ( sampleSet.length - sampleIndex > returnColorCount - i ) {   //  Do we have more samples than we need?  If not, skip the spread check.
			/* *** TODO: if we're going skip the check at some point, should we skip at the beginning?
				Sliding spread based on color count?   */
			let redo = false
			paletteColors.forEach( elem => {
				if ( Math.abs(sampleSet[sampleIndex].zccIndex - elem.zccIndex) < spread ) {
				redo = true;
				}
			})
			if (redo ) {
				i--
				sampleIndex++
				continue         // try again looking at the next entry in the sample set
			}
			} else {
			}
			if (sampleSet[sampleIndex] == undefined) {
			paletteColors.push(paletteColors[paletteColors.length - 1])
			}
			else{
			paletteColors.push(sampleSet[sampleIndex])
			}
			sampleIndex++
		}
		return paletteColors;
  	}
  	addPaletts (){
		var self = this;
		if(this.isAddingGoesOverTheLimitSwatches()){
			return;
		}
		self.removeAllPallets();
		let hashHex;
			for(let i=0; i<this.paletteColors.length; i++){
				if(this.paletteColors[i] && this.paletteColors[i].hashHex){
				hashHex = this.paletteColors[i].hashHex;
				} else {
				hashHex = '#CCCCCC';
				}
			for(let j=0; j<this.gm.palettesFileModel.selectedPalette.swatches.length;j++){
				let swatch = this.gm.palettesFileModel.selectedPalette.swatches[j];
				if(swatch.empty === true){
				//self.gm.colorUtility.generateColors(hashHex, swatch);
				self.gm.colorUtility.generateZCCColors(hashHex,this.paletteColors[i].zcc, swatch);
				swatch.empty = false;
				break;
				}
			}
		}
		this.gm.palettesFileModel.writeDefaultFile();
	}
	removeAllPallets():void {
		this.gm.emptyPalettesModelSavedDetails();

		this.gm.palettesFileModel.removePalette( this.gm.palettesFileModel.selectedPalette);
	}
	isAddingGoesOverTheLimitSwatches() {
		let self = this;
			var nonEmptySwatched = [];
			for(var item of self.gm.palettesFileModel.selectedPalette.swatches){
			if (item.empty === false) {
				nonEmptySwatched.push(item);
			}
			}
		if ((nonEmptySwatched.length + self.paletteColors.length) > 64) {
			this.confirmationDialogService.alert('Error', 'Swatch Preview is Full. (64 swatch maximum)"')
			.then((confirmed) =>{
				if(confirmed){
				}
			})	
			.catch(() =>  {

			});
			return true;
		}
		return false;
  	}
	prepareImage (maxWidth, maxHeight, image) {    
		let s = {height: image.height, width: image.width};
		let xs = 1
		let ys = 1
		let shrink = 1
		if (maxWidth < s.width) {
		  xs = maxWidth / s.width
		}
		if (maxHeight < s.height) {
		  ys = maxHeight / s.height
		}
		if (xs < ys) {
		  shrink = xs
		  image.width = maxWidth;
		  image.height = s.height*xs;
		} else if (ys < 1) {
		  shrink = ys
		  image.height = maxHeight;
		  image.width = s.width*ys;
		}
		else{
		  image.width = s.width;
		  image.height = s.height;
		}

        //below code is to enable aspect fil
		// let minSize = {height: maxHeight, width: maxWidth};

		// let aspectFill = this.aspectFill(s, minSize)
		// image.width = aspectFill.aspectSize.width
		// image.height = aspectFill.aspectSize.height
		// shrink = aspectFill.diff

		//center
		// image.width = s.width
		// image.height = s.height
		// shrink = 1

	  
		return JSON.stringify(
		  {
			// 'path': imagePath,
			'shrink': shrink,
			time: 1.0,
			fullWidth: image.width,
			fullHeight: image.height
		  })
	  }

	   aspectFill(aspectRatio, minimumSize){
        let mW = minimumSize.width / aspectRatio.width;
        let mH = minimumSize.height / aspectRatio.height;
		let shrink = 1
        if( mH > mW ) {
			shrink = mH
            minimumSize.width = minimumSize.height / aspectRatio.height * aspectRatio.width;
        }
        else if( mW > mH ) {
			shrink = mW
            minimumSize.height = minimumSize.width / aspectRatio.width * aspectRatio.height;
        }
        
        return {aspectSize: minimumSize, diff: shrink};
    }

	marqueeDeselect():void {
		this.marqueeHide();
		// reset palette to full image?
    }

    marqueeHide():void {
        jQuery("#marqueeSelection").hide();
        // jQuery('#marqueeImg').css( "webkitClipPath", "none");
        jQuery('#marqueeImg').css( "clip-path", "none");
    }
	zoomImage(zm:any, value?) {
		
			if(!this.currDoc){
				return;
			}
			let zoomvalue = zm;
			zm = zm * 50;
			this.marqueeDeselect();
			this.zoomModel = value;
			let zoom = Number(zm);
			this.zoomImg = zm;
				if(zoomvalue == 6) zoom = 0;
			let imageWid = Number(jQuery('#img').width());
			let imageHgt = Number(jQuery('#img').height());
			if(zoom === 0){
				imageWid = this.zoomImageDefaultWidth;
				imageHgt = this.zoomImageDefaultHeight;
			} else {
				let mWidth =  this.zoomImageDefaultWidth + zoom;
				let mHeight =  this.zoomImageDefaultHeight + zoom;
				let  xs = mWidth / this.zoomImageDefaultWidth;
				let ys = mHeight / this.zoomImageDefaultHeight;
				let shrink;
				if (xs > ys) {
				imageWid = mWidth;
				imageHgt = this.zoomImageDefaultHeight*xs;
				} else {
				imageHgt = mHeight;
				imageWid = this.zoomImageDefaultWidth*ys;
				}
			}
			this.width = imageWid;
			this.height = imageHgt;
		
			jQuery('#img').width(imageWid+'px');
			jQuery('#img').height(imageHgt+'px');
		
			jQuery('#canvaszoom').attr('width', imageWid);
			jQuery('#canvaszoom').attr('height', imageHgt);
			var orig = jQuery('#img')[0],
				refCanvas = jQuery('.reference')[0]
			var context = refCanvas.getContext('2d');
			context.clearRect(0, 0, imageWid, imageHgt);
			context.drawImage(orig, 0, 0, imageWid, imageHgt);
			var input = context.getImageData(0, 0, imageWid, imageHgt);
			this.imageData = input;
			jQuery('#marqueeImg').width(imageWid+'px');
			jQuery('#marqueeImg').height(imageHgt+'px');
	}
	rgb2hex( clr:any ): string {
		var clrs = (clr.length==3) ? clr : clr.split(',')
		  , hex = clrs.map( function( clr:any ){
			  var val = Number( clr ).toString(16);
			  return (val.length===1 ? '0'+val : val);
			})
		hex = '#' + hex.join('');
		hex.toUpperCase()
		return hex;
	}
	toolSelect(mode:string) {
		// this.samplingDefault.nativeElement.checked = true;
		var self = this;
		if ( this.selectionTool == "sampler" && mode == "sampler") {
		this.selectionTool = "window-box"
		mode = "window-box"
		}
		jQuery('.tool-selected').removeClass('tool-selected');
		jQuery('#'+mode).addClass('tool-selected');
		jQuery('#img').not('.select-mode').addClass('select-mode');
		jQuery('#img').unbind('click');

		switch(mode) {
			case "sampler":
				jQuery('#sampler').attr("src", "../../../assets/Eyedropper-icon-selected.svg");
						this.selectionTool = "sampler"
				self.marqueeHide();
				jQuery('#img').unbind('mousedown' );
				jQuery('#img').css('cursor','url("/assets/cross_hair.png") 25 25,auto').mousemove(function(e:any) {
					var data = jQuery('.reference')[0].getContext('2d').getImageData(e.offsetX, e.offsetY, 1, 1).data;
					var hex:string = self.rgb2hex([data[0], data[1], data[2]]);
					let hashHex = '#'+hex;
					jQuery('#tooltip-span').css({'background-color':hashHex})
				} );
				jQuery('#img').css('cursor','url("/assets/cross_hair.png") 25 25,auto').click(function(e:any) {
					if (self.gm.palettesFileModel.selectedSwatch.lock == true || self.gm.palettesFileModel.selectedSwatch.empty) {
						return
				}
				var selectedSwatch = self.gm.palettesFileModel.selectedSwatch
				if ( !selectedSwatch || selectedSwatch === undefined )
					return;
					//take the center value
				let centerX = e.offsetX
				let centerY = e.offsetY
					//create the square
					//square height, weigth
					let squareHeight = self.samplingValue
					let squareWidth = self.samplingValue
					//square origin
					let squareX = centerX - (self.samplingValue -1)/2
					let squareY = centerY - (self.samplingValue -1)/2

			var imgdata = jQuery('.reference')[0].getContext('2d').getImageData(squareX, squareY, squareHeight, squareWidth);
			
				let colorCounter : ColorCounter[] = []
				let data = imgdata.data
				for (let index = 0; index < data.length; ) {
					var hex:string = self.rgb2hex([data[index], data[index + 1], data[index + 2]]);
					var finalHex = hex
					let isItemExist = false
					for(let i = 0; i < colorCounter.length; i++){
						let item = colorCounter[i]
						if (item.value == finalHex) {
							item.count += 1 
							isItemExist = true
							break
						}
					}
					if (isItemExist == false) {
						let item :ColorCounter = {value: finalHex, count: 1}
						colorCounter.push(item)
					}
					index += 4
				}
				colorCounter.sort((a,b) => (a.count > b.count) ? -1 : ((b.count > a.count) ? 1 : 0)); 
				let simpleHex = colorCounter[0].value
				
					//api call

				let hexString = simpleHex.split('#').join('')
				let selectedSwatchHex = self.gm.palettesFileModel.selectedSwatch.rgb
				let ssHex = selectedSwatchHex.split('#').join('')
				self.spinner.show();
				self.apiService.batchhextoCompareZcc(hexString, ssHex, self.tolerancePack.cf, self.tolerancePack.l, self.tolerancePack.c).subscribe((data:any)=>{
					self.spinner.hide();
					let dataObj = data["data"]
			         let zccObj = dataObj[0]
			         var hashHex = "#" + zccObj.oldHex
					var rgb = ColorPickerService.hexToRgb( hashHex);
					self.gm.palettesFileModel.selectedSwatch.deltaE = zccObj.deltaE.toFixed(1)
		            self.setDeltaEText()
		            self.gm.palettesFileModel.selectedSwatch.swatchCouplergb = hashHex
		            self.gm.palettesFileModel.selectedSwatch.swatchCoupleZcc = zccObj.zcc
		            self.gm.palettesFileModel.selectedSwatch.swatchCouplergbfull = rgb.r.toString() + ',' + rgb.g.toString() + ',' + rgb.b.toString();
					if (zccObj.deltaE.toFixed(1) <= self.dynamicDeltaE) {
						let msg = '∆E = ' + zccObj.deltaE.toFixed(1) + ' BETWEEN THE REFERENCE AND COMPARISON COLORS AND IN YOUR SELECTED TOLERANCE SETTING OF ' + self.dynamicDeltaE + '.'
						self.gm.palettesFileModel.selectedSwatch.comparisonResult = 'success'
						self.confirmationDialogService.confirm('MATCH FOUND', msg, 'LOCK RESULT', 'CONTINUE SAMPLING')
						.then((confirmed) =>{
							if(confirmed){
								self.gm.palettesFileModel.selectedSwatch.lock = true
							}
						})	
						.catch(() =>  {
							
						});
						
					}
					else{
						self.gm.palettesFileModel.selectedSwatch.comparisonResult = 'failure'
						self.gm.palettesFileModel.selectedSwatch.lock = false
					}
					self.gm.palettesFileModel.writeDefaultFile();
				 },(error)=>{
					self.spinner.hide();
					alert(error.error.message)
				})
				
			} );

				break;
			case "marquee":
				jQuery('#marquee').attr("src", "../../../assets/marquee_icon_selected.svg");
				//jQuery('#marquee').src = "../../../assets/marquee_icon_selected.png"
				this.selectionTool = "marquee";
				jQuery('#img').css('cursor','crosshair').mousedown(function (e:any) {
				var marqueeDrag = false;
				var orig = jQuery('#img')[0];
				var initialX = e.offsetX + orig.offsetLeft;
				var initialW = e.offsetX;
				var initialH = e.offsetY;
				var fullWidth = jQuery('#marqueeImg').width();
				var fullHeight = jQuery('#marqueeImg').height();

				jQuery('#img').bind("mousemove", function(moveEvent:any) {
					var w = Math.abs(initialW - moveEvent.offsetX);
					var h = Math.abs(initialH - moveEvent.offsetY);

					if(!marqueeDrag && (h+w > 3)) {
						jQuery("#marqueeSelection")
						.show()
						.css({
							'left': initialX,
							'top': e.offsetY
						});
						marqueeDrag = true;
					}
					var topVal = initialH, rightVal, bottomVal, leftVal = initialW;
					var leftMarqueeValue = initialX;

					if (moveEvent.offsetX <= initialW && moveEvent.offsetY >= initialH) {
						leftVal = moveEvent.offsetX;
						leftMarqueeValue = moveEvent.offsetX + orig.offsetLeft;
					} else if (moveEvent.offsetY <= initialH && moveEvent.offsetX >= initialW) {
						topVal = moveEvent.offsetY;
					} else if (moveEvent.offsetY < initialH && moveEvent.offsetX < initialW) {
						leftVal = moveEvent.offsetX;
						leftMarqueeValue = moveEvent.offsetX + orig.offsetLeft;
						topVal = moveEvent.offsetY;
					}
					jQuery("#marqueeSelection").css({ 'width': w, 'height': h, 'left': leftMarqueeValue, 'top': topVal });

					rightVal = fullWidth - leftVal - w;
					bottomVal = fullHeight - topVal - h;

					jQuery('#marqueeImg').css( "webkitClipPath", "inset("+ topVal +"px "+rightVal+"px "+ bottomVal +"px "+ leftVal +"px)");
					jQuery('#marqueeImg').css( "clip-path", "inset("+ topVal +"px "+rightVal+"px "+ bottomVal +"px "+ leftVal +"px)");
				});
				jQuery('#img').bind("mouseup", function(upEvent:any) {
					jQuery('#img').unbind("mousemove mouseup");
					if(marqueeDrag){
					//self.getMarqueePalette();
					}
					else {
						self.marqueeHide();
					}
					marqueeDrag = false;
				});
			});
			//   self.gm.scrollHorizontal();
			break;
						case "window-box":
				self.marqueeHide();
				jQuery('#img').unbind('mousedown' ).css('cursor','default');
				this.selectionTool = 'window-box';
			break;
			default:
				console.log("Unknown mode: " + mode);
		}
	}
	unselectSampler() : void {
		this.selectionTool = "window-box"
		jQuery('#img').unbind('click');
		jQuery('#img').unbind('mousedown' ).css('cursor','default');
		jQuery('#marquee').attr("src", "../../../assets/marquee_icon.svg");
		jQuery('#sampler').attr("src", "../../../assets/Eyedropper-icon-white.svg");
	}


	incrementcf(){
		//case to fix the number of swatches to 1 in case of average and marquee

			if(this.cf == 3){
				return;
			}
			this.cf += 1;
			this.tolerancePack.cf = this.cf
			this.dynamicDeltaE = this.cf
		}
		
	decrementcf(){
		if(this.cf == 1){
			return;
		}
		this.cf -= 1;
		this.tolerancePack.cf = this.cf
		this.dynamicDeltaE = this.cf
	}

	incrementl(){
		//case to fix the number of swatches to 1 in case of average and marquee

			if(this.l == 5.0){
				return;
			}
			this.l = (this.l * 10 + 0.1 * 10) / 10;
			this.tolerancePack.l = this.l
		}
		
	decrementl(){
		if(this.l == 1.0){
			return;
		}
		this.l = (this.l * 10 - 0.1 * 10) / 10;
		this.tolerancePack.l = this.l
	}

	incrementc(){
		//case to fix the number of swatches to 1 in case of average and marquee

			if(this.c == 5.0){
				return;
			}
			this.c = (this.c * 10 + 0.1 * 10) / 10;
			this.tolerancePack.c = this.c
		}
		
	decrementc(){
		if(this.c == 1.0){
			return;
		}
		this.c = (this.c * 10 - 0.1 * 10) / 10;
		this.tolerancePack.c = this.c
	}

	incrementBasicTolerance(){
		//case to fix the number of swatches to 1 in case of average and marquee

			if(this.basicTolerance == 3){
				return;
			}
			this.basicTolerance += 1;
			this.setBasicTolerancePack()
		}
		
	decrementBasicTolerance(){
		if(this.basicTolerance == 1){
			return;
		}
		this.basicTolerance -= 1;
		this.setBasicTolerancePack()
	}

	setBasicTolerancePack(){
		switch (this.basicTolerance) {
			case 1:
				this.dynamicDeltaE = 1.0
				this.tolerancePack.cf = 1
				this.tolerancePack.l = 1
				this.tolerancePack.c = 1
				break;
			case 2:
				this.dynamicDeltaE = 2.0
				this.tolerancePack.cf = 1
				this.tolerancePack.l = 2
				this.tolerancePack.c = 1
			break;
			case 3:
				this.dynamicDeltaE = 3.0
				this.tolerancePack.cf = 2
				this.tolerancePack.l = 2
				this.tolerancePack.c = 1
			break;
		
			default:
				break;
		}
	}

	

}
