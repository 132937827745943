import { Component } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
export interface alertModel {
  header: string;
  title:string;
  message:string;
}
@Component({
    selector: 'confirm',
    template: `<div class="modal-dialog small-model">
    <div class="modal-content">
       <div class="modal-header">
         <button type="button" class="close" (click)="close()">&times;</button>
         <h4 class="modal-title">{{header || 'ERROR'}}</h4>
       </div>
       <div class="modal-body">
       <p class="modal-message linebreak" style="letter-spacing: -.5px;" [innerHTML]="title"></p>
       <hr class="hr-grey">
       </div>

       <div class="modal-footer">
         <button type="button" class="btn btn-primary" (click)="confirm()">OK</button>
       </div>
     </div>
    </div>`
})
export class alertComponent extends DialogComponent<alertModel, boolean> implements alertModel {
  header: string;
  title: string;
  message: string;
  constructor(dialogService: DialogService) {
    super(dialogService);
  }
  confirm() {
    // we set dialog result as true on click on confirm button,
    // then we can get dialog result from caller code
    this.result = true;
    this.close();
  }
}
