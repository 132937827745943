import { OnInit, Injector } from '@angular/core';
import { GlobalModel } from "./models/global.model";
import { HostListener } from '@angular/core';
declare var _: any;

export abstract class BaseComponent implements OnInit {
    $el: any;
    gm: GlobalModel;
    showPopup: boolean;
    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
    location.reload()
    }
    constructor(public injector: Injector) {
        this.gm = injector.get(GlobalModel);
        this.gm.resetComparisonResult()
        let popUpValue = localStorage.getItem('popup');
        if (popUpValue === 'true')
            this.showPopup = true;
        else
            this.showPopup = false;
    }

    ngOnInit() {

    }
}
