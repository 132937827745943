import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'dye-formulas',
	templateUrl: './dye-formulas.html',
	styleUrls: ['./dye-formulas.scss'],
	encapsulation: ViewEncapsulation.None
})
export class DyeFormulasPage implements OnInit {
	constructor() { }
	ngOnInit() {
	}
}