import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from  '../../auth.service';
import { Router } from  '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {environment} from '../../../environments/environment';

@Component({
	selector: 'ngbd-modal-content',
	styles: ['.modal-header { background: #333344; border-bottom: none; } .modal-body { background: #333344; }'],
	template: `
		<div class="modal-header">
		<h4 class="modal-title">&nbsp;</h4>
		<button type="button" class="close" style="color:#fff" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
			<span aria-hidden="true">&times;</span>
		</button>
		</div>
		<div class="modal-body" style="text-align:center; color: #fff; padding-top:10px;">
			<img src="/assets/logo.png" />
			<p style="margin-top:15px">v 1.0.3</p>
			<p>&copy; zenColor<sup>®</sup> Company LLC 2019 - All Rights Reserved</p>
		</div>
	`
})

export class NgbdModalContent {
	@Input() name;
  
constructor(public activeModal: NgbActiveModal) {}
}

@Component({
	selector: 'common-header',
	templateUrl: './header.html',
	styleUrls: ['./header.scss'],
	encapsulation: ViewEncapsulation.None
})
export class HeaderCommon implements OnInit {
	links : any[]
	constructor(private modalService: NgbModal, public router: Router, public authService: AuthService) {}
	ngOnInit() {
		this.links = [
			{
				'link' : environment.shopURL,
				'text' : 'Shop'
			},
			{
				'link' : environment.accountURL,
				'text' : 'My Account'
			}
		]
	}

	logout(){
		this.authService.logout();
		this.router.navigateByUrl('/login');
	}

	about(){
		const modalRef = this.modalService.open(NgbdModalContent);
	}
}