import { Component } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
export interface ConfirmModel {
  title:string;
  message:string;
}
@Component({  
  selector: 'update',
  template: `<div class="modal-dialog">
              <div class="modal-content">
                 <div class="modal-header">
                   <button type="button" class="close" (click)="close()" >&times;</button>
                   <h4 class="modal-title" style="text-align:center">{{title || 'There is an update available'}}</h4>
                 </div>
                 <div class="modal-footer" style="text-align:center; padding-top:10px!important;">
                   <button type="button" class="btn btn-primary" (click)="confirm()">update now</button>
                   <button type="button" class="btn btn-default" (click)="close()" >remind me later</button>
                 </div>
               </div>
            </div>`
})
export class UpdateAppConfirmComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel {
  title: string;
  message: string;
  constructor(dialogService: DialogService) {
    super(dialogService);
  }
  confirm() {
    // we set dialog result as true on click on confirm button, 
    // then we can get dialog result from caller code 
    this.result = true;
    this.close();
  }
}