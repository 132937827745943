import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-getdeltae',
  templateUrl: './getdeltae.component.html',
  styleUrls: ['./getdeltae.component.scss']
})
export class GetdeltaeComponent implements OnInit {
  rawJson: any;
  constructor(private apiService: ApiService) { }

  ngOnInit() {
  }

  fileChangedEvent(fileInput: any) {
	  var self = this;
	  self.rawJson = ""
		 if(fileInput.target.files[0].type == 'text/csv' || fileInput.target.files[0].type == 'application/vnd.ms-excel'){
			// this.filesToUpload = <Array<File>>fileInput.target.files;
      var r = new FileReader();
      r.onload = function(e) {
        var eventTarget = <FileReader>e.target;
        var contents:any;
        contents = eventTarget.result;
        contents = contents.split("\n");
        contents.shift();
        var filtered = contents.filter(function(el) { return el; });
        self.rawJson =  filtered.toString();
      };
      r.readAsText(fileInput.target.files[0]);
		 }
		
  }
  rgbtohexfileChangedEvent(fileInput: any) {
		var self = this;
		self.rawJson = ''
		 if(fileInput.target.files[0].type == 'text/csv' || fileInput.target.files[0].type == 'application/vnd.ms-excel'){
			// this.filesToUpload = <Array<File>>fileInput.target.files;
			var r = new FileReader();
			r.onload = function(e) {
				var eventTarget = <FileReader>e.target;
				var contents:any;
				contents = eventTarget.result;
				contents = contents.split("\n");
				contents.shift();
				var filtered = contents.filter(function(el) { return el; });
				self.rawJson =  filtered.toString();
			};
			r.readAsText(fileInput.target.files[0]);
		}
		
  }

  exportCSVFile(){
		
		this.apiService.rgbtodeltae(this.rawJson).subscribe(
			(res) => {

				var today = new Date();
				var day = today.getDate() + "";
				var month = (today.getMonth() + 1) + "";
				var year = today.getFullYear() + "";
				var hour = today.getHours() + "";
				var minutes = today.getMinutes() + "";
				var seconds = today.getSeconds() + "";
				
				day = this.checkZero(day);
				month = this.checkZero(month);
				year = this.checkZero(year);
				hour = this.checkZero(hour);
				minutes = this.checkZero(minutes);
				seconds = this.checkZero(seconds);
				var todayDateTime = month+'-'+day+'-'+year+' at '+hour+'.'+minutes+'.'+seconds;

				var outputFile = 'csv_export_'+todayDateTime+'.csv';
				const element = document.createElement('a');
				element.href = URL.createObjectURL(res.image);
				element.download = outputFile;
				document.body.appendChild(element);
				element.click();
			},
		(error) =>{
			alert(error.error.message);
		});
	}

	
	exportrgbtohexCSVFile(){
		
		this.apiService.rgbtohex(this.rawJson).subscribe(
			(res) => {

				var today = new Date();
				var day = today.getDate() + "";
				var month = (today.getMonth() + 1) + "";
				var year = today.getFullYear() + "";
				var hour = today.getHours() + "";
				var minutes = today.getMinutes() + "";
				var seconds = today.getSeconds() + "";
				
				day = this.checkZero(day);
				month = this.checkZero(month);
				year = this.checkZero(year);
				hour = this.checkZero(hour);
				minutes = this.checkZero(minutes);
				seconds = this.checkZero(seconds);
				var todayDateTime = month+'-'+day+'-'+year+' at '+hour+'.'+minutes+'.'+seconds;

				var outputFile = 'csv_export_'+todayDateTime+'.csv';
				const element = document.createElement('a');
				element.href = URL.createObjectURL(res.image);
				element.download = outputFile;
				document.body.appendChild(element);
				element.click();
			},
		(error) =>{
			alert(error.error.message);
		});
	}



  checkZero(data){
		if(data.length == 1){
			data = "0" + data;
		}
		return data;
	}

  
}
