import {Injectable, Injector} from '@angular/core';
import {FileModel} from "./file.model";

declare var SystemPath: any;
declare var _: any;

@Injectable()
export class PreferencesModel extends FileModel{
    data = {
        "version": "1.0.1",
        "colorSystem" : {
            "hex": {
                "visible": true
            },
            "rgb": {
                "visible": true
            },
            "lab": {
                "visible": true
            },
            "hsl": {
                "visible": true
            },
            "cmyk": {
                "visible": true
            }
        },
        "customFields" : {
            "customField1": {
                "visible": true
            },
            "customField2": {
                "visible": true
            },
            "customField3": {
                "visible": true
            },
            "customField4": {
                "visible": true
            },
            "customField5": {
                "visible": true
            }
        },
        "settings" : {
            "editSwatch": {
                //you can add/remove brightness, level1 is most subtraction of brightness, level6 is most addition to brightness
                //expects decimal values between 0 and 1 for percentages
                "brightness": {
                    "level1": -.1,
                    "level2": -.05,
                    "level3": -.01,
                    "level4": .01,
                    "level5": .05,
                    "level6": .10,
                },
                //you can add/remove saturation, level1 is most subtraction of brightness, level6 is most addition to saturation
                //expects decimal values between 0 and 1 for percentages
                "saturation": {
                    "level1": -.1,
                    "level2": -.05,
                    "level3": -.01,
                    "level4": .01,
                    "level5": .05,
                    "level6": .10,
                },
                //you can add/remove saturation, level1 is most subtraction of brightness, level6 is most addition to saturation
                //expects decimal values between 0 and 1 for percentages
                "hue": {
                    "level1": -.1,
                    "level2": -.05,
                    "level3": -.01,
                    "level4": .01,
                    "level5": .05,
                    "level6": .10,
                },
                //you can only add to green so 1 is lowest addition, 3 is highest
                "green": {
                    "level1": 10,
                    "level2": 30,
                    "level3": 50
                },
                //you can only add to green so 1 is lowest addition, 3 is highest
                "red": {
                    "level1": 10,
                    "level2": 30,
                    "level3": 50
                }
            }
        }
    };
    constructor() {
        super();
        this.init();
    }

    init():void {
        this.fileName =  "zcdp.preferences.json";
        this.initFileCheck();
    }
}
