import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
  Injector,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { BaseComponent } from "../../base.component";
import { ApiService } from "../../api.service";
import { ColorPickerService } from "../../components/color-picker/color-picker.service";
import { SwatchColorChangedEvent } from "../../events/swatch.color.changed.event";
import { SwatchModel } from "../../models/swatch.model";
import { SwatchChangedEvent } from "../../events/swatch.changed.event";

import { ConfirmationDialogService } from "../../confirmation-dialog/confirmation-dialog.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import * as cloneDeep from "lodash/cloneDeep";

import * as _ from "underscore";
import { style } from "@angular/animations";
declare var jQuery: any;
interface SwatchColor {
  hex: string;
  hashHex: string;
  zcc: string;
  empty: boolean;
  refname: string;
  refnumber: string;
}
interface PreviewArea {
  src: any;
  width: number;
  height: number;
}
@Component({
  selector: "image-exchange",
  templateUrl: "./image-exchange.page.html",
  styleUrls: ["./image-exchange.page.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ImageExchange extends BaseComponent implements OnInit {
  public previewArea: PreviewArea = { src: "", width: 100, height: 100 };
  public zoomImageDefaultWidth: any;
  public zoomImageDefaultHeight: any;
  public currDoc: string = "";
  exportFormats: any[];
  showBackButton: boolean = false;
  originalSwatches: any = [];
  paletteOptions: any[];
  paletteCheckOptions: any[];
  showWorkSpace = false;
  lastSortedBy = "";
  fileExchange = {
    userName: "",
    company: "",
    date: "",
    fileFormat: "PNG",
    swatchFileName: "",
    imageFileName: "",
    brandName: "",
    skuNumber: "",
    countryOfOrigin: "",
    factory:"",
    supplier:"",
    completeBy:"",
    startBy:"",
    retailPrice: "",
    productCategory: "",
    productTitle: "",
    material: "",
    productDescription: "",
    marketingInformation: "",
    printType: "solid",
    primaryColor: null,
    secondaryColor: null,
    secondaryColors: [],
    imageWidth: 300,
    imageHeight: 300,
    imageData: "",
    watermark: "approved"

  };
  imageURL = null;
  imageStandard = null;
  selectedPrintTypeOption = null;
  @ViewChild("swatchFile") private swatchFile: ElementRef;
  gmPalleteModel = null;
  sub = null;
  fromDigitalManager = false;
  public skuName = "";
  secondaryPalletes: any[] = [];
  private _swatchChangedSubscription: any;
  constructor(
    private router: Router,
    private location: Location,
    private spinner: NgxSpinnerService,
    public confirmationDialogService: ConfirmationDialogService,
    public injector: Injector,
    public apiService: ApiService,
    public _zone: NgZone,
    public ref: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    super(injector);

    let currentNavigation = this.router.getCurrentNavigation();
    console.log(currentNavigation.extras.state);
    if (
      currentNavigation.extras.state != null ||
      currentNavigation.extras.state != undefined
    ) {
      let params = currentNavigation.extras.state;
      if (params.imageAsDataURL != undefined) {
        this.imageURL = params.imageAsDataURL;
        this.fileExchange = {...this.fileExchange, ...this.gm.palettesImageExchangeModel.selectedPalette.metaData}
        this.fileExchange.swatchFileName = this.removeFileExtension(params.fileName);
        this.fileExchange.imageFileName = this.fileExchange.swatchFileName + "_ZCIF"
      }
      if (
        params.fromDigitalImageManager != undefined &&
        params.fromDigitalImageManager == true
      ) {
        this.fromDigitalManager = true;
      }
    }
  }
  // goBack(){
  // 	this.location.back()
  // }
  ngOnDestroy() {
    console.log('ngdestroy called')
  }

  ngOnInit() {
    this.loadImage();
    let swatches = [];
    if (this.fromDigitalManager == true) {
      for (
        var i = 0;
        i < this.gm.palettesImageExchangeModel.selectedPalette.swatches.length;
        i++
      ) {
        var swatch =
          this.gm.palettesImageExchangeModel.selectedPalette.swatches[i];
        if (swatch.empty != true) {
          swatches.push(swatch);
          this.originalSwatches = JSON.parse(JSON.stringify(swatches));
        }
      }
    }

   
    this.exportFormats = [
      {
        formatID: "PNG",
        formatText: "PNG",
      },
      {
        formatID: "JPG",
        formatText: "JPG",
      },
      // {
      // 	'formatID': 'ASE',
      // 	'formatText': 'ASE'
      // },
      // {
      // 	'formatID': 'ACO',
      // 	'formatText': 'ACO'
      // },
      // {
      // 	'formatID': 'CSV',
      // 	'formatText': 'CSV'
      // },
      // {
      // 	'formatID': 'JSON',
      // 	'formatText': 'JSON'
      // }
    ];

    this.paletteCheckOptions = [
      {
        formatID: "solid",
        formatText: "SOLID",
        checked: true,
      },
      {
        formatID: "print",
        formatText: "PRINT",
        checked: false,
      },
    ];
    this.selectedPrintTypeOption = this.paletteCheckOptions[0];
    this.secondaryPallete();
    let palettesImageExchangeModel = this.gm.palettesImageExchangeModel;
    this._swatchChangedSubscription =
      palettesImageExchangeModel.swatchChanged.subscribe((id) =>
        this.swatchChanged(id)
      );

      this.selectedPrintTypeBasedOn(this.fileExchange.printType)

    // setTimeout(() => {
    //   var heightStyleValue = jQuery('#common-box2.imageexchangecolorbox>.scroll-bar.vertical')[0].style.height;
    //   var ret = heightStyleValue.replace("px","");
    //   console.log(ret - 21)
    //   jQuery('#common-box2.imageexchangecolorbox>.scroll-bar.vertical').css("height", ret)
    //   let scrollItem = jQuery('#common-box2.imageexchangecolorbox>.scroll-bar.vertical').attr("style")
    //   console.log(scrollItem)

    // }, 500);

  }
  swatchChanged(o: SwatchChangedEvent): void {
    let index =
      this.gm.palettesImageExchangeModel.selectedPalette.swatches.findIndex(
        (item) => {
          if (!item.empty) {
            return (
              item.id == this.gm.palettesImageExchangeModel.selectedSwatch.id
            );
          }
        }
      );

    if (index != -1) {
      var swatch =
        this.gm.palettesImageExchangeModel.selectedPalette.swatches[index];
      if (swatch.id != this.secondaryPalletes[0].id) {
        this.selectedSecondaryOption = swatch;
        jQuery("#" + this.selectedSecondaryOption.id).prop("selected", true);
      }
    }
  }

  fillMissingTag() { }

  checkForMissingTag() {
    setTimeout(() => {
      jQuery("#indentifyMissingTags").prop(
        "checked",
        this.gm.palettesModel.selectedPalette.shouldShowMissingTags
      );
    }, 10);
  }

  selectFileFormat(format: any) {
    jQuery("#" + format).prop("checked", true);
    this.fileExchange.fileFormat = format;
  }
  contrastingFontColor(swatch): string {
    return ColorPickerService.isDarkColor(swatch.rgb)
      ? "rgb(255, 255, 255)"
      : "rgb(0, 0, 0)";
  }

  isSecondaryEnabled() {
    if (this.selectedPrintTypeOption.formatID == "print") {
      return true;
    }
    jQuery("#selectAccentColors").prop("selectedIndex", 0);
    return false;
  }
  selectedSecondaryOption = null;
  secondaryOptionSelected(event) {
    this.selectedSecondaryOption =
      this.secondaryPalletes[event.target.selectedIndex];
    this.gm.palettesImageExchangeModel.selectedSwatch.selected = false;

    let index =
      this.gm.palettesImageExchangeModel.selectedPalette.swatches.findIndex(
        (item) => {
          return item.id == this.selectedSecondaryOption.id;
        }
      );
    if (index != -1) {
      this.gm.palettesImageExchangeModel.selectedSwatch =
        this.gm.palettesImageExchangeModel.selectedPalette.swatches[index];
      this.gm.palettesImageExchangeModel.selectedPalette.swatches[
        index
      ].selected = true;
    }
  }
  secondaryPallete() {
    let filteredArray =
      this.gm.palettesImageExchangeModel.selectedPalette.swatches.filter(
        (item) => {
          return !item.empty;
        }
      );
    this.secondaryPalletes = filteredArray.slice();
    filteredArray.shift();
    return filteredArray;
  }

  selectPrintType(event: any, selectType: any) {
    this.selectedPrintTypeBasedOn(selectType)
  }

  selectedPrintTypeBasedOn(selectType: any) {
    jQuery("#" + selectType).prop("checked", true);
    this.fileExchange.printType = selectType
    if (selectType == "print") {
      this.selectedPrintTypeOption = this.paletteCheckOptions[1];
      if (this.selectedSecondaryOption) {
        jQuery("#" + this.selectedSecondaryOption.id).prop("selected", true);
      }

    } else {
      this.selectedSecondaryOption = null
      this.selectedPrintTypeOption = this.paletteCheckOptions[0];
    }
    this.selectedPrintTypeOption.checked = true;
  }

  private importedData: any;
  private paletteColors: SwatchColor[] = [];

  loadFileColors(colorData: any) {
    var self = this;
    let swatches = this.gm.palettesModel.selectedPalette.swatches;
    for (let s = 0; s < swatches.length; s++) {
      swatches[s].empty = true;
      swatches[s].refname = "";
      swatches[s].refnumber = "";
      swatches[s].zcc = "ZCC-0000";
      swatches[s].contrastingFontColor = "white";
    }
    self._zone.run(() => {
      self.importedData = colorData;
      self.paletteColors = colorData.map((elem: any) => {
        return {
          hashHex: elem.rgb,
          hex: elem.rgbNoHash,
          refname: elem.refname,
          refnumber: elem.refnumber,
          zcc: elem.zcc,
          empty: false,
        };
      });
    });
    for (var i = 0; i < self.paletteColors.length; i++) {
      let swatchColor = self.paletteColors[i];
      let value = swatchColor.hashHex;

      let o = new SwatchColorChangedEvent({
        oldSwatch: new SwatchModel(
          JSON.parse(JSON.stringify(this.gm.palettesModel.selectedSwatch))
        ),
      });
      let target = this.gm.palettesModel.selectedPalette.swatches[i];
      if (swatchColor.empty) {
        target.empty = true;
      } else {
        target.empty = false;
      }
      let c = this.gm.colorUtility.generateZCCColors(
        value,
        swatchColor.zcc,
        target
      );

      // makes sure the new swatch is not empty now, in case it was empty before applying swatch
      o.newSwatch = new SwatchModel(JSON.parse(JSON.stringify(target)));
      this.gm.palettesModel.swatchColorChanged.emit(o);

      self.gm.palettesModel.selectedPalette.swatches[i].refname =
        swatchColor.refname;
      self.gm.palettesModel.selectedPalette.swatches[i].refnumber =
        swatchColor.refnumber;
      if (
        swatchColor.refnumber ==
        self.gm.palettesModel.selectedPalette.swatches[i].zcc
      ) {
        self.gm.palettesModel.selectedPalette.swatches[i].defaultZcc = true;
      } else {
        self.gm.palettesModel.selectedPalette.swatches[i].defaultZcc = false;
      }
      if (swatchColor.refnumber == "" || swatchColor.refname == "") {
        self.gm.palettesModel.selectedPalette.swatches[i].missingRefTag = true;
      } else {
        self.gm.palettesModel.selectedPalette.swatches[i].missingRefTag = false;
      }
    }
    this.gm.palettesModel.selectedPalette.deselectAllSwatches();
    this.gm.palettesModel.selectedPalette.swatches[0].selected = true;
    var o = new SwatchChangedEvent({
      oldSwatchId: this.gm.palettesModel.selectedSwatch.id,
      oldPaletteId: this.gm.palettesModel.selectedPalette.id,
      newSwatch: this.gm.palettesModel.selectedPalette.swatches[0],
      newPalette: this.gm.palettesModel.selectedPalette,
    });
    this.gm.palettesModel.selectedSwatch =
      this.gm.palettesModel.selectedPalette.swatches[0];
    this.gm.palettesModel.swatchChanged.emit(o);
    this.gm.palettesModel.writeDefaultFile();
  }

  exportFile() {
    if (this.fileExchange.fileFormat == "") {
      alert("Select a File Format to export.");
      return;
    }

    if (this.fileExchange.imageFileName == "") {
      alert("Enter Image Name to export.");
      return;
    }

    let userDetails = JSON.parse(sessionStorage.getItem("USER_DETAILS"));
    this.fileExchange.userName = userDetails.ContactName;
    this.fileExchange.company = userDetails.CompanyName;

    var event = new Date();
    var options = { year: "numeric", month: "short", day: "numeric" };
    let date = event.toLocaleDateString("en-US", options);
    let time = new Date();
    var outputdate =
      date +
      "    " +
      time.getHours() +
      ":" +
      time.getMinutes() +
      ":" +
      time.getSeconds() +
      " " +
      new Date()
        .toTimeString()
        .match(new RegExp("[A-Z](?!.*[(])", "g"))
        .join("");
    this.fileExchange.date = outputdate;
    this.fileExchange.primaryColor = null
    this.fileExchange.secondaryColors = []
    let primarySwatch = null;
    let secondarySwatch = null;
    let secondarySwatches = []
    if (this.fileExchange.printType == "solid") {
      primarySwatch = this.gm.palettesImageExchangeModel.selectedSwatch.selected && !this.gm.palettesImageExchangeModel.selectedSwatch.empty ? this.gm.palettesImageExchangeModel.selectedSwatch : null
    }
    else {
      let printArray = this.secondaryPalletes.slice();
      primarySwatch = printArray[0]
      printArray.shift()
      secondarySwatches = printArray
      secondarySwatch = this.selectedSecondaryOption
    }
    if (primarySwatch) {
      let rgbArray = primarySwatch.rgbfull.split(",");
      this.fileExchange.primaryColor = {
        colorName: primarySwatch.refname.toUpperCase(),
        zcc: primarySwatch.zcc,
        hex: primarySwatch.rgb,
        red: rgbArray[0],
        green: rgbArray[1],
        blue: rgbArray[2],
        id: primarySwatch.id
      }
    }
    if (secondarySwatch) {
      let rgbArray = secondarySwatch.rgbfull.split(",");
      this.fileExchange.secondaryColor = {
        colorName: secondarySwatch.refname.toUpperCase(),
        zcc: secondarySwatch.zcc,
        hex: secondarySwatch.rgb,
        red: rgbArray[0],
        green: rgbArray[1],
        blue: rgbArray[2],
        id: secondarySwatch.id
      }
    }
    let secondaryColorArray = []
    for (let i = 0; i < secondarySwatches.length; i++) {
      const swatch = secondarySwatches[i];
      let rgbArray = swatch.rgbfull.split(",");
      let newElem = {
        colorName: swatch.refname.toUpperCase(),
        zcc: swatch.zcc,
        hex: swatch.rgb,
        red: rgbArray[0],
        green: rgbArray[1],
        blue: rgbArray[2],
        id: swatch.id
      }
      secondaryColorArray.push(newElem)
    }
    this.fileExchange.secondaryColors = secondaryColorArray
    if (this.fileExchange.primaryColor == null) {
      alert("Select a Primary Color to export.");
      return;
    }
    //  this.fileExchange.imageData = this.previewArea.src

    this.spinner.show();
    this.apiService.uploadCompareFileAsBase64(this.previewArea.src).subscribe((data: any) => {
      this.spinner.hide()

      let imagePath = data.filename
      this.apiService.createEmbeddedMetaDetaImage(this.fileExchange.imageFileName, imagePath, this.fileExchange, this.fileExchange.fileFormat).subscribe(
        (res) => {
          this.spinner.hide();
          const element = document.createElement('a');
          element.href = URL.createObjectURL(res.image);
          element.download = this.fileExchange.imageFileName + '.' + this.fileExchange.fileFormat.toLowerCase();
          document.body.appendChild(element);
          element.click();
        },
        (error) => {
          this.spinner.hide();
          alert(error.error.message);
        });

    }, (error) => {
      this.spinner.hide();
      alert(error.error.message);
    })
  }

  undoDelete() {
    this.gm.undoStack();
    if (this.gm.palettesModel.isEmpty()) {
      this.gm.palettesModel.selectedSwatch =
        this.gm.palettesModel.selectedPalette.swatches[0];
      this.gm.palettesModel.selectedSwatch.selected = true;
    }
  }

  clearAllPallets() {
    this.gm.addToRedoStack();
    let isNonEmptyIndex =
      this.gm.palettesModel.selectedPalette.swatches.findIndex(
        (elem) => elem.empty == false
      );
    if (isNonEmptyIndex >= 0) {
      this.removeAllPallets();
    }
  }

  removeAllPallets() {
    let palette = this.gm.palettesModel.selectedPalette;
    this.gm.removeAllPalletes();
    this.gm.palettesModel.writeDefaultFile();
    this.ref.markForCheck();
  }

  deleteSelectedPalette() {
    if (this.gm.palettesModel.selectedSwatch.empty == false) {
      this.gm.addToRedoStack();
      let deletedSwatch = Object.assign(
        {},
        this.gm.palettesModel.selectedSwatch
      );
      let index = this.gm.palettesModel.selectedPalette.swatches.findIndex(
        (x) => x.id == this.gm.palettesModel.selectedSwatch.id
      );
      let deletedArr = { index: index, data: deletedSwatch };
      this.gm.deletedStack.push(deletedArr);
      this.gm.palettesModel.selectedSwatch.empty = true;
      this.gm.palettesModel.selectedSwatch.refname = "";
      this.gm.palettesModel.selectedSwatch.refnumber = "";
      this.gm.palettesModel.selectedSwatch.contrastingFontColor = "white";
      this.gm.palettesModel.updatePalette(this.gm.palettesModel.selectedSwatch);
      this.gm.palettesModel.writeDefaultFile();
    }
  }

  showWorkspace(text: any) {
    jQuery("#page-title").html(text);
    this.createScrollScript();
    this.showWorkSpace = !this.showWorkSpace;
    this.checkForMissingTag();
    setTimeout(() => {
      jQuery("#" + this.lastSortedBy).prop("checked", true);
    }, 10);

    this.ref.markForCheck();
  }

  createScrollScript() {
    var s = document.createElement("script");
    s.setAttribute("src", "../../../assets/scrollbar.js");
    s.onload = () => {
      jQuery("body").find("#zcc-color-examples").customScrollbar();
    };
    document.body.appendChild(s);
  }

  openSwatchFile(filetype) {
    this.openFile(filetype, "swatchFile");
  }

  openComparisonFile(filetype) {
    this.openFile(filetype, "comparisonFile");
  }

  openFile(filetype, whichFile) {
    // let self = this

    // this.imageExchangeSwatchFileName = ''
    // this.gm.palettesImageExchangeModel.removePalette(this.gm.palettesImageExchangeModel.selectedPalette)
    // localStorage.removeItem(this.imageExchangeStandardFileName)
    // localStorage.removeItem('zcdpfileImageExchange.palettes.json')
    // if (whichFile == 'comparisonFile') {
    // 	//remove from cache also
    // 	localStorage.removeItem(this.imageExchangeFile)
    // 	localStorage.removeItem(this.imageExchangeFileName)
    // }
    jQuery("#" + filetype).click();
  }
  public filesToUpload;
  public imageExchangeSwatchFileName;
  public imageExchangeStandardFileName;
  fileChangeEvent(fileInput) {
    if (fileInput.target.files.length == 0) {
      return;
    }
    this.filesToUpload = <Array<File>>fileInput.target.files;
    const formData: any = new FormData();
    const files: Array<File> = this.filesToUpload;

    for (let i = 0; i < files.length; i++) {
      formData.append("uploads[]", files[i], files[i]["name"]);
    }
    this.spinner.show();
    this.apiService.uploadFile(formData).subscribe(
      (data: any) => {
        this.spinner.hide();
        data = JSON.parse(data);
        // this.gm.paletteFileName = undefined
        this.gm.palettesImageExchangeModel.removePalette(
          this.gm.palettesImageExchangeModel.selectedPalette
        );
        let swatches = data.swatches;
        for (let i = 0; i < swatches.length; i++) {
          let swatch = swatches[i];
          this.gm.palettesImageExchangeModel.selectedPalette.swatches[i].empty =
            swatch.empty;
          this.gm.palettesImageExchangeModel.selectedPalette.swatches[i].rgb =
            swatch.hex;
          this.gm.palettesImageExchangeModel.selectedPalette.swatches[
            i
          ].rgbfull = swatch.red + "," + swatch.green + "," + swatch.blue;
          this.gm.palettesImageExchangeModel.selectedPalette.swatches[i].zcc =
            swatch.zcc;
          this.gm.palettesImageExchangeModel.selectedPalette.swatches[i].zcc =
            swatch.zcc;
          this.gm.palettesImageExchangeModel.selectedPalette.swatches[
            i
          ].refname = swatch.referenceName;
          this.gm.palettesImageExchangeModel.selectedPalette.swatches[
            i
          ].refnumber = swatch.referenceNumber;
          if (i == 0) {
            this.gm.palettesImageExchangeModel.selectedPalette.swatches[
              i
            ].selected = true;
          } else {
            this.gm.palettesImageExchangeModel.selectedPalette.swatches[
              i
            ].selected = false;
          }
        }
        // this.gm.palettesImageExchangeModel.writeDefaultFile();
        this.imageExchangeSwatchFileName = fileInput.target.files[0].name;
        this.gm.paletteFileName = this.imageExchangeSwatchFileName;
        // try {
        //   localStorage.setItem(
        //     this.imageExchangeStandardFileName,
        //     this.imageExchangeSwatchFileName
        //   );
        // } catch (error) {
        //   console.log("Local Storage is full, Please empty data");
        // }

        this.gm.palettesImageExchangeModel.selectedPalette.notes = data.notes;
        this.gm.palettesImageExchangeModel.selectedPalette.techpack =
          data.techpack;
      },
      (error) => {
        this.spinner.hide();
        alert(error.error.message);
      }
    );
  }
  public imageExchangeFile;
  public imageExchangeFileName;
  saveCompareImageDataToLocalStorage() {
    let self = this;
    try {
      // localStorage.setItem(self.imageExchangeFile, self.previewArea.src);
      // localStorage.setItem(self.imageExchangeFileName, self.fileExchange.swatchFileName);
    } catch (error) {
      console.log("Local Storage is full, Please empty data");
    }
  }

  clearImage() {
    // this.finallyClearImage()
  }
  clearStandard() { }
  clearMetaDataImage() {

  }
  prepareImage(maxWidth, maxHeight, image) {
    let s = { height: image.height, width: image.width };
    let xs = 1;
    let ys = 1;
    let shrink = 1;
    if (maxWidth < s.width) {
      xs = maxWidth / s.width;
    }
    if (maxHeight < s.height) {
      ys = maxHeight / s.height;
    }
    if (xs < ys) {
      shrink = xs;
      image.width = maxWidth;
      image.height = s.height * xs;
    } else if (ys < 1) {
      shrink = ys;
      image.height = maxHeight;
      image.width = s.width * ys;
    } else {
      image.width = s.width;
      image.height = s.height;
    }
    return JSON.stringify({
      // 'path': imagePath,
      shrink: shrink,
      time: 1.0,
      fullWidth: image.width,
      fullHeight: image.height,
    });
  }

  public width: any;
  public height: any;
  public imageData: any;
  cb(imgPath: String, shrink: number, fullWidth: any, fullHeight: any): void {
    let self = this;
    self._zone.run(() => {
      var startTime = Date.now();

      var orig = jQuery("#img")[0],
        refCanvas = jQuery(".reference")[0];

      var _h = orig.naturalHeight,
        _w = orig.naturalWidth,
        x = 0,
        y = 0,
        w = _w,
        h = _h,
        maxWidth = jQuery("#imageContainer").width(),
        k = w > maxWidth ? maxWidth / w : 1; // width is the determining factor
      w = Math.round(w * shrink);
      h = Math.round(h * shrink);
      refCanvas.width = w;
      refCanvas.height = h;
      self.width = w;
      self.height = h;
      var mainHeight = jQuery("main#palleteView").innerHeight() + 105;
      jQuery(".main-palette-container").css(
        "height",
        "calc(100% - " + mainHeight + "px)"
      );
      var context = refCanvas.getContext("2d");
      context.drawImage(orig, 0, 0, w, h);

      var input = context.getImageData(x, y, w, h);
      self.imageData = input;
      self.zoomImage("0", "1x");
    });
  }
  zoomImage(zm: any, value?) {
    if (!this.currDoc) {
      return;
    }

    let zoomvalue = zm;
    zm = zm * 50;
    let zoom = Number(zm);
    // if(zoomvalue == 0) zoom = 0;
    let imageWid = Number(jQuery("#img").width());
    let imageHgt = Number(jQuery("#img").height());
    if (zoom === 0) {
      imageWid = this.zoomImageDefaultWidth;
      imageHgt = this.zoomImageDefaultHeight;
    } else {
      let mWidth = this.zoomImageDefaultWidth + zoom;
      let mHeight = this.zoomImageDefaultHeight + zoom;
      let xs = mWidth / this.zoomImageDefaultWidth;
      let ys = mHeight / this.zoomImageDefaultHeight;
      let shrink;
      if (xs > ys) {
        imageWid = mWidth;
        imageHgt = this.zoomImageDefaultHeight * xs;
      } else {
        imageHgt = mHeight;
        imageWid = this.zoomImageDefaultWidth * ys;
      }
    }
    this.width = imageWid;
    this.height = imageHgt;

    jQuery("#img").width(imageWid + "px");
    jQuery("#img").height(imageHgt + "px");

    jQuery("#canvaszoom").attr("width", imageWid);
    jQuery("#canvaszoom").attr("height", imageHgt);
    var orig = jQuery("#img")[0],
      refCanvas = jQuery(".reference")[0];
    var context = refCanvas.getContext("2d");
    context.clearRect(0, 0, imageWid, imageHgt);
    context.drawImage(orig, 0, 0, imageWid, imageHgt);
    var input = context.getImageData(0, 0, imageWid, imageHgt);
    this.imageData = input;
    jQuery("#marqueeImg").width(imageWid + "px");
    jQuery("#marqueeImg").height(imageHgt + "px");
  }

  loadImage() {
    if (this.imageURL != null || this.imageURL != undefined) {
      //load image
      let self = this;
      self.previewArea.src = this.imageURL;
      self.paletteColors.length = 0; // empty the array

      var img = new Image();

      img.onload = function () {
        var response: any;
        //response = self.prepareImage(364,274, img);
        var maxWidth = jQuery("#imageContainer").width();
        var maxHeight = jQuery("#imageContainer").height();
        response = self.prepareImage(maxWidth, maxHeight, img);
        response = JSON.parse(response);
        self.currDoc = response;
        self.zoomImageDefaultWidth = response.fullWidth;
        self.zoomImageDefaultHeight = response.fullHeight;
        self.fileExchange.imageWidth = img.width
        self.fileExchange.imageHeight = img.height
        setTimeout(function () {
          self.cb(null, response.shrink, 0, 0);
        }, 100);
      };

      img.src = this.imageURL;
    }
  }

  loadMetaDataFileChangeEvent(fileInput) {

    if (fileInput.target.files.length == 0) {
      return;
    }
    //check for metadata
    this.filesToUpload = <Array<File>>fileInput.target.files;
    const formData: any = new FormData();
    const files: Array<File> = this.filesToUpload;
    let fileName = files[0]["name"]
    if(fileName.length > 0){
      fileName = this.removeFileExtension(fileName);
    }
    for (let i = 0; i < files.length; i++) {
      formData.append("uploads[]", files[i], files[i]["name"]);
    }
    let self = this
    this.spinner.show();
    this.apiService.uploadFile(formData).subscribe(
      (data: any) => {
        self.spinner.hide();
        data = JSON.parse(data);
        // this.gm.paletteFileName = undefined
        self.gm.palettesImageExchangeModel.removePalette(
          self.gm.palettesImageExchangeModel.selectedPalette
        );
        let swatches = [data.primaryColor]
        let selectedSecondaryIndex = 0
        swatches = swatches.concat(data.secondaryColors)
        //  let swatches = data.swatches;
        for (let i = 0; i < swatches.length; i++) {
          let swatch = swatches[i];
          self.gm.palettesImageExchangeModel.selectedPalette.swatches[i].empty =
            false;
          self.gm.palettesImageExchangeModel.selectedPalette.swatches[i].rgb =
            swatch.hex;
          self.gm.palettesImageExchangeModel.selectedPalette.swatches[
            i
          ].rgbfull = swatch.red + "," + swatch.green + "," + swatch.blue;
          self.gm.palettesImageExchangeModel.selectedPalette.swatches[i].zcc =
            swatch.zcc;
          self.gm.palettesImageExchangeModel.selectedPalette.swatches[
            i
          ].refname = swatch.colorName;
          if (data.secondaryColor && data.printType == "print" && swatch.zcc == data.secondaryColor.zcc) {
            let secondarySwatchToShow = self.gm.palettesImageExchangeModel.selectedPalette.swatches[i]
            self.selectedSecondaryOption = secondarySwatchToShow
            selectedSecondaryIndex = i

          }
        }
        //self.gm.palettesImageExchangeModel.writeDefaultFile();
        self.imageExchangeSwatchFileName = fileInput.target.files[0].name;
        self.gm.paletteFileName = self.imageExchangeSwatchFileName;
        try {
          // localStorage.setItem(
          //   self.imageExchangeStandardFileName,
          //   self.imageExchangeSwatchFileName
          // );
        } catch (error) {
          console.log("Local Storage is full, Please empty data");
        }

        self.gm.palettesImageExchangeModel.selectedPalette.notes = data.notes;
        self.gm.palettesImageExchangeModel.selectedPalette.techpack =
          data.techpack;
        self.gm.palettesImageExchangeModel.selectedPalette.metaData = data
        self.fileExchange = data
        self.fileExchange.imageFileName = fileName
        self.secondaryPallete()
        self.selectedPrintTypeBasedOn(data.printType)
        self.fileChangedEvent(fileInput)
        if (self.selectedSecondaryOption) {
          setTimeout(function () {
            jQuery("#selectAccentColors").prop("selectedIndex", selectedSecondaryIndex);
          }, 100)
        }
      },
      (error) => {
        self.spinner.hide();
        alert(error.error.message);
      }
    );


  }
  fileChangedEvent(fileInput) {
    if (fileInput.target.files.length == 0) {
      return;
    }
    // this.zoomImage("0", "1x");
    let file, type: any;

    file = fileInput.target.files[0];
    type = file.type;
    if (type != "image/png" && type != "image/jpeg") {
      return;
    }
    // this.fileExchange.swatchFileName = this.removeFileExtension(file.name);
    var self = this;
    var reader = new FileReader();

    reader.onload = function () {
      // file is loaded
      self.previewArea.src = reader.result;
      self.paletteColors.length = 0; // empty the array
      self.saveCompareImageDataToLocalStorage();

      var img = new Image();

      img.onload = function () {
        var response: any;
        //response = self.prepareImage(364,274, img);
        var maxWidth = jQuery("#imageContainer").width();
        var maxHeight = jQuery("#imageContainer").height();
        response = self.prepareImage(maxWidth, maxHeight, img);
        response = JSON.parse(response);
        self.currDoc = response;

        self.zoomImageDefaultWidth = response.fullWidth;
        self.zoomImageDefaultHeight = response.fullHeight;
        self.fileExchange.imageWidth = img.width
        self.fileExchange.imageHeight = img.height
        setTimeout(function () {
          self.cb(null, response.shrink, 0, 0);
        }, 100);
      };

      img.src = reader.result.toString(); // is the data URL because called with readAsDataURL
    };

    reader.readAsDataURL(fileInput.target.files[0]);
  }

  getSecondaryRGBValue() {
    return this.selectedSecondaryOption.empty
      ? ""
      : "R: " +
      this.selectedSecondaryOption.r +
      " G: " +
      this.selectedSecondaryOption.g +
      " B: " +
      this.selectedSecondaryOption.b;
  }
  getPrimaryRGBValue() {
    return this.gm.palettesImageExchangeModel.selectedSwatch.empty ||
      !this.gm.palettesImageExchangeModel.selectedSwatch.selected
      ? ""
      : "R: " +
      this.gm.palettesImageExchangeModel.selectedSwatch.r +
      " G: " +
      this.gm.palettesImageExchangeModel.selectedSwatch.g +
      " B: " +
      this.gm.palettesImageExchangeModel.selectedSwatch.b;
  }
  getPrintPrimaryRGBValue() {
    return this.secondaryPalletes[0].empty
      ? ""
      : "R: " +
      this.secondaryPalletes[0].r +
      " G: " +
      this.secondaryPalletes[0].g +
      " B: " +
      this.secondaryPalletes[0].b;
  }
  canEditNames() {
    let filteredArray =
      this.gm.palettesImageExchangeModel.selectedPalette.swatches.filter(
        (item) => {
          return !item.empty;
        }
      );
    if (filteredArray.length > 0) {
      return true
    }
    return false
  }
  navigateToFileExchange() {
    this.gm.palettesModel = cloneDeep(this.gm.palettesImageExchangeModel)
    this.gm.palettesModel.writeDefaultFile()
    this.router.navigateByUrl("/swatch-exchange");
  }

  removeFileExtension(name) {
    return name.substring(0, name.lastIndexOf('.'));
  }
}
