import {PaletteModel} from "../models/palette.model";
import {SwatchModel} from "../models/swatch.model";
import * as _ from 'underscore';


export class SwatchColorChangedEvent {

    public oldSwatch: SwatchModel;
    public newSwatch: SwatchModel;


    constructor(o:any=null, paletteModel:PaletteModel=null) {
        if( o ) {
            _.extend(this, o);
        }
        if(paletteModel){
            paletteModel.palletSaved = false;
        }
    }

}
