

let colorGradient: number[][] = [
  [0, 255, 0, 0],
  [0.17, 255, 255, 0],
  [0.34, 0, 255, 0],
  [0.51, 0, 255, 255],
  [0.68, 0, 0, 255],
  [0.85, 255, 0, 255],
  [1, 255, 0, 0]
];


function rgbForHue(hueValue: number): number[] {
  let hueRgb: number[] = [255, 0, 0];
  if (hueValue <= 0 || hueValue >= 1.0) {
    // red
  } else {
    let prev: number[] = [0, 255, 0, 0];
    for (let arr of colorGradient) {
      let keyFrame = arr[0];
      if (keyFrame >= hueValue) {
        let oldKeyFrame = prev[0];
        let oldAmount = (keyFrame - hueValue) / (keyFrame - oldKeyFrame);
        let newAmount = 1.0 - oldAmount;
        hueRgb[0] = Math.floor(oldAmount * prev[1] + newAmount * arr[1] + 0.5);
        hueRgb[1] = Math.floor(oldAmount * prev[2] + newAmount * arr[2] + 0.5);
        hueRgb[2] = Math.floor(oldAmount * prev[3] + newAmount * arr[3] + 0.5);
        break;
      }
      prev = arr;
    }
  }
  return hueRgb;
}

// returns a hex string (sans #)
// inputs are in range 0-255
function sanitizeToZcc(r: number, g: number, b: number): string {
  let near4bits = (x) => Math.floor((8 + x) / 17);
  let zccBits = [r, g, b].map(near4bits);
  let toHex = (x) => `${x.toString(16)}${x.toString(16)}`;
  return zccBits.map(toHex).join('');
}

export { colorGradient, rgbForHue, sanitizeToZcc };
