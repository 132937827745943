import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'error-404',
  templateUrl: './error-404.html',
  styleUrls: ['./error-404.scss'],
	encapsulation: ViewEncapsulation.None
})
export class Error404Page implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}