import { EventEmitter, ChangeDetectionStrategy, ElementRef, Component,
  OnChanges, OnInit, Output, Injector, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../base.component';
import { colorGradient, rgbForHue } from './colorPicker2.utils';

let L_INSET = 15;
let R_INSET = 6;
let V_INSET = 15;

@Component({
selector: 'colorPicker2-saturation-picker',
styles: ['./saturationPickerCanvas.component.scss'],
templateUrl: './saturationPickerCanvas.component.html'
})
export class SaturationPickerCanvas extends BaseComponent implements OnChanges, OnInit {
// export default class SaturationPickerCanvas implements OnChanges, OnInit {
@ViewChild('saturationPickerCanvas') saturationPickerCanvasRef: ElementRef;
@Output() saturationUpdated = new EventEmitter();
@Output() valueUpdated = new EventEmitter();
@Output() rgbUpdated = new EventEmitter();

@Input() hue: number;

@Input()
set saturation(saturation: number) {
  if (Math.abs(saturation - this._saturation) < 0.001) {
    return;
  }
  if (saturation >= 0 && saturation <= 1) {
    this._saturation = saturation;
  }
  else if(saturation != undefined){
    this._saturation = 0;
  }
  else{
    this._saturation = saturation
  }
  this.saturationUpdated.emit(saturation);
}
get saturation(): number { return this._saturation; }

public _saturation: number;

@Input()
set value(value: number) {
  if (Math.abs(value - this._value) < 0.001) {
    return;
  }
  if (value >= 0 && value <= 1) {
    this._value = value;
  } else if(value != undefined){
    this._value = 0;
  }
  else{
    this._value = value
  }
  this.valueUpdated.emit(value);
}
get value(): number { return this._value; }

public _value: number;

public _isTracking = false;

constructor(public injector: Injector) {
  super(injector);
}

ngOnInit() {
  this.renderSaturationCanvas()
}

ngOnChanges(changes: any) {
  this.renderSaturationCanvas();
}

public renderSaturationCanvas() {
  let element = this.saturationPickerCanvasRef.nativeElement;
  let ctx = element.getContext('2d');

  let width = element.width;
  let height = element.height;
  ctx.clearRect(0, 0, width, height);
  let gradient = ctx.createLinearGradient(0, 0, width, 0);

  // Create color gradient
  let hue = rgbForHue(this.hue);

  gradient.addColorStop(0, 'rgb(255, 255, 255)');
  gradient.addColorStop(1, 'rgb(' + hue.join(', ') + ')');
  ctx.fillStyle = gradient;
  ctx.fillRect(L_INSET, V_INSET, width - R_INSET, height - V_INSET);

  gradient = ctx.createLinearGradient(0, 0, 0, height);
  gradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
  gradient.addColorStop(1, 'rgba(0, 0, 0, 1)');
  ctx.fillStyle = gradient;
  ctx.fillRect(L_INSET, V_INSET, width - R_INSET, height - V_INSET);
  
  ctx.fillStyle = 'rgba(0, 0, 0, 0)';

  ctx.fillRect(0, 0, width, V_INSET);
  ctx.fillRect(0, height - V_INSET, width, V_INSET);
  ctx.fillRect(0, 0, L_INSET, height);
  ctx.fillRect(width - R_INSET, 0, R_INSET, height);

  let x = (width - R_INSET - L_INSET) * this.saturation + L_INSET;
  let y = (height - V_INSET - V_INSET) * (1.0 - this.value) + V_INSET;
  let flip = this.value > 0.5 && this.saturation < 0.3;
  let colors = [
      {c: flip ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)', r: 5},
      {c: flip ? '#000' : '#fff', r: 4}
  ];
  for (let info of colors) {
    ctx.strokeStyle = info.c;
    ctx.beginPath();
    ctx.arc(x + 0.5, y + 0.5, info.r, 0, 2 * Math.PI);
    ctx.closePath();
    ctx.stroke();
  }
}

public onSaturationMouseDown(event: MouseEvent): void {
  this._isTracking = true;
  this.onSaturationMouseMove(event);
}

public onSaturationMouseUp(event: MouseEvent): void {
  this._isTracking = false;
  this.onSaturationMouseMove(event);
}

public onSaturationMouseMove(event: MouseEvent): void {
  if ((event.buttons === 0) || (this._isTracking === false)) {
    this._isTracking = false;
    return;
  }
  let x = event.clientX;
  let y = event.clientY;
  let rect = this.saturationPickerCanvasRef.nativeElement.getBoundingClientRect();
  x -= rect.left + L_INSET;
  y -= rect.top + V_INSET;
  let width = rect.width - L_INSET - R_INSET;
  let height = rect.height - V_INSET - V_INSET;
  if (y < 0) {
    y = 0;
  } else if (y > height) {
    y = height;
  }
  if (x < 0) {
    x = 0;
  } else if (x > width) {
    x = width;
  }
  let newSaturation = x / width;
  let newValue = (height - y) / height;
  this.saturation = newSaturation;
  this.value = newValue;
  this.renderSaturationCanvas();
}
}
