import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'common-footer',
  templateUrl: './footer.html',
  styleUrls: ['./footer.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FooterCommon implements OnInit {
  privacyLink = ''
  termsLink = ''
  legalLink = ''
  contactLink = ''
  constructor() { }

  ngOnInit() {
    this.privacyLink = environment.privacyURL;
    this.termsLink = environment.termsURL;
    this.legalLink = environment.legalURL;
    this.contactLink = environment.contactURL;
  }

}