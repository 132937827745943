import { Injectable, ChangeDetectorRef, Component, OnInit, ViewEncapsulation, Injector, ChangeDetectionStrategy, Input, HostListener, NgZone } from '@angular/core';
import { BaseComponentSecondary } from 'src/app/base.component.secondary';
import { SwatchChangedEvent } from "../../events/swatch.changed.event";
import { SwatchModel } from "../../models/swatch.model";
import { PaletteModel } from "../../models/palette.model";
import { ColorPickerService } from '../../components/color-picker/color-picker.service';
import { ConfirmationDialogService } from 'src/app/confirmation-dialog/confirmation-dialog.service';
import { SwatchColorChangedEvent } from 'src/app/events/swatch.color.changed.event';
import * as cloneDeep from "lodash/cloneDeep";
declare var jQuery: any;
interface SwatchColor {
	hex: string;
	hashHex: string;
	zcc: string;
	empty: boolean;
	refname: string;
	refnumber: string;
}
@Injectable({
	providedIn: 'root'
})
@Component({
	selector: 'remove-duplicate',
	templateUrl: './remove-duplicate.html',
	styleUrls: ['./remove-duplicate.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class RemoveDuplicate extends BaseComponentSecondary implements OnInit {
	swatchesList: any[] = []
	@Input() palette: PaletteModel;
	@Input() swatch: SwatchModel;
	checkForDuplicate: Boolean = false;
	constructor(public ref: ChangeDetectorRef, public injector: Injector, public confirmationDialogService: ConfirmationDialogService, public _zone: NgZone) {
		super(injector);
	}

	ngOnInit() {

	}
	@HostListener('window:resize', ['$event'])
	onResize(event) {

	}

	contrastingFontColor(swatch): string {
		return ColorPickerService.isDarkColor(swatch.rgb) ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)';
	}
	dropped(event: any, swatchIndex: number): void {
		this.gm.addToRedoStack();
		if (typeof event.dragData.dragIndex != 'undefined') {
			// move swatch from dragIndex to swatchIndex
			var element = this.palette.swatches[event.dragData.dragIndex];
			this.palette.swatches.splice(event.dragData.dragIndex, 1);
			this.palette.swatches.splice(swatchIndex, 0, element);
		}
		else {
			let indexSwatch = this.palette.swatches[swatchIndex];
			indexSwatch.copy(event.dragData.swatch);
			if (indexSwatch.refnumber == '' || indexSwatch.refname == '') {
				indexSwatch.missingRefTag = true;
			}
			else {
				indexSwatch.missingRefTag = false;
			}
			this.gm.palettesFileModel.selectedSwatch.selected = false;
			this.gm.palettesFileModel.selectedSwatch = indexSwatch;

			if (this.gm.palettesFileModel.selectedPalette.savedReferenceName.length > 0) {
				this.gm.palettesFileModel.selectedPalette.enableMerge = true;
			}
		}
		this.gm.palettesFileModel.selectedPalette.palletSaved = false;
		this.gm.palettesFileModel.writeDefaultFile();

	}

	missingTagBlack(swatch): any {
		if (swatch.empty || this.gm.palettesModel.selectedPalette.shouldShowMissingTags == false) {
			return false;
		} else {
			return swatch.missingRefTag;
		}
	}

	selectSwatch(event): void {
		if (event.selected == true) {
			event.selected = false;
		} else {
			this.gm.palettesModel.selectedPalette.deselectAllSwatches();

			var o = new SwatchChangedEvent({
				"oldSwatchId": this.gm.palettesModel.selectedSwatch.id,
				"oldPaletteId": this.gm.palettesModel.selectedPalette.id,
				"newSwatch": event,
				"newPalette": this.palette
			});
			event.selected = true;
			this.gm.palettesModel.selectedSwatch = event;
			// this.gm.colorUtility.generateColors(this.gm.palettesModel.selectedSwatch.rgb, this.gm.palettesModel.selectedSwatch);
			this.gm.palettesModel.selectedPalette = this.palette;
			this.gm.palettesModel.data.activePaletteId = this.palette.id;
			this.gm.palettesModel.swatchChanged.emit(o);
		}

	}


	deleteSwatch(swatch) {
		if (swatch.empty) {
			alert("The swatch is already empty.");
			return;
		} else {
			this.gm.addToRedoStack();
			for (var i = 0; i < this.palette.swatches.length; i++) {
				if (this.palette.swatches[i].id === swatch.id) {
					let deletedSwatch = Object.assign({}, this.palette.swatches[i]);
					let index = this.gm.palettesModel.selectedPalette.swatches.findIndex(x => x.id == this.palette.swatches[i].id);
					let deletedArr = { index: index, data: deletedSwatch };
					this.gm.deletedStack.push(deletedArr);
					this.palette.swatches[i].empty = true;
					this.palette.swatches[i].zcc = "ZCC-0000"
					this.palette.swatches[i].refname = ""
					this.palette.swatches[i].refnumber = ""
					this.palette.swatches[i].contrastingFontColor = "white";
					this.gm.palettesModel.selectedSwatch = new SwatchModel({});
					this.gm.palettesModel.updatePalette(this.palette);
					this.gm.palettesModel.writeDefaultFile();
				}
			}
		}
	}

	handleDuplicate() {
		let b = this.gm.palettesModel.selectedPalette.swatches;
		let uniqueArray: any = [];
		let self = this;

		let isDuplicateExist = false
		let propertyName = "zcc"
		self._zone.run(() => {
			let testObject = {};
			b.map(function (item: any, index: any) {
				var itemPropertyName = item[propertyName];
				if (itemPropertyName in testObject) {
					if (item.empty == false) {
						isDuplicateExist = true
					}

				}
				else {
					let newSwatch = new SwatchModel()
					newSwatch = cloneDeep(item)
					testObject[itemPropertyName] = item
					if (item.empty == false) {
						uniqueArray.push(newSwatch);
					}
				}
			});
			if (isDuplicateExist == true) {
				this.confirmationDialogService.confirm('Duplicate Swatch', 'Duplicate Swatches will be permanently removed.')
					.then((confirmed) => {
						if (confirmed) {
							self.loadFileColors(uniqueArray);

						}
						jQuery('#removeDuplicates').prop('checked', false);
						this.ref.markForCheck();
					})
					.catch(() => jQuery('#removeDuplicates').prop('checked', false));

			}
			else {
				this.confirmationDialogService.alert('Duplicate Swatch', 'No duplicate swatches found.')
					.then((confirmed) => {
						if (confirmed) {
						}
						jQuery('#removeDuplicates').prop('checked', false);
					})
					.catch(() =>
						jQuery('#removeDuplicates').prop('checked', false)
					);

			}

		});


	}

	private importedData: any;
	private paletteColors: SwatchColor[] = []
	loadFileColors(colorData: any) {
		var self = this;
		let swatches = this.gm.palettesModel.selectedPalette.swatches;
		for (let s = 0; s < swatches.length; s++) {
			swatches[s].empty = true;
			swatches[s].refname = '';
			swatches[s].refnumber = '';
			swatches[s].zcc = 'ZCC-0000';
			swatches[s].contrastingFontColor = "white";
		}
		self._zone.run(() => {
			self.importedData = colorData;
			self.paletteColors = colorData.map((elem: any) => {
				return { 'hashHex': elem.rgb, 'hex': elem.rgbNoHash, 'refname': elem.refname, 'refnumber': elem.refnumber, 'zcc': elem.zcc, 'empty': false }
			});
		});
		for (var i = 0; i < self.paletteColors.length; i++) {
			let swatchColor = self.paletteColors[i];
			let value = swatchColor.hashHex;

			let o = new SwatchColorChangedEvent({
				'oldSwatch': new SwatchModel(JSON.parse(JSON.stringify(this.gm.palettesModel.selectedSwatch)))
			});
			let target = this.gm.palettesModel.selectedPalette.swatches[i];
			if (swatchColor.empty) {
				target.empty = true;
			} else {
				target.empty = false;
			}
			let c = this.gm.colorUtility.generateZCCColors(value, swatchColor.zcc, target);

			// makes sure the new swatch is not empty now, in case it was empty before applying swatch
			o.newSwatch = new SwatchModel(JSON.parse(JSON.stringify(target)));
			this.gm.palettesModel.swatchColorChanged.emit(o);

			self.gm.palettesModel.selectedPalette.swatches[i].refname = swatchColor.refname;
			self.gm.palettesModel.selectedPalette.swatches[i].refnumber = swatchColor.refnumber;
			if (swatchColor.refnumber == self.gm.palettesModel.selectedPalette.swatches[i].zcc) {
				self.gm.palettesModel.selectedPalette.swatches[i].defaultZcc = true;
			}
			else {
				self.gm.palettesModel.selectedPalette.swatches[i].defaultZcc = false;
			}
			if (swatchColor.refnumber == '' || swatchColor.refname == '') {
				self.gm.palettesModel.selectedPalette.swatches[i].missingRefTag = true;
			}
			else {
				self.gm.palettesModel.selectedPalette.swatches[i].missingRefTag = false;
			}
		}
		this.gm.palettesModel.selectedPalette.deselectAllSwatches();
		this.gm.palettesModel.selectedPalette.swatches[0].selected = true;
		var o = new SwatchChangedEvent({
			"oldSwatchId": this.gm.palettesModel.selectedSwatch.id,
			"oldPaletteId": this.gm.palettesModel.selectedPalette.id,
			"newSwatch": this.gm.palettesModel.selectedPalette.swatches[0],
			"newPalette": this.gm.palettesModel.selectedPalette
		});
		this.gm.palettesModel.selectedSwatch = this.gm.palettesModel.selectedPalette.swatches[0];
		this.gm.palettesModel.swatchChanged.emit(o);
		this.gm.palettesModel.writeDefaultFile();
	}

}