
import {Injectable, Injector, EventEmitter} from '@angular/core';
import {PreferencesModel} from "./preferences.model";
import {PalettesModel} from "./palettes.model";
import {ColorUtility} from "../utils/color.utility";
import {ColorPickerService} from "../components/color-picker/color-picker.service";
import {ColorHistoryModel} from "./colorhistory.model";
import * as $ from 'jquery';
import {SwatchModel} from "./swatch.model";
import * as cloneDeep from 'lodash/cloneDeep';
import { PaletteModel } from './palette.model';
declare var SystemPath: any;
// declare var themeManager: any;
declare var jQuery: any;
enum theme {
  dark = 'dark',
  gray = 'gray',
  light = 'light',
}
enum orientation {
  portrait = 'portrait',
  landscape = 'landscape',
}
@Injectable()
export class GlobalModel {

  // public csInterface    = new CSInterface();
  public rb: any;
  // public extensionId    = this.csInterface.getExtensionID();
  // public hostEnvironment  = this.csInterface.getHostEnvironment();
  public preferencesModel   = new PreferencesModel();
  public palettesModel    = new PalettesModel(this, 'zcdp.palettes.json');
  public palettesFileModel    = new PalettesModel(this, 'zcdpfile.palettes.json');
  public palettesImageExchangeModel    = new PalettesModel(this, 'zcdpfileImageExchange.palettes.json');
  public palettesBuilderModel    = new PalettesModel(this, 'zcdpPaletteBuilder.palettes.json');
  public colorHistoryModel  = new ColorHistoryModel(this);
  // public zccModel       = new ZccModel();
  public activeNavComponent: string;
  public swatchFileOutputPath: string;
  public appVersion: string = "2.1.1";
  public appName: string = "zenColorConnect";
  public currentTheme: string;
  public isAppJustLaunched: boolean = false
  //shape when saving
  public defaultShape: string;
  public defaultPosition: string;
  public defaultFileType: string;
  public currentOrientation: string;
  public userOrientation: string;
  public colorUtility:ColorUtility;
  // public resources: any   =this.csInterface.initResourceBundle();
  public deletedStack:any = [];
  public undoPalettesModelStack:any = [];
  public selectedSwatchPickerStack:any = [];
  public deletedAllStack:any = [];
  public displaySwatchName:boolean;
  public preserveEmptySwatch: boolean;
  public sampleZoomState: number = 0;
  public get undoDisabled(): boolean {
    return (this.deletedStack.length == 0) ? true : false;
  }
  public get selectedSwatchPickerundoDisabled(): boolean {
    return (this.selectedSwatchPickerStack.length == 0) ? true : false;
  }
  public paletteFileName:any = '';
  public imageFileName:any = '';
  constructor(private injector:Injector) {
    var self = this;
    self.isAppJustLaunched = true // patch just to manage the fliker issue with orientation
    var colorPickerService = injector.get(ColorPickerService);
    self.colorUtility = new ColorUtility(this,colorPickerService);
    self.readUndoStackToFile()
  }

  emptyPalettesModelSavedDetails(){
    this.palettesModel.selectedPalette.savedReferenceName = '';
    this.palettesModel.selectedPalette.swatchFileName = '';
    this.palettesModel.selectedPalette.techpack = '';
    this.palettesModel.selectedPalette.notes = '';
  }

  scrollHorizontal(){
    let index;
    let scrollleftpos = 465;
    for(var i = 0; i<=this.palettesModel.selectedPalette.swatches.length; i++){
      var swatch = this.palettesModel.selectedPalette.swatches[i];
      if(swatch.selected == true){
        index = i;
        break;
      }
    }
    let devide = index/8;
    jQuery('#selectedpal').scrollLeft(scrollleftpos*Math.floor(devide));
  }

  addToRedoStack(){
    let palette = cloneDeep(this.palettesModel.selectedPalette)
    this.undoPalettesModelStack.push(palette)
    this.writeUndoStackToFile()

  }
  
  undoStack(){
      let item = this.undoPalettesModelStack.pop();  
      this.writeUndoStackToFile()
      if (item) {
        let selectedSwatchIndex = item.swatches.findIndex(elem => elem.selected == true)
        this.palettesModel.selectedPalette = this.createNewPalete(item)
        this.palettesModel.selectedPalette.deselectAllSwatches()
        this.palettesModel.selectedSwatch.selected = false
        if (selectedSwatchIndex >= 0) {
          this.palettesModel.selectedSwatch = this.palettesModel.selectedPalette.swatches[selectedSwatchIndex]
          this.palettesModel.selectedSwatch.selected = true
        }
        this.palettesModel.updateNewPalette(this.palettesModel.selectedPalette)
       // this.palettesModel.writeDefaultFile() 
      }
  }

  createNewPalete(item): PaletteModel{
    let newPaletteModel = new PaletteModel()
    
    newPaletteModel.id = item.id
    newPaletteModel.paletteRef1 = item.paletteRef1
    newPaletteModel.paletteRef2 = item.paletteRef2
    newPaletteModel.paletteRef3 = item.paletteRef3
    newPaletteModel.paletteRef4 = item.paletteRef4
    
    newPaletteModel.swatchFileName = item.swatchFileName
    newPaletteModel.techpack = item.techpack
    newPaletteModel.notes = item.notes
    newPaletteModel.customFieldValue1 = item.customFieldValue1
    newPaletteModel.customFieldValue2 = item.customFieldValue2
    newPaletteModel.customFieldValue3 = item.customFieldValue3
    newPaletteModel.activeSwatchId = item.activeSwatchId
    newPaletteModel.swatches = item.swatches
    newPaletteModel.palletSaved = item.palletSaved
    newPaletteModel.isNewPalleteAdded = item.isNewPalleteAdded
    newPaletteModel.shouldShowMissingTags = item.shouldShowMissingTags
    newPaletteModel.enableMerge = item.enableMerge
    newPaletteModel.swatchCustomFieldLabel1 = item.swatchCustomFieldLabel1
    newPaletteModel.swatchCustomFieldLabel2 = item.swatchCustomFieldLabel2
    newPaletteModel.swatchCustomFieldLabel3 = item.swatchCustomFieldLabel3
    newPaletteModel.swatchCustomFieldLabel4 = item.swatchCustomFieldLabel4
    newPaletteModel.swatchCustomFieldLabel5 = item.swatchCustomFieldLabel5
    newPaletteModel.savedReferenceName = item.savedReferenceName
    newPaletteModel.defaultZcc = item.defaultZcc
    return newPaletteModel
  }

  writeUndoStackToFile(){
    let fileName = 'UndoStack.json'
    try {
      var json = JSON.stringify(this.undoPalettesModelStack)
      localStorage.setItem(fileName, json)
    } catch (error) {
      console.log(fileName + ":Caught error writing new file: " + error);
    }

  }
  readUndoStackToFile(){
    let fileName = 'UndoStack.json'
    try {
      let data = localStorage.getItem(fileName);
        data = JSON.parse(data);
        let typeofData = typeof data;
        if(data && typeofData == "object"){
                this.undoPalettesModelStack = data;
            }
    } catch (error) {
      console.log(fileName + ":Caught error reading new file: " + error);
    }

  }

  removeAllPalletes(){
    this.palettesModel.selectedPalette.swatches.forEach(elem => {
      elem.empty = true
      elem.deltaE = null
      elem.comparisonResult = 'none'
      elem.refnumber = ''
      elem.refname = ''
    })
  }

  resetComparisonResult() {
    this.palettesModel.selectedPalette.swatches.forEach(elem => {
      elem.deltaE = null
			elem.comparisonResult = 'none'
    })
	}
}
